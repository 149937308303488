import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class LoginGtmAction implements GtmAction {
  event = GtmEvent.login;

  constructor(public payload: LoginGtmActionPayload) {}
}

export interface LoginGtmActionPayload {
  method: LoginGtmActionPayloadMethod;
}

export type LoginGtmActionPayloadMethod = 'google' | 'facebook' | 'cross_login' | 'form';
