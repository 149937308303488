import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { AppTypeGtm } from '../model/app-type-gtm';

export class SignUpGtmAction implements GtmAction {
  event = GtmEvent.sign_up;

  constructor(public payload: SignUpGtmActionPayload) {}
}

export interface SignUpGtmActionPayload {
  method: SignUpGtmActionPayloadMethod;
  app_type?: AppTypeGtm;
}

export type SignUpGtmActionPayloadMethod = 'google' | 'facebook' | 'form';
