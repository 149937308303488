<div class="outer-container" [style.height]="(isAuthenticated$ | async) ? '405px' : ltMd ? '290px' : '350px'">
  <div class="product-card-container inner-container">
    <app-skeleton-image height="200px" borderRadius="6px 6px 0 0"></app-skeleton-image>

    <div fxLayout="column" fxLayoutGap="12px" style="padding: 20px 15px 12px 15px">
      <app-skeleton-text [lines]="1" style="width: 30%"></app-skeleton-text>

      <app-skeleton-text [lines]="1" style="width: 80%; margin-bottom: 44px"></app-skeleton-text>

      <ng-container *ngIf="isAuthenticated$ | async; else guest">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; margin-bottom: 24px">
          <div style="width: 50%" fxLayout="column" fxLayoutGap="8px">
            <app-skeleton-text [lines]="1" style="width: 60%"></app-skeleton-text>
            <app-skeleton-text [lines]="1" style="width: 30%"></app-skeleton-text>
          </div>

          <div style="width: 50%" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center end">
            <app-skeleton-text [lines]="1" style="width: 60%"></app-skeleton-text>
            <app-skeleton-text [lines]="1" style="width: 30%"></app-skeleton-text>
          </div>
        </div>

        <div fxLayoutAlign="space-between end">
          <div style="width: 50%" fxLayout="column" fxLayoutGap="8px">
            <app-skeleton-text [lines]="1" style="width: 40%"></app-skeleton-text>
            <app-skeleton-text [lines]="1" style="width: 60%"></app-skeleton-text>
          </div>

          <div style="width: 50%" fxLayoutAlign="end">
            <app-skeleton-text [lines]="1" style="width: 50%"></app-skeleton-text>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #guest>
  <div style="width: 100%" *ngIf="!ltMd">
    <app-skeleton-text style="width: 70%" [lines]="1"></app-skeleton-text>
  </div>
</ng-template>
