<ng-container *ngIf="!!suppliers && !!supplierImages; else skeleton">
  <div [ngClass]="wrapperClass">
    <div [ngClass]="innerClass">
      <app-suppliers-slider
        [title]="title"
        [suppliers]="suppliers"
        [supplierImages]="supplierImages"
      ></app-suppliers-slider>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div [ngClass]="wrapperClass">
    <div [ngClass]="innerClass">
      <app-skeleton-supplier-slider-box></app-skeleton-supplier-slider-box>
    </div>
  </div>
</ng-template>
