import { Injectable } from '@angular/core';
import { ShippingDetailsDto } from '../../vo/shipping-details/shipping-details-dto';
import { BasicCountryDto } from '../../vo/shipping-details/basic-country-dto';
import { ShippingWithLegacyRates } from '../../vo/shipping/shipping';
import { ShippingDetails } from '../../vo/search-product-vo';

@Injectable({
  providedIn: 'root',
})
export class CountryMapperService {
  constructor() {}

  // TODO: return with Shipping after shipping migration
  mapShippingDetailsToShipping(dto: ShippingDetails[]): ShippingWithLegacyRates[] {
    return dto.map((item) => ({
      locations: item.countries,
      additionalCost: item.additionalCost,
      maxShippingDays: item.maxShippingTime,
      minShippingDays: item.minShippingTime,
      shippingCost: item.cost,
      shippingZoneRates: item.shippingZoneRates,
    }));
  }

  mapToObjectWithConcatenatedCountries(shippingDetails: ShippingDetailsDto[]): ShippingDetailsDto[] {
    return shippingDetails.map((shippingDetail) => {
      return {
        countries: this.getConcatenatedCoutries(shippingDetail),
        shippingZoneRates: shippingDetail.shippingZoneRates,
        cost: !!shippingDetail.cost ? shippingDetail.cost : 0,
        additionalCost: !!shippingDetail.additionalCost ? shippingDetail.additionalCost : 0,
        minShippingTime: shippingDetail.minShippingTime,
        maxShippingTime: shippingDetail.maxShippingTime,
      } as unknown as ShippingDetailsDto;
    });
  }

  private getConcatenatedCoutries(shippingDetail: ShippingDetailsDto): string[] {
    return shippingDetail.countries.map((country) => this.getConcatenatedCountryCode(country));
  }

  private getConcatenatedCountryCode(country: BasicCountryDto): string {
    return !!country.provinceCode ? country.countryCode + '-' + country.provinceCode : country.countryCode;
  }
}
