export class TabFileSettsavedOutVo {
  private _tabIndex;
  private _fileId;

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value) {
    this._tabIndex = value;
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value) {
    this._fileId = value;
  }

  constructor(tabIndex, fileId) {
    this.tabIndex = tabIndex;
    this.fileId = fileId;
  }
}
