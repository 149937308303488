import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Preference } from '../../vo/Preferences/preference';
import {
  DeletePreferencesStartAction,
  PreferencesActionTypes,
  SavePreferencesStartAction,
} from '../../store/preferences/preferences.action';
import {
  preferenceTypesSelector,
  shipsToPreferenceSelector,
  typesIdsByNameSelector,
  userPreferencesSelector,
} from '../../store/preferences/preferences.selector';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { PreferenceType } from '../../vo/Preferences/preference-type';
import { PreferenceNameEnum } from '../../vo/Preferences/preference-name.enum';
import { Actions, ofType } from '@ngrx/effects';
import { isEmpty } from 'lodash';
import { getCurrentUserIdSelector, locationByIpSelector } from '../../store/user/user.selector';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { CountryNode } from '../../utils/Countries';
import { CountriesManagerService } from '../countries-manager/countries-manager.service';
import { Utils } from '../../utils/utils';
import { CountryCodeNameMapperService } from '../country-code-name-mapper.service';

@Injectable({ providedIn: 'root' })
export class PreferenceStoreService {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private countryManager: CountriesManagerService
  ) {}

  public getPreferences(): Observable<Preference[]> {
    return this.store.select(userPreferencesSelector);
  }

  public getPreferenceTypes(): Observable<PreferenceType[]> {
    return this.store.select(preferenceTypesSelector);
  }

  public savePreferences(savedPreferences: Preference[]): Observable<boolean> {
    return combineLatest([this.getPreferences(), this.store.select(getCurrentUserIdSelector)]).pipe(
      take(1),
      switchMap(([storedPreferences, userId]) => {
        const preferencesWithUserIds: Preference[] = savedPreferences.map((pref) => ({
          ...pref,
          userId: userId,
        }));
        const savedPreferenceTypeIds = preferencesWithUserIds.map((pref) => pref.preferenceTypeId);
        const preferenceIdsToDelete = this.getPreferenceIdsToDelete(
          preferencesWithUserIds,
          storedPreferences,
          savedPreferenceTypeIds
        );
        if (!isEmpty(preferenceIdsToDelete)) {
          this.deletePreferences(preferenceIdsToDelete);
        }
        const notCurrentlySavedValues = this.getNotCurrentlySavedPreferences(preferencesWithUserIds, storedPreferences);
        if (!isEmpty(notCurrentlySavedValues)) {
          this.store.dispatch(new SavePreferencesStartAction(notCurrentlySavedValues));
          return this.subscribeToSavePreferenceSuccess();
        } else {
          return of(true);
        }
      })
    );
  }

  private getNotCurrentlySavedPreferences(
    preferencesToSave: Preference[],
    currentPreferences: Preference[]
  ): Preference[] {
    return preferencesToSave.filter(
      (preference) =>
        !currentPreferences.some(
          (pref) =>
            pref.preferenceTypeId === preference.preferenceTypeId && pref.preferenceValue === preference.preferenceValue
        )
    );
  }

  private getPreferenceIdsToDelete(
    preferencesToSave: Preference[],
    currentPreferences: Preference[],
    savedPreferenceTypeIds?: number[]
  ): number[] {
    const idsToDelete: number[] = [];
    const preferencesToDelete = currentPreferences
      .filter((pref) => savedPreferenceTypeIds.includes(pref.preferenceTypeId))
      .filter(
        (preference) =>
          !preferencesToSave.some(
            (pref) =>
              pref.preferenceTypeId === preference.preferenceTypeId &&
              pref.preferenceValue === preference.preferenceValue
          )
      );
    preferencesToDelete.forEach((pref) => {
      idsToDelete.push(pref.id);
    });
    return idsToDelete;
  }

  public deletePreferences(preferenceValueIds: number[]): void {
    this.store.dispatch(new DeletePreferencesStartAction(preferenceValueIds));
  }

  public subscribeToSavePreferenceSuccess(): Observable<boolean> {
    return this.actions$.pipe(
      ofType(PreferencesActionTypes.SAVE_PREFERENCES_SUCCESS),
      map(() => true)
    );
  }

  public getTypeIdsByNames(): Observable<PreferenceTypeRecord> {
    return this.store.select(typesIdsByNameSelector);
  }

  public getPreferencesWithTypes(): Observable<[Preference[], PreferenceTypeRecord]> {
    return combineLatest([this.getPreferences().pipe(filter((data) => !!data)), this.getTypeIdsByNames()]);
  }

  public getPreferencesByPreferenceName(prefName: PreferenceNameEnum): Observable<Preference[]> {
    return this.getPreferencesWithTypes().pipe(
      switchMap(([preferences, types]) => {
        return of(preferences.filter((preference) => preference.preferenceTypeId === types[prefName]));
      })
    );
  }

  public savePreferencesByName(prefName: PreferenceNameEnum, preferences: Preference[]): void {
    this.getTypeIdsByNames()
      .pipe(
        take(1),
        map((types) => {
          return preferences.map((preference) => ({
            ...preference,
            preferenceTypeId: types[prefName],
          }));
        }),
        switchMap((value) => this.savePreferences(value))
      )
      .subscribe();
  }

  private getCountryFromShipsToPrefObs(): Observable<CountryNode> {
    return this.store.select(shipsToPreferenceSelector).pipe(
      filter((pref) => !!pref),
      map((pref) =>
        pref.length > 0 ? this.countryManager.getOnlyCountries().find((county) => county.name === pref[0]) : null
      )
    );
  }

  private getCountryFromIpObs(): Observable<CountryNode> {
    return this.store.select(locationByIpSelector).pipe(
      filter((country) => !!country),
      map((countyCode) => this.countryManager.getOnlyCountries().find((country) => country.code === countyCode))
    );
  }

  public getPreferredLocation(): Observable<CountryNode> {
    return this.store.select(isAuthenticatedSelector).pipe(
      filter((loggedIn) => !Utils.isNullOrUndefined(loggedIn)),
      switchMap((loggedId) =>
        loggedId
          ? this.getCountryFromShipsToPrefObs().pipe(
              switchMap((country) => (!!country ? of(country) : this.getCountryFromIpObs()))
            )
          : this.getCountryFromIpObs()
      )
    );
  }
}

export type PreferenceTypeRecord = Record<PreferenceNameEnum, number>;
