<div class="category-card-list" *ngIf="widgets$ | async as widgets; else skeleton">
  <app-category-card-item
    *ngFor="let widget of widgets"
    [widget]="widget"
    [cardHeight]="cardHeight"
  ></app-category-card-item>
</div>

<ng-template #skeleton>
  <div class="category-card-list">
    <app-skeleton-category-card-item
      [cardHeight]="cardHeight"
      *ngFor="let _ of [].constructor(maxNumberOfWidgets)"
    ></app-skeleton-category-card-item>
  </div>
</ng-template>
