import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { SetUpAccountFormComponent } from '../../../../../shared/components/set-up-account-form/set-up-account-form.component';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { PreferencesActionTypes } from '../../../../../store/preferences/preferences.action';
import { UserActionTypes } from '../../../../../store/user/user.actions';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { SetUpAccountFormValue } from '../../../../../shared/components/set-up-account-form/model/set-up-account-form-value';
import { SetUpAccountFormService } from '../../../../../shared/components/set-up-account-form/service/set-up-account-form.service';

import { RolesEnum } from '../../../../../vo/roles/roles';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
  providers: [SetUpAccountFormService],
})
export class GeneralInformationComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('setUpAccountFormComponent') setUpForm: SetUpAccountFormComponent;

  value: SetUpAccountFormValue;
  isLoading = false;
  private unsubscribeAll: Subject<void>;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;

  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private actions$: Actions,
    private setUpAccountFormService: SetUpAccountFormService
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.setUpAccountFormService
      .getSetUpAccountFormValueRetailer()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((setUpAccountFormValue) => {
        this.value = setUpAccountFormValue;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  saveClicked(): void {
    this.setUpForm.setUpAccountFormGroup.markAllAsTouched();
    if (!this.setUpForm.isValid) {
      return;
    }
    if (this.setUpForm.form.dirty) {
      this.setUpForm.saveUserDetails().subscribe();
      this.subscribeToUpdateAndGoToNextStep();
    } else {
      this.goToNextStep();
    }
  }

  private subscribeToUpdateAndGoToNextStep(): void {
    const actionsToSubscribeTo = this.getActionsToSubscribeTo();
    combineLatest(actionsToSubscribeTo)
      .pipe(take(1))
      .subscribe(() => {
        this.goToNextStep();
      });
  }

  private isUserDirty(): boolean {
    return this.setUpForm.firstName.dirty || this.setUpForm.lastName.dirty || this.setUpForm.retailerPhoneNumber.dirty;
  }

  private isCountryDirty(): boolean {
    return this.setUpForm.retailerCountry.dirty;
  }

  private getActionsToSubscribeTo(): Observable<any>[] {
    const actionsToSubscribeTo: Observable<any>[] = [];
    if (this.isUserDirty()) {
      actionsToSubscribeTo.push(this.actions$.pipe(ofType(UserActionTypes.UPDATE_USER_SUCCESS)));
    }
    if (this.isCountryDirty()) {
      actionsToSubscribeTo.push(this.actions$.pipe(ofType(PreferencesActionTypes.SAVE_PREFERENCES_SUCCESS)));
    }
    return actionsToSubscribeTo;
  }

  get rolesEnum(): typeof RolesEnum {
    return RolesEnum;
  }
}
