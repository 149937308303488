import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { rcatalogsSelector } from 'app/store/rcatalogs/rcatalogs.selector';
import { Utils } from 'app/utils/utils';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { NeedStoreDialogService } from '../../shared/components/dialogs/need-store-dialog/need-store-dialog.service';
import { UpsellDialogComponent, UpsellDialogData } from '../../shared/components/upsell-dialog/upsell-dialog.component';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { getSelectedEcomByRole, hasEcomSelector } from '../../store/ecom/ecom.selector';
import { getCurrentUserSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { EcomVO } from '../ecom/ecom.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { permissionValidatorObj } from './permission.validator';

export enum Action {
  CONTACTS_READ = 'CONTACTS_READ',
  CHAT_ACCESS = 'CHAT_ACCESS',
  SYNC_CATALOG_MARKETPLACE = 'SYNC_CATALOG_MARKETPLACE',
  SYNC_ORDERS = 'SYNC_ORDERS',
  SYNC_CATALOG_DATAFEED = 'SYNC_CATALOG_DATAFEED',
  EDIT_PIM = 'EDIT_PIM',
  EDIT_VARIANT_RENAMING = 'EDIT_VARIANT_RENAMING',
  PRODUCT_ADD_TO_CATALOG = 'PRODUCT_ADD_TO_CATALOG',
  USE_SUPPLIER_TASK = 'USE_SUPPLIER_TASK',
  CREATE_CATALOG = 'CREATE_CATALOG',
}

const FREE_PLANS = [120, 100];

@Injectable({
  providedIn: 'root',
})
export class AuthorizationEcomService {
  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private mrpService: MarketplaceEcomService,
    private needStoreDialogService: NeedStoreDialogService
  ) {}

  public hasPermission(action: Action, ecom: EcomVO, isAdmin?: boolean, payload?: Partial<PermissionPayload>): boolean {
    if (isAdmin) {
      return true;
    }
    if (Utils.isNullOrUndefined(ecom) || Utils.isNullOrUndefined(ecom.subscriptions)) {
      return false;
    }

    return permissionValidatorObj[action](ecom, payload);
  }

  public hasPermissionObs(action: Action, payload?: Partial<PermissionPayload>): Observable<boolean> {
    return this.isAdmin().pipe(
      switchMap((isAdmin: boolean) => {
        if (isAdmin) {
          return of(true);
        }
        return this.mrpService.getEcomWithSubscriptionFromStore().pipe(
          map((ecom: EcomVO) => {
            if (!ecom || !ecom.subscriptions) {
              return false;
            }
            return permissionValidatorObj[action](ecom, payload);
          })
        );
      })
    );
    // return permissionValidatorObj[action](ecom, payload);
  }

  public openUpsellDialogWhenCatalogCreateNumReachedBySelectedEcom(): Observable<boolean> {
    return combineLatest([
      this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)),
      this.store.select(rcatalogsSelector),
    ]).pipe(
      switchMap(([ecom, rcatalogs]) =>
        this.openUpsellDialogByHasPermission(
          Action.CREATE_CATALOG,
          { numberOfMarketplaceCatalogs: rcatalogs?.length },
          ecom
        )
      )
    );
  }

  public openUpsellDialogWhenCatalogCreateNumReached(ecom: EcomVO): Observable<boolean> {
    return this.store.select(rcatalogsSelector).pipe(
      map((rcatalogs) => rcatalogs.length),
      switchMap((rcatalogsNum) =>
        this.openUpsellDialogByHasPermission(Action.CREATE_CATALOG, { numberOfMarketplaceCatalogs: rcatalogsNum }, ecom)
      )
    );
  }

  public openUpsellDialogByHasPermission(
    action: Action,
    payload?: Partial<PermissionPayload>,
    selectedEcom?: EcomVO,
    descriptionTranslationKey = 'PLAN_UPSELL.DESCRIPTION',
    variant: 'marketplace' | 'datafeed' = 'marketplace'
  ): Observable<boolean> {
    return this.hasPermissionObs(action, payload).pipe(
      tap((hasPermission) => {
        if (!hasPermission) {
          this.openSubscribeDialog(descriptionTranslationKey, selectedEcom, variant);
        }
      })
    );
  }

  public openSubscribeDialog(
    descriptionTranslationKey = 'PLAN_UPSELL.DESCRIPTION',
    selectedEcom?: EcomVO,
    variant: 'marketplace' | 'datafeed' = 'marketplace'
  ): void {
    this.store
      .select(hasEcomSelector, RolesEnum.RETAILER)
      .pipe(
        take(1),
        switchMap((hasEcom) =>
          hasEcom ? this.openUpsellDialog(descriptionTranslationKey, selectedEcom, variant) : this.openNeedStoreDialog()
        )
      )
      .subscribe();
  }

  private openNeedStoreDialog(): Observable<any> {
    return this.needStoreDialogService.open(RolesEnum.RETAILER);
  }

  private openUpsellDialog(
    descriptionTranslationKey = 'PLAN_UPSELL.DESCRIPTION',
    selectedEcom?: EcomVO,
    variant: 'marketplace' | 'datafeed' = 'marketplace'
  ): Observable<any> {
    const data: UpsellDialogData = descriptionTranslationKey
      ? {
          descriptionTranslationKey,
          selectedEcom,
          variant,
        }
      : null;
    return this.dialog
      .open(UpsellDialogComponent, {
        data: data,
        maxHeight: '95vh',
        width: '1300px',
        maxWidth: '90vw',
        restoreFocus: false,
        autoFocus: false,
      })
      .afterClosed();
  }

  isAuthenticatedUserOrNot(): Observable<boolean> {
    return this.store.select(isAuthenticatedSelector);
  }

  isAuthenticatedUser(): Observable<boolean> {
    return this.store.select(isAuthenticatedSelector).pipe(
      filter((authenticated) => authenticated),
      distinctUntilChanged()
    );
  }

  isGuest(): Observable<boolean> {
    return this.store.select(isAuthenticatedSelector).pipe(
      filter((authenticated) => !authenticated),
      distinctUntilChanged()
    );
  }

  isAdmin(): Observable<boolean> {
    return this.store.select(getCurrentUserSelector).pipe(
      map((user) => user?.role === 3),
      distinctUntilChanged()
    );
  }
}

export interface PermissionPayload {
  approveNeeded: boolean;
  premium: boolean;
  isHidden: boolean;
  numberOfMarketplaceCatalogs: number;
  numberOfFindSupplierCatalogs: number;
  catalogType: 'findSupplier' | 'marketplace';
}
