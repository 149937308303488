import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class VirtualPageViewGtmAction implements GtmAction {
  event = GtmEvent.virtual_page_view;

  constructor(public payload: VirtualPageViewGtmActionPayload) {}
}

export interface VirtualPageViewGtmActionPayload {
  cleaned_page_url: string;
  page_title: string;
}
