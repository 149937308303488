import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { AppState } from '../../../../../app.state';

@Component({
  selector: 'app-finishing-up',
  templateUrl: './finishing-up.component.html',
  styleUrls: ['./finishing-up.component.scss'],
})
export class FinishingUpComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  unsubscribeAll: Subject<void>;

  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private store: Store<AppState>,
    private router: Router
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleUpgradeLaterClick(): void {
    this.router.navigate(['/']);
    this.goToNextStep(true);
  }
}
