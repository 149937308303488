<div fxLayout="column" fxLayoutGap="8px" *conditionLoading="!loading">
  <ng-container *ngIf="true">
    <div *ngIf="!connected; else connectedCard" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="typography-body-large-2">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.LWPP' | translate }}</div>
        <app-custom-icon [icon]="'close'" [type]="'danger'"></app-custom-icon>
      </div>
      <span id="cwppBtn" class="h-40"></span>
    </div>
    <div
      *ngIf="deletedAccounts.length > 0 && !connected"
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxLayoutGap="20px"
    >
      <div class="typography-body-1">
        {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.MESSAGE' | translate }}
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="20px"
        fxLayoutAlign="stretch center"
        fxLayoutAlign.lt-md="center center"
      >
        <mat-form-field id="domain-selector" class="w-100p input-without-padding" appearance="fill">
          <mat-label>{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECTED_EMAILS' | translate }}</mat-label>
          <mat-select panelClass="mat-select-display-change" [(value)]="selectedAccount">
            <mat-option *ngFor="let account of deletedAccounts" [value]="account">
              {{ account.email }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          fxFlex="1 0 auto"
          (click)="setDeletedToNullByEmail()"
          mat-button
          class="add-new-store syncee-blue-button"
        >
          {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.CONNECTION' | translate }}
        </button>
      </div>
    </div>
    <!--    <div fxLayout="row" fxLayoutAlign="end center">-->
    <!--            <img class="h-32" src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_SbyPP_mc_vs_dc_ae.jpg" />-->
    <!--    </div>-->
  </ng-container>
  <ng-container *ngTemplateOutlet="adminTemplate"> </ng-container>
</div>

<ng-template #adminTemplate>
  <div class="alugy" fxLayout="column" fxLayoutGap="8px">
    <div *ngIf="userPaypalEmail">
      <b>{{ 'LOGIN_WITH_PAYPAL.EMAIL' | translate }}: {{ userPaypalEmail }}</b>
    </div>
    <div *ngIf="deletedAccounts.length > 0">
      <b>{{ 'LOGIN_WITH_PAYPAL.DELETED_EMAILS' | translate }}:</b>
      <mat-list *ngFor="let account of deletedAccounts">
        <mat-list-item
          ><div class="alugy">{{ account.email }}</div></mat-list-item
        >
      </mat-list>
    </div>
    <div *ngIf="!userPaypalEmail && deletedAccounts.length == 0">
      <b>{{ 'LOGIN_WITH_PAYPAL.NO_EMAIL' | translate }}</b>
    </div>
  </div>
</ng-template>

<ng-template #connectedCard>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="typography-body-large-2">{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_PAYPAL' | translate }}</div>
      <app-custom-icon [icon]="'check'" [type]="'success'"></app-custom-icon>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="center end"
      fxLayoutGap="10px"
    >
      <span
        ><b>{{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.YOUR_PAYPAL_EMAIL' | translate }}</b> {{ userPaypalEmail }}</span
      >
      <button mat-button color="warn" (click)="openDialog()">
        {{ 'BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_BTN' | translate }}
      </button>
    </div>
  </div>
</ng-template>
