import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class GsStepSupplierAction implements GtmAction {
  event = GtmEvent.gs_step_supplier;

  constructor(public payload: GsStepSupplierActionPayload) {}
}

export interface GsStepSupplierActionPayload {
  step_id: string;
  index: number;
  has_ecom?: 'has_ecom' | 'has_no_ecom';
}
