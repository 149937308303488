import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoginGtmAction } from '../google-tag-manager/actions/login';
import { GtmManagerService } from '../google-tag-manager/gtm-manager.service';
import { InitAppService } from '../shared/init-app.service';
import { UserService } from '../user/user.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  private readonly loginPath = '/login';

  constructor(
    private _router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private initAppService: InitAppService,
    private gtmManagerService: GtmManagerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isTokenPresent()) {
      if (!this.initAppService.isInitializedApp) {
        return this.userService.getUserDetailsForceWithoutNavigate().pipe(
          map((resp) => true),
          tap(() => this.gtmManagerService.pushTag(new LoginGtmAction({ method: 'cross_login' })))
        );
      } else {
        return this.userService.getUserDetails().pipe(map((resp) => true));
      }
    } else {
      console.warn('Token is not present, redirecting to login.');
      this._router.navigate([this.loginPath], { queryParams: { requestUrl: state.url } });
    }
  }
}
