<ng-container>
  <div fxLayout="column" fxLayoutAlign="center center">
    <app-subscription-upsell
      [variant]="'marketplace'"
      [title]="'GETTING_STARTED.STEP_PAGE.FINISHING_UP.WITH_ECOM.TITLE' | translate"
      [descriptionTranslationKey]="'GETTING_STARTED.STEP_PAGE.FINISHING_UP.WITH_ECOM.SUBTITLE'"
      (laterEvent)="handleUpgradeLaterClick()"
      (redirectedEvent)="goToNextStep()"
      [hasActionBar]="true"
      buttonClass="syncee-white-button mt-20"
      class="w-100p"
    ></app-subscription-upsell>
  </div>
</ng-container>
