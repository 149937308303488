import { Injectable } from '@angular/core';
import { SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { SuppliersService } from 'app/service/suppliers/suppliers.service';
import { Observable } from 'rxjs';

@Injectable()
export class SupplierCurrencyService {
  constructor(private _supplierService: SuppliersService) {}

  patchCurrency(currency: string): Observable<void> {
    return this._supplierService.patchSupplier({ paymentCurrency: currency });
  }
}
