<div
  fxLayout="column"
  fxLayoutAlign="start"
  fxLayoutGap="24px"
  class="outer-container"
  [ngClass]="wrapperClass"
  [style.background-color]="background"
>
  <div [ngClass]="innerWrapperClass" class="inner-wrapper">
    <div
      class="ml-4 mb-24"
      [fxLayout]="titlePosition === 'start' ? 'column' : 'row'"
      [fxLayoutAlign]="titlePosition === 'start' ? 'start' : 'center'"
      fxLayoutGap="4px"
    >
      <h2 [ngClass]="'h2'" [ngClass.lt-md]="'h3'" *ngIf="title; else skeletonTitle">{{ title }}</h2>

      <div
        [ngClass]="'typography-h2-subtitle'"
        [ngClass.lt-md]="'typography-h3-subtitle'"
        *ngIf="description; else skeletonDescripton"
      >
        {{ description }}
      </div>
    </div>

    <app-category-card-list [widgets$]="widgets$" [maxNumberOfWidgets]="maxNumberOfWidgets"></app-category-card-list>
  </div>
</div>

<ng-template #skeletonTitle>
  <app-skeleton-display-text></app-skeleton-display-text>
</ng-template>

<ng-template #skeletonDescripton>
  <span class="skeleton-description">
    <app-skeleton-text [lines]="1"></app-skeleton-text>
  </span>
</ng-template>
