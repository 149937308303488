import { CommonModule, NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IncludesPipe } from 'app/shared/pipes/includes.pipe';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ProductCardModule } from '../../../main/product-card/product-card.module';
import { SearchProductVO } from '../../../vo/search-product-vo';
import { CatalogStatusCheckPipe } from '../../pipes/catalog-status-check.pipe';
import { ExploreProductsAdvertisementComponent } from '../explore-products-advertisement/explore-products-advertisement.component';

@Component({
  selector: 'app-product-card-list',
  templateUrl: './product-card-list.component.html',
  styleUrls: ['./product-card-list.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    ProductCardModule,
    ExploreProductsAdvertisementComponent,
    NgIf,
    MatProgressSpinnerModule,
    FlexModule,
    NgTemplateOutlet,
    NgStyle,
    CommonModule,
    FlexLayoutModule,
    CatalogStatusCheckPipe,
    IncludesPipe,
  ],
  animations: [fuseAnimations],
})
export class ProductCardListComponent implements OnInit {
  @Input() productCardWidth: ProductCardWidths = {
    xs: '50%',
    sm: '50%',
    md: '33.333%',
    lg: '20%',
    xl: '16.666%',
  };
  @Input() products: SearchProductVO[];
  @Input() from: number;
  @Input() areProductsLoading: boolean;
  @Input() hasError: boolean;
  @Input() numberOfProducts: number;
  @Input() ecomCurrency: string;
  @Input() selectable: boolean;
  @Input() adsPositions: number[];
  @Input() hasAds = false;
  @Input() needsFillers = false;
  @Input() isFreePlan: boolean;
  @Input() hiddenActionBar = false;
  @Input() generateMicrodata = false;
  @Output() selectionChange = new EventEmitter<string[]>();

  selected: string[] = [];
  constructor() {}

  ngOnInit(): void {}

  handleSelectionChange(id: string, selected: boolean): void {
    if (selected) {
      this.selected.push(id);
    } else {
      this.selected = this.selected.filter((item) => item !== id);
    }
    this.selectionChange.emit(this.selected);
  }

  trackByProduct(index: number, product: SearchProductVO): string {
    return product.ID;
  }
}

export interface ProductCardWidths {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
}
