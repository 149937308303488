import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ScrollService } from 'app/service/scroll/scroll.service';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoadingScreenService } from '../../../service/loading-screen/loading-screen.service';
import { RestResponse } from '../../../service/rest/rest-response';
import { TaskWatcherService } from '../../../service/task/task-watcher.service';
import { TaskFile } from '../../../service/taskwizard/taskwizard-update-response';
import { TaskwizardUpdateService } from '../../../service/taskwizard/taskwizard-update.service';
import { TaskwizardService } from '../../../service/taskwizard/taskwizard.service';
import { Constants } from '../../../utils/Constants';
import { Utils } from '../../../utils/utils';
import { RolesEnum } from '../../../vo/roles/roles';
import { RecentlyDroppedItemNotifier } from '../../drag-ndrop-zone/recently-dropped-item-notifier';
import { ChipColor } from '../../droppable-input/chip';
import { StepBase } from '../step-base';
import { ProductdetailsbuilderComponent } from './mapping/productdetailsbuilder/productdetailsbuilder.component';
import { ConnecttypepopupComponent } from './modals/connecttypepopup.component';
import { PreviewModalComponent } from './modals/preview-modal.component';
import { TaskWizardSampleService } from './services/task-wizard-sample.service';
import { FilemanagertabComponent } from './tabs/filemanagertab.component';
import { MappingInputVo } from './vo/mapping-vo/mapping-input-vo';
import { ProdSampleInputVo } from './vo/prod-sample-input-vo';
import { TabFileDelTaskfileOutVo } from './vo/tab-file-del-taskfile-out-vo';
import { TabFileManagerInputVo } from './vo/tab-file-manager-input-vo';
import { TabFileManagerOutputVo } from './vo/tab-file-manager-output-vo';
import { TabFileSettsavedOutVo } from './vo/tab-file-settsaved-out-vo';

export type ROLE_TYPE =
  | 'SUPPLIER'
  | 'DF_SHOPIFY'
  | 'DF_JUMPSELLER'
  | 'RETAILER_VARIANTS_UPDATE'
  | 'RETAILER_JUMPSELLER_VARIANTS_UPDATE'
  | 'DF_ECWID'
  | 'DF_SHOPRENTER';

@Component({
  selector: 'app-addfiles',
  templateUrl: './addfiles.component.html',
  styleUrls: ['./addfiles.component.scss'],
  providers: [RecentlyDroppedItemNotifier, TaskWizardSampleService],
})
export class AddfilesComponent implements OnInit, StepBase, AfterViewInit {
  private readonly TAB_TEXT_NEW_FILE = 'New File';
  private readonly TAB_TEXT_SHOPIFY_STORE = 'Shopify Store';
  // Ha csak az a cél, hogy az utolsó mindig el legyen tárolva és ha valaki subscribe-ol, akkor
  // megkapja rögtön az utolsót, akkor elég egy BehaviorSubject
  private _inputSync: ReplaySubject<number> = new ReplaySubject<number>(1);

  @ViewChild('addFilesContainer', { read: ElementRef, static: true }) addFilesContainer: ElementRef;

  @Output() sourceTypeCallBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() stateChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() set currentTaskId(value: number) {
    this._inputSync.next(value);
  }

  @Input() comments;
  userRole: RolesEnum;

  @Input() role: ROLE_TYPE = 'SUPPLIER';

  @ViewChildren('fileManagerTabs') private fileManagerTabs: QueryList<FilemanagertabComponent>;

  _currentTaskId: number;

  public tabs: TabData[];
  /** This variable is purpose to check if tabs array is empty in "logic"
   * or not (the tabs array cant be null, and have to contains temp tab if no one real tab defined....)*/
  isEmptyTabs = true;
  selectedTab = 0;
  mappingInputVoArray: Array<MappingInputVo> = null;
  showFileAddBtn = true;

  private _selectedConnectionType: string = null;

  get selectedConnectionType(): string {
    return this._selectedConnectionType;
  }

  set selectedConnectionType(value: string) {
    if (value === Constants.SHOPIFY_CONNECTION_TYPE) {
      this.showFileAddBtn = false;
    }
    this._selectedConnectionType = value;
    this.sourceTypeCallBack.emit(value);
  }

  currentProdSampleInputVO: ProdSampleInputVo = null;

  @ViewChild('productDetailsBuilder', { static: false }) productDetailsBuilder: ProductdetailsbuilderComponent;
  expanded = false;

  constructor(
    private dialog: MatDialog,
    private twUpdateService: TaskwizardUpdateService,
    private taskwizardService: TaskwizardService,
    private loadingService: LoadingScreenService,
    private taskWatcherService: TaskWatcherService,
    private translate: TranslateService,
    private scrollService: ScrollService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    switch (this.role) {
      case 'SUPPLIER':
        this.userRole = RolesEnum.SUPPLIER;
        break;
      case 'RETAILER_VARIANTS_UPDATE':
        this.userRole = RolesEnum.RETAILER;
        break;
      default:
        this.userRole = RolesEnum.RETAILER;
    }

    this._inputSync.subscribe((taskId: number) => {
      this.init(taskId);
    });
  }

  init(taskId): void {
    this._currentTaskId = taskId;

    if (this.twUpdateService.isUpdate) {
      this.selectedConnectionType = this.twUpdateService.data.task.sourceType;
    }
    if (!this.twUpdateService.isUpdate || this.twUpdateService.data.files.length < 1) {
      this.tabs = [
        {
          label: this.translate.instant('TASKWIZARD.ADDFILES.MODALS.CONNECTION'),
          inputs: null,
          fileId: null,
          chipColor: null,
        },
      ];
      return;
    }
    if (this.twUpdateService.data.isTaskRunning) {
      this.taskWatcherService.clearTaskRunningWatcher();
      this.taskWatcherService.addRunnedTaskToWatcher(this._currentTaskId);
      this.taskWatcherService.startTaskWatcher();
      this.loadingService.startLoading();
      this.taskWatcherService.getTaskRunFinishedObservable().subscribe((response) => {
        if (response.data.length > 0) {
          this.taskWatcherService.stopWatcher();
          this.loadingService.stopLoading();
        }
      });
    }

    this.tabs = [];
    this.twUpdateService.data.files.forEach((taskFile: TaskFile, index) => {
      const name =
        this.twUpdateService.data.task.sourceType === Constants.SHOPIFY_CONNECTION_TYPE
          ? this.TAB_TEXT_SHOPIFY_STORE
          : this.trimNameToCharLimit(Utils.getFileNameFromPath(taskFile.remotepath), 23);

      const inputVo = new TabFileManagerInputVo();
      inputVo.taskId = this._currentTaskId;
      inputVo.tabIndex = index;
      inputVo.conType = this.twUpdateService.data.task.sourceType;
      inputVo.taskFile = taskFile;
      inputVo.isDownloading = this.twUpdateService.data.task.isDownloadRunning;
      this.tabs.push({
        label: name,
        inputs: inputVo,
        fileId: Utils.isEmptyObject(taskFile.settings) ? null : taskFile.fileId,
        chipColor: {
          backgroundColor: Constants.CHIP_BACKGROUND_COLORS[index],
          color: Constants.CHIP_COLORS[index],
          iconColor: Constants.CHIP_ICON_COLORS[index],
          iconActiveColor: Constants.CHIP_ICON_COLORS_ACTIVE[index],
        },
      });
    });
    this.selectedTab = this.tabs.length - 1;
    this.isEmptyTabs = false;
    this.refreshMapping();
  }

  refreshMapping(retry = false): void {
    const mappingInpVoArray = new Array<MappingInputVo>();
    for (const tab of this.tabs) {
      if (Utils.isNullOrUndefined(tab.fileId)) {
        continue;
      }
      const mappingInpObj = new MappingInputVo();
      mappingInpObj.fileId = tab.fileId;
      mappingInpObj.fileName = tab.label;
      mappingInpVoArray.push(mappingInpObj);
    }
    if (mappingInpVoArray.length > 0) {
      this.initProductBuilder(mappingInpVoArray);
    }
    this.initProductSample(this.selectedTab, retry);
  }

  addNewFile(): void {
    if (Utils.isNullOrUndefined(this._currentTaskId)) {
      return;
    }
    if (this.selectedConnectionType) {
      this.addNewConnection(this.selectedConnectionType);
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.width = '600px';
    dialogConfig.data = this._currentTaskId;

    const dialogRef = this.dialog.open(ConnecttypepopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((conType) => {
      console.log(conType);
      if (conType) {
        this.addNewConnection(conType);
      }
    });
  }

  addNewConnection(conType): void {
    this.selectedConnectionType = conType;
    this.addFileTabManager();
  }

  addFileTabManager(): void {
    const inputVo = new TabFileManagerInputVo();
    inputVo.conType = this.selectedConnectionType;
    inputVo.taskId = this._currentTaskId;

    if (this.isEmptyTabs) {
      inputVo.tabIndex = 0;
      this.tabs[0] = {
        label: this.setNewFileTabText(this.selectedConnectionType),
        inputs: inputVo,
        fileId: null,
        chipColor: {
          backgroundColor: Constants.CHIP_BACKGROUND_COLORS[0],
          color: Constants.CHIP_COLORS[0],
          iconColor: Constants.CHIP_ICON_COLORS[0],
          iconActiveColor: Constants.CHIP_ICON_COLORS_ACTIVE[0],
        },
      };
      this.isEmptyTabs = false;
    } else {
      inputVo.tabIndex = this.tabs.length;
      this.tabs.push({
        label: this.setNewFileTabText(this.selectedConnectionType),
        inputs: inputVo,
        fileId: null,
        chipColor: {
          backgroundColor: Constants.CHIP_BACKGROUND_COLORS[this.tabs.length],
          color: Constants.CHIP_COLORS[this.tabs.length],
          iconColor: Constants.CHIP_ICON_COLORS[this.tabs.length],
          iconActiveColor: Constants.CHIP_ICON_COLORS_ACTIVE[this.tabs.length],
        },
      });
      this.selectedTab = this.tabs.length - 1;
    }
  }

  /**This callback function will be invoked after confirmed delete popup*/
  removeTabEvent(outputVo: TabFileDelTaskfileOutVo): void {
    if (!Utils.isNullOrUndefined(outputVo.fileId)) {
      this.taskwizardService.deleteTaskFile(outputVo.taskId, outputVo.fileId).subscribe(
        () => {
          this.removeFile(outputVo, true);
        },
        (error) => console.log(error)
      );
    } else {
      this.removeFile(outputVo);
    }
  }

  removeFile(outputVo: TabFileDelTaskfileOutVo, isDeletedFileFromDB = false): void {
    if (this.tabs.length === 1) {
      this.tabs[0] = { label: 'Add new file', inputs: null, fileId: null, chipColor: null };
      this.selectedTab = 0;
      this.currentProdSampleInputVO = null;
      this.isEmptyTabs = true;
      this.showFileAddBtn = true;
      // this.stepperService.disableStepByIndex(StepperService.SCHEDULE_STEP);
      if (isDeletedFileFromDB) {
        this.initProductBuilder(null);
        this.twUpdateService.data.mapping = null;
      }
    } else {
      this.tabs.splice(outputVo.tabIndex, 1);
      console.log(this.tabs);
      console.log(this.tabs.length - 1);
      this.selectedTab = this.tabs.length - 1;
      this.refreshMapping();
    }
  }

  /**After saving file settings will be invoked this function, where will be set the fileId for the selected file**/
  setFileIDCallback(tabFileSettsavedVO: TabFileSettsavedOutVo): void {
    this.tabs[tabFileSettsavedVO.tabIndex].fileId = tabFileSettsavedVO.fileId;
    this.refreshMapping(true);
  }

  setTabNameCallback(tabFileManagerVO: TabFileManagerOutputVo): void {
    this.tabs[tabFileManagerVO.tabIndex].label = this.trimNameToCharLimit(tabFileManagerVO.tabName, 23);
  }

  /**update input params for product builder*/
  initProductBuilder(mappingInpVoArray: Array<MappingInputVo>): void {
    this.mappingInputVoArray = mappingInpVoArray;
  }

  /**update input params for product sample list*/
  initProductSample(index, retry = false): void {
    this.selectedTab = index;
    if (index >= this.tabs.length || index < 0) {
      return;
    }
    const tabData = this.tabs[index];
    this.setFileTabBorderColor(tabData.chipColor);
    if (Utils.isNullOrUndefined(tabData.fileId)) {
      this.currentProdSampleInputVO = null;
      return;
    }
    const prodSampleVO = new ProdSampleInputVo();
    prodSampleVO.fileName = tabData.label;
    prodSampleVO.fileId = tabData.fileId;
    prodSampleVO.taskId = this._currentTaskId;
    prodSampleVO.chipColor = tabData.chipColor;
    prodSampleVO.retry = retry;
    prodSampleVO.extension = tabData.inputs.taskFile.extension;

    // this.elRef.nativeElement.querySelector('.file-mat-tab-group').
    this.currentProdSampleInputVO = prodSampleVO;
  }

  setFileTabBorderColor(color: ChipColor): void {
    this.addFilesContainer.nativeElement.style.cssText = '--tab-ink-bar-color: ' + color.backgroundColor;
  }

  /**set new file tab name**/
  setNewFileTabText(conType: string): string {
    if (conType === Constants.SHOPIFY_CONNECTION_TYPE) {
      return this.TAB_TEXT_SHOPIFY_STORE;
    } else {
      return this.TAB_TEXT_NEW_FILE;
    }
  }

  trimNameToCharLimit(name: string, limit: number): string {
    return name.substring(name.length - limit, name.length);
  }

  isStepValid(): boolean {
    return true;
  }

  closeMappingAndScrollTop(): void {
    this.scrollService.scrollToTop();
    this.expanded = false;
  }

  saveStep(): Observable<any> {
    this.closeMappingAndScrollTop();

    return new Observable<any>((observe) => {
      this.productDetailsBuilder.saveStep().subscribe((resp: RestResponse) => {
        this.fileManagerTabs.first.saveScheduler();
        this.taskWatcherService.clearTaskRunningWatcher();
        this.taskWatcherService.addRunnedTaskToWatcher(this._currentTaskId);
        this.taskWatcherService.startTaskWatcher();
        this.loadingService.startLoading(true);
        this.stateChanged.emit();
        this.taskWatcherService.getTaskRunFinishedObservable().subscribe((response) => {
          if (response.length > 0) {
            this.taskWatcherService.stopWatcher();
            this.loadingService.stopLoading();
            observe.next(resp);
            observe.complete();
          }
        });
      });
    });
  }

  onSuccessfulSave(response: RestResponse): void {
    this.productDetailsBuilder.onSuccessfulSave(response);
  }

  refreshSettings(fileId: number): void {
    this.fileManagerTabs.forEach((fileManagerComponent) => {
      if (fileManagerComponent.currentFileID === fileId) {
        fileManagerComponent.refreshSettings();
      }
    });
  }

  preview(): void {
    const mapping = this.productDetailsBuilder.getMappingStructure();
    this.taskwizardService.getPreviewProducts(this._currentTaskId, mapping).subscribe((products) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '52vw';
      dialogConfig.data = products;
      const dialogRef = this.dialog.open(PreviewModalComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {});
    });
  }

  get hasMapping(): boolean {
    return !!this.twUpdateService.data.mapping;
  }
}

export interface TabData {
  label: string;
  inputs: TabFileManagerInputVo;
  fileId: number;
  chipColor: ChipColor;
}
