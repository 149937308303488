import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class GsStepRetailerAction implements GtmAction {
  event = GtmEvent.gs_step_retailer;

  constructor(public payload: GsStepRetailerActionPayload) {}
}

export interface GsStepRetailerActionPayload {
  step_id: string;
  skipped: 'skipped' | 'not_skipped';
  index: number;
}
