import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { categorySelector, flattenCategoriesSelector } from '../../store/category/category.selector';
import { Utils } from '../../utils/utils';
import {
  CategoryImageUrl,
  CategoryName,
  CategoryNameSynonym,
  CategoryVo,
  PostPutCategory,
  PostPutCategoryDescription,
  PostPutCategoryName,
  SaveCategoryImage,
} from '../../vo/category-vo';
import { SupplierDto } from '../../vo/supplier/supplier-dto';
import { I18nBackendJson } from '../../vo/translation/i18n-backend-json';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestService } from '../rest/rest.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private static microserviceName = 'productSearch';

  constructor(
    private springRestService: SpringRestService,
    private restService: RestService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}

  public getCategories(
    selectedCategories: number[],
    withChildren: boolean,
    inactiveIncluded: boolean = false
  ): Observable<CategoryVo[]> {
    const params = {
      ids: selectedCategories.join(','),
      withChildren: withChildren,
      inactiveIncluded: inactiveIncluded,
    };

    return this.springRestService.get(CategoryService.microserviceName, '/v2/Category', params);
  }

  observeStoredCategories(): Observable<CategoryVo> {
    return this.store.select(categorySelector);
  }

  observeFlattenedCategories(): Observable<CategoryVo[]> {
    return this.store.select(flattenCategoriesSelector);
  }

  public postCategory(data: PostPutCategory): Observable<CategoryVo> {
    return this.springRestService.post(CategoryService.microserviceName, '/v2/Category', data, {});
  }

  public postCategoryName(categoryId: number, data: PostPutCategoryName): Observable<CategoryName> {
    return this.springRestService.post(CategoryService.microserviceName, `/v2/Category/${categoryId}/Name`, data);
  }

  public postCategoryDescription(categoryId: number, data: PostPutCategoryDescription): Observable<void> {
    return this.springRestService.post(
      CategoryService.microserviceName,
      `/v2/Category/${categoryId}/Description`,
      data
    );
  }

  public putCategory(categoryId, data: Partial<PostPutCategory>): Observable<void> {
    return this.springRestService.put(CategoryService.microserviceName, `/v2/Category/${categoryId}`, data);
  }

  public putCategoryName(categoryNameId: number, data: PostPutCategoryName): Observable<CategoryName> {
    return this.springRestService.put(CategoryService.microserviceName, `/v2/Category/Name/${categoryNameId}`, data);
  }

  public putCategoryDescription(descriptionId: number, data: PostPutCategoryDescription): Observable<void> {
    return this.springRestService.put(
      CategoryService.microserviceName,
      `/v2/Category/Description/${descriptionId}`,
      data
    );
  }

  public saveImage(data: SaveCategoryImage): Observable<CategoryImageUrl> {
    const formData = Utils.objectToFormData(data, new FormData());
    return this.restService.post('CategoryService/saveImage', formData).pipe(
      map((value) => {
        return value.getFirstData();
      })
    );
  }

  saveCategoryImage(categoryId: number, image: File): Observable<void> {
    const formData = Utils.objectToFormData({ image }, new FormData());
    return this.springRestService.put(CategoryService.microserviceName, `/v2/Category/${categoryId}/Image`, formData);
  }

  getCategoryNameSynonyms(categoryNameIds: number[]): Observable<CategoryNameSynonym[]> {
    return this.springRestService.get(CategoryService.microserviceName, `/v2/Category/Name/Synonym`, {
      categoryNameIds: categoryNameIds.join(),
    });
  }

  postCategoryNameSynonym(categoryNameId: number, synonym: Pick<CategoryNameSynonym, 'synonym'>): Observable<void> {
    return this.springRestService.post(
      CategoryService.microserviceName,
      `/v2/Category/Name/${categoryNameId}/Synonym`,
      synonym
    );
  }

  putCategoryNameSynonym(synonymId: number, synonym: Pick<CategoryNameSynonym, 'synonym'>): Observable<void> {
    return this.springRestService.put(
      CategoryService.microserviceName,
      `/v2/Category/Name/Synonym/${synonymId}`,
      synonym
    );
  }

  deleteCategoryNameSynonym(synonymId: number): Observable<void> {
    return this.springRestService.delete(CategoryService.microserviceName, `/v2/Category/Name/Synonym/${synonymId}`);
  }

  public fetchCategoryIdsFromTree(tree: CategoryVo[]): number[] {
    const result = [];

    tree.forEach((item) => {
      result.push(item['id']);
      if (!Utils.isNullOrUndefined(item['children'])) {
        result.push(...this.fetchCategoryIdsFromTree(item['children']));
      }
    });

    return result;
  }

  public searchInCategories(categoryTree: CategoryVo, categoryId: number): CategoryVo {
    if (Number(categoryTree.id) === categoryId) {
      return categoryTree;
    } else if (categoryTree.children != null) {
      let result = null;
      for (let i = 0; result === null && i < categoryTree.children.length; i++) {
        result = this.searchInCategories(categoryTree.children[i], categoryId);
      }
      return result;
    }
    return null;
  }

  public flatCategories(categoryTree: CategoryVo): CategoryVo[] {
    const tree: CategoryVo[] = [];
    const mapCategories = (node: CategoryVo) => {
      tree.push(node);
      if (!Utils.isNullOrUndefined(node.children) && node.children.length !== 0) {
        node.children.forEach(mapCategories);
      }
    };
    mapCategories(categoryTree);
    return tree;
  }

  public searchInCategoriesByTerm(flattenCategories: CategoryVo[], term: string, lang = 'en'): CategoryVo[] {
    const termStyled = term.toLowerCase();
    return flattenCategories.filter((cat) => {
      return this.getNameForCategory(cat, lang).toLowerCase().indexOf(termStyled) > -1;
    });
  }

  getHandleForCategory(category: CategoryVo): string {
    return category?.handle;

    /* const name = this.getNameForCategory(category);
    return name
      .replace('&', 'and')
      .replace(/['\s]*,\s*|,\s*|['\s]+/g, '-')
      .toLowerCase(); */
  }

  getNameForCategory(category: CategoryVo, language?: string): string {
    const currentLang = language ?? this.translateService.currentLang;
    let name = category.categoryName.find((cat) => cat.languageCode === currentLang);
    if (name === undefined) {
      name = category.categoryName.find((cat) => cat.languageCode === 'en');
    }
    return name.name;
  }

  getCategoryNamesInJson(categories: CategoryVo[]): I18nBackendJson[] {
    const categoryNames = categories.map((category) => category.categoryName);
    const returnArray: I18nBackendJson[] = [];
    categoryNames.forEach((catNameArray) => {
      const nameObject = {};
      catNameArray.forEach((name) => {
        Object.assign(nameObject, { [name.languageCode]: name.name });
      });
      returnArray.push(nameObject as I18nBackendJson);
    });
    return returnArray;
  }

  translateCategoryNames(languageCode: string, categories: CategoryVo[]): string[] {
    const categoryNames = categories.map((category) => category.categoryName);
    return categoryNames.map((categoryName) => {
      const matchingTranslatedName = categoryName.find((name) => {
        return name.languageCode === languageCode;
      });
      if (!matchingTranslatedName) {
        return categoryName.find((name) => name.languageCode === 'en').name;
      }
      return matchingTranslatedName.name;
    });
  }

  public isFirstLevelCategory(categoryId: number, categories: CategoryVo[]): boolean {
    const queriedCategory = categories.find((cat) => cat.id === categoryId);
    return queriedCategory.parent === 1;
  }

  public getDescriptionForCategory(category: CategoryVo, language?: string): string {
    const currentLang = language ?? this.translateService.currentLang;
    let desc = category.description.find((cat) => cat.languageCode === currentLang);
    if (desc === undefined) {
      desc = category.description.find((cat) => cat.languageCode === 'en');
    }
    return desc.description;
  }
}

export interface TopSuppliersForCategory {
  [categoryId: number]: SupplierDto[];
}
