<div fxLayout="row" fxLayoutAlign="start center">
  <mat-icon [ngClass]="iconClasses" *ngFor="let _ of [].constructor(condensed ? 1 : wholeStarCount)">star</mat-icon>
  <mat-icon [ngClass]="iconClasses" *ngIf="isHalf && !condensed">star_half</mat-icon>
  <ng-container *ngIf="!condensed">
    <mat-icon
      [ngClass]="iconClasses"
      *ngFor="let _ of [].constructor(isHalf ? 5 - wholeStarCount - 1 : 5 - wholeStarCount)"
    >
      star_outline
    </mat-icon>
  </ng-container>
  <ng-container *ngIf="condensed">{{ this.avgRating | number : '.0-2' }}</ng-container>
  <span class="no-wrap pl-4" *ngIf="!!reviewCount">
    ({{ 'STOREFRONT.REVIEWS.REVIEWS' | translate : { number: reviewCount } }})
  </span>
  <ng-content></ng-content>
</div>
