<ng-container *conditionLoading="!isLoading">
  <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
    <app-step-title-description
      [title]="'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION.TITLE' | translate"
      [description]="'GETTING_STARTED.STEP_PAGE.GENERAL_INFORMATION.SUBTITLE' | translate"
    ></app-step-title-description>
    <div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
      <div class="w-100p" [style.max-width]="contentMaxWidth">
        <app-set-up-account-form
          [accountType]="[rolesEnum.RETAILER]"
          [value]="value"
          #setUpAccountFormComponent
        ></app-set-up-account-form>
      </div>
      <app-step-navigation-buttons
        (nextClick)="saveClicked()"
        (prevClick)="goToPreviousStep()"
        [hasPrevButton]="hasPreviousStep"
      ></app-step-navigation-buttons>
    </div>
  </div>
</ng-container>
