<div class="preference-container">
  <div *ngIf="!!title || !!subTitle">
    <div *ngIf="!!title" class="typography-body-large-2">{{ title }}</div>
    <div *ngIf="!!subTitle">
      {{ subTitle }}
    </div>
  </div>
  <app-multiple-country-selector
    [selectedCountries]="selectedCountries"
    (selectedCountriesChange)="selectedCountriesChange.emit($event)"
    [placeholder]="'RETAILER_SEARCH_SETTINGS.COUNTRY' | translate"
    [searchPlaceholder]="'RETAILER_SEARCH_SETTINGS.SEARCH_COUNTRY' | translate"
    [error]="error"
  ></app-multiple-country-selector>
</div>
