import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { GetUserReviewPipe } from 'app/main/marketplace/my-suppliers-new/pipe/get-user-review.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule, FlexModule, GetUserReviewPipe, TranslateModule],
})
export class ReviewComponent implements OnInit, OnChanges {
  @Input() condensed = false;
  @Input() reviewCount = 0;
  @Input() avgRating: number;
  @Input() iconClasses = ['s-24'];
  wholeStarCount: number;
  isHalf: boolean;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(_: SimpleChanges): void {
    this.setStars();
  }

  private setStars(): void {
    const flooredStarCount = Math.floor(this.avgRating);
    const fraction = this.avgRating - flooredStarCount;

    if (fraction < 0.25) {
      this.wholeStarCount = flooredStarCount;
      this.isHalf = false;
    } else if (fraction < 0.75) {
      this.wholeStarCount = flooredStarCount;
      this.isHalf = true;
    } else {
      this.wholeStarCount = flooredStarCount + 1;
      this.isHalf = false;
    }
  }
}
