import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { RequestError } from 'app/service/rest/request-error';
import { BasicLogIn } from 'app/store/authentication/authentication.actions';
import {
  isAuthenticatedSelector,
  isAuthenticationFailedSelector,
} from 'app/store/authentication/authentication.selector';
import { filter, takeWhile } from 'rxjs/operators';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleTypesEnum, ROLE_TYPE } from '../../../../vo/roles/roles';
import { GtmManagerService } from '../../../../service/google-tag-manager/gtm-manager.service';
import { LoginGtmAction, LoginGtmActionPayloadMethod } from '../../../../service/google-tag-manager/actions/login';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationDialogResponse } from '../../helpers/authentication-dialog-response';
import { ForgottenPasswordManagerService } from '../../forgotten-password-manager.service';
import { AuthDialogType } from '../../helpers/authentication-dialog-type';
@UntilDestroy()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class RetailerLoginFormComponent implements OnInit {
  @Input() role: RoleTypesEnum = RoleTypesEnum.RETAILER;

  RETAILER = RoleTypesEnum.RETAILER;
  SUPPLIER = RoleTypesEnum.SUPPLIER;

  public basicFormGroup: FormGroup;
  public submitEnabled = true;

  constructor(
    private _fb: FormBuilder,
    private store: Store<AppState>,
    private gtmManagerService: GtmManagerService,
    private forgottenPasswordManager: ForgottenPasswordManagerService,
    @Optional() private dialogRef: MatDialogRef<LoginDialogComponent, AuthenticationDialogResponse>
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.basicFormGroup = this._fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  submitBasicLogin(): void {
    this.basicFormGroup.markAllAsTouched();
    this.disableSubmit();
    if (!this.basicFormGroup.valid) {
      this.enableSubmit();
      return;
    }
    // this.loginError$.next(null);
    this.store.dispatch(new BasicLogIn({ ...this.basicFormGroup.value }));
    this.subscribeSuccessLogin();
    this.subscribeLoginError();
  }

  private subscribeSuccessLogin(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        untilDestroyed(this),
        takeWhile((authenticated) => authenticated === false, true)
      )
      .subscribe(
        (isAuthenticated) => {
          if (isAuthenticated) {
            this.dialogRef.close({ success: true });
            this.gtmManagerService.pushTag(new LoginGtmAction({ method: 'form' }));
            // this.routerService.navigate(['/']).then(() => {});
          }
        },
        (err: RequestError) => {
          this.handleLoginError(err);
        },
        () => {
          this.enableSubmit();
        }
      );
  }

  private disableSubmit(): void {
    this.submitEnabled = false;
  }

  private enableSubmit(): void {
    this.submitEnabled = true;
  }

  successSociaLoginHandle(loginType: LoginGtmActionPayloadMethod): void {
    this.dialogRef.close({ success: true });
    // this.routerService.navigate(['/']);
    this.gtmManagerService.pushTag(new LoginGtmAction({ method: loginType }));
  }

  private subscribeLoginError(): void {
    this.store
      .select(isAuthenticationFailedSelector)
      .pipe(filter((isFailed) => isFailed))
      .subscribe(() => {
        this.handleLoginError();
      });
  }

  handleLoginError(error?: RequestError): void {
    this.enableSubmit();
  }

  openForgottenPasswordDialog(): void {
    const type: AuthDialogType =
      this.role === RoleTypesEnum.RETAILER ? AuthDialogType.RETAILER : AuthDialogType.SUPPLIER;
    this.forgottenPasswordManager.openForgottenPasswordStep(type);
  }
}
