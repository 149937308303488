import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class TaskWatcherService {
  public static TASK_RUNNING_WATCHER_URL = 'TaskService/isTaskRunning';
  private tasksRunnedIds: number[] = [];
  private taskRunFinished = new Subject();
  private pid = null;
  private locked = false;

  private watcher: Subscription;

  constructor(private restService: RestService) {}

  private initETaskDownloadingWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'ExportTaskService/isETaskStoreDownloadingRunning';
  }

  private initTaskWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'TaskService/isTaskRunning';
  }

  private initExportTaskFileWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'ExportTaskService/isETaskFileRunning';
  }

  private initVUpdateTaskWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'TaskService/isVUTaskRunning';
  }

  private initRCatalogWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'RetailerCatalogService/isCatalogRunning';
  }

  private initEcomDownloadWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'TaskFileEcomDownloadService/isDownloadRunning';
  }

  private initOrderEcomWatcher(): void {
    TaskWatcherService.TASK_RUNNING_WATCHER_URL = 'CentralOrderManagementService/isOrderSyncRunning';
  }

  public addRunnedTaskToWatcher(taskId: number, isWaiting = true): void {
    if (this.tasksRunnedIds.indexOf(taskId) !== -1) {
      return;
    }
    if (isWaiting) {
      setTimeout(() => {
        this.tasksRunnedIds.push(taskId);
      }, 10000);
    } else {
      this.tasksRunnedIds.push(taskId);
    }
  }

  public getTaskRunFinishedObservable(): Observable<any> {
    return this.taskRunFinished.asObservable();
  }

  public clearTaskRunningWatcher(): void {
    this.tasksRunnedIds.splice(0);
  }

  public startEcomDownloaderWatcher(): void {
    this.initEcomDownloadWatcher();
    this.startWatcher();
  }

  public startUpdateVariantsTaskWatcher(): void {
    this.initVUpdateTaskWatcher();
    this.startWatcher();
  }

  public startETaskStoreDownloadWatcher(): void {
    this.initETaskDownloadingWatcher();
    this.startWatcher();
  }

  public startETaskFileWatcher(): void {
    this.initExportTaskFileWatcher();
    this.startWatcher();
  }

  public startRCatalogWatcher(): void {
    this.initRCatalogWatcher();
    this.startWatcher();
  }

  public startTaskWatcher(): void {
    this.initTaskWatcher();
    this.startWatcher();
  }

  public startOrderEcomWatcher(): void {
    this.initOrderEcomWatcher();
    this.startWatcher();
  }

  private startWatcher(): void {
    if (!Utils.isNullOrUndefined(this.watcher)) {
      return;
    }

    this.watcher = interval(5000).subscribe((t) => {
      if (Utils.isNullOrUndefined(this.tasksRunnedIds) || this.tasksRunnedIds.length < 1 || this.locked) {
        return;
      }
      this.locked = true;
      const params = { taskIds: this.tasksRunnedIds };
      this.restService.post(TaskWatcherService.TASK_RUNNING_WATCHER_URL, params).subscribe(
        (tasks) => {
          if (tasks.isNotEmpty()) {
            tasks.getData().forEach((task) => {
              const index = this.tasksRunnedIds.indexOf(task);
              if (index > -1) {
                this.tasksRunnedIds.splice(index, 1);
              }
            });
            this.taskRunFinished.next(tasks.getData());
          }
          this.locked = false;
        },
        (error1) => {
          this.locked = false;
        }
      );
    });
  }

  public getTasksRunnedIds(): number[] {
    return this.tasksRunnedIds;
  }

  public stopWatcher(): void {
    this.clearTaskRunningWatcher();
    if (Utils.isNullOrUndefined(this.watcher)) {
      return;
    }
    this.watcher.unsubscribe();
    this.watcher = null;
  }
}
