export enum SCOPES {
  REMOVE_RETAILER = 'role.remove.retailer', // has retailer account
  REMOVE_SUPPLIER = 'role.remove.supplier', // has supplier account
  CART_READ = 'CART.READ',
  ADMIN = 'admin',
  LOCKED_RCATALOG_EDIT = 'rcatalog.filter.locked_by_admin.edit',
  CATALOG_FILTER_LOCKED_EDIT = 'catalog.filter.locked_by_admin.edit',
  CATALOG_PRICING_LOCKED_EDIT = 'catalog.pricing.locked_by_admin.edit',
  AI_CHAT = 'ai.chat',
  TEAM_MEMBER = 'team.member',
  TEAM_MEMBER_READ = 'team.member.read',
  TEAM_MEMBER_WRITE = 'team.member.write',
  TEAM_LOGIN_MEMBER_WRITE = 'team.login.member.write',
  SEARCH_TYPE_EDIT = 'search_type.edit',
  PAYMENT_SANDBOX = 'sandbox.payment',
  HOMEPAGE_FULL_NEW_SUPPLIER_ARRIVALS = 'homepage.full_new_supplier_arrivals',
}
