import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { CountryNameToCodePipe } from '../../../../shared/pipes/legacy/country-name-to-code.pipe';
import { ProductSearchService } from '../../../../service/product-search/product-search.service';
import { ProductSearchState } from '../../../../store/product-search/product-search.reducer';
import { CountryNode } from '../../../../utils/Countries';
import {
  FilterSelectorOption,
  FilterSelectorOptionGroup,
} from '../../filter-selector-base/filter-selector-base.component';

@Component({
  selector: 'app-ships-from-filter',
  templateUrl: './ships-from-filter.component.html',
  styleUrls: ['./ships-from-filter.component.scss'],
})
export class ShipsFromFilterComponent implements OnInit, OnChanges {
  @Input() multiple = false;
  @Input() hasClearAll = false;
  @Input() value: string | string[];
  @Input() overlayOpen: boolean;
  @Output() valueChange = new EventEmitter<string | string[]>();
  @Output() shipsFromChange = new EventEmitter<CountryNode | CountryNode[]>();
  @Output() clearAll = new EventEmitter<void>();

  noOptionsAvailable = false;
  allCountries: FilterSelectorOption<string, string>[] = [];
  options: FilterSelectorOptionGroup<string, string>[] = [];
  productSearchStore$: Observable<ProductSearchState>;
  searchTerm = '';
  hasSeeMore = true;

  constructor(
    private productSearchService: ProductSearchService,
    private countryNameToCodePipe: CountryNameToCodePipe,

    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initAllCountriesAndOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.overlayOpen || changes.overlayOpen.currentValue || changes.overlayOpen.isFirstChange()) {
      return;
    }

    this.reset();
  }

  private reset(): void {
    this.handleSearchChange('');
    this.setInitialOptions();
  }

  handleSelectedChange(value: string | string[]): void {
    this.valueChange.emit(value);
    this.emitCountryNodes(value);
  }

  private emitCountryNodes(selectedValue: string | string[]): void {
    const mapValueToCountryNode = (): CountryNode | CountryNode[] => {
      if (Array.isArray(selectedValue)) {
        return selectedValue.map((countryName) => ({
          name: countryName,
          code: this.countryNameToCodePipe.transform(countryName),
        }));
      } else {
        return { name: selectedValue, code: this.countryNameToCodePipe.transform(selectedValue) };
      }
    };
    this.shipsFromChange.emit(mapValueToCountryNode());
  }

  handleSearchChange(value: string): void {
    this.searchTerm = value;
    this.filterOptions();
    this.setHasMore();
  }

  onSeeMore(): void {
    this.filterOptions();
  }

  onSeeLess(): void {
    this.setInitialOptions();
  }

  private mapCountryListToOptions(countries: string[]): FilterSelectorOption<string, string>[] {
    return countries.map((country) => {
      const code = this.countryNameToCodePipe.transform(country);
      return {
        key: country,
        value: this.translateService.instant(`COUNTRIES.${code}`),
        image: {
          src: transformToCdnUrl(`assets/images/country_flags_png/${code.toLowerCase()}.png`),
          width: '20px',
          height: '17px',
        },
      };
    });
  }

  private filterOptions(): void {
    if (!this.searchTerm) {
      this.options = [{ options: [...this.allCountries] }];
    } else {
      this.options = [
        {
          options: this.allCountries.filter(
            (option) => option.value.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
          ),
        },
      ];
    }
    this.setNoOptions();
  }

  private setHasMore(): void {
    if (!this.searchTerm) {
      this.hasSeeMore = this.allCountries.length > 4;
    } else {
      this.hasSeeMore = this.options[0].options.length > 4;
    }
  }

  private initAllCountriesAndOptions(): void {
    this.productSearchService.getShipsFromForFilters().subscribe((countries) => {
      this.allCountries = this.mapCountryListToOptions(countries.sort(this.sortCountries));
      this.setInitialOptions();
    });
  }

  private setNoOptions(): void {
    this.noOptionsAvailable = isEmpty(this.options[0].options);
  }

  private setInitialOptions(): void {
    this.filterOptions();
    this.setHasMore();
    this.options = [{ options: this.options[0].options.slice(0, 4) }];
  }

  private sortCountries(a: string, b: string): number {
    return a.localeCompare(b);
  }

  onClearAll(): void {
    this.clearAll.emit();
  }
}
