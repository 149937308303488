import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Utils } from 'app/utils/utils';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MfieldQuantitySettingsService } from '../../service/taskwizard/mfield-quantity-settings.service';
import { ProductField } from '../../service/taskwizard/taskwizard.service';
import { DEFAULT_WEIGHT_UNIT } from '../../utils/Constants';
import { FieldSettingsComponent, OperationsWithSettings } from '../field-settings/field-settings.component';
import {
  ProdSampleDialogComponent,
  ProdSampleDialogData,
  ProdSampleDialogResult,
} from '../taskwizard/addfiles/mapping/prod-sample-dialog/prod-sample-dialog.component';
import { ProdSampleInputVo } from '../taskwizard/addfiles/vo/prod-sample-input-vo';
import {
  ChipColor,
  ConstantChip,
  CurrencyWrapper,
  DimWrapper,
  FieldOperation,
  FieldSetting,
  LocationWrapper,
  PathPiece,
  VariableChip,
  WeightWrapper,
} from './chip';
import { DimSettingsService } from './dim-settings.service';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface FieldStructure extends OperationsWithSettings {
  chips: any[];
}

export interface FieldStructureWithId {
  [id: string]: FieldStructure[];
}

@Component({
  selector: 'app-droppable-input',
  templateUrl: './droppable-input.component.html',
  styleUrls: ['./droppable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DroppableInputComponent),
      multi: true,
    },
  ],
})
export class DroppableInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public static SETTINGS_PRICE = 0;
  public static SETTINGS_WEIGHT = 1;
  public static SETTINGS_DIM = 2;
  public static SETTINGS_LOCATIONS = 3;
  public static SETTINGS_FILTER = 4;

  public static IS_CHIP_SETTINGS = [
    DroppableInputComponent.SETTINGS_FILTER,
    DroppableInputComponent.SETTINGS_PRICE,
    DroppableInputComponent.SETTINGS_LOCATIONS,
  ];

  @ViewChildren('inputChip') inputChips: QueryList<ElementRef>;
  @ViewChildren('alterInputChip') alterInputChips: QueryList<ElementRef>;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() newRow: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeRow: EventEmitter<number> = new EventEmitter<number>();

  @Input() readonly id: string;
  @Input() readonly title: string;
  @Input() placeholder: string;
  @Input() readonly maxVariables: number = 100;
  @Input() readonly maxValues: number = 60;
  @Input() readonly isConstantsAllowed: boolean = true;
  @Input() readonly maxLength: number;
  @Input() readonly suffix: string;
  @Input() readonly numberOnly: boolean = false;
  @Input() readonly textAreaMode: boolean = false;
  @Input() readonly multiline: boolean = false;
  @Input() index: number;
  @Input() isAlternativeFields: boolean;
  @Input() toggledAlterFields = false;

  private prevTypedValue: string;
  private previousInputValue: string;

  @Input() required: boolean;
  @Input() taskId: any;
  public errors: any = { required: false };

  @ViewChild('container', { static: false }) container: ElementRef;

  public values: (ConstantChip | VariableChip)[] = [];
  public alterValues: (ConstantChip | VariableChip)[] = [];

  public remainingChars: number;

  public inputOperationsWithChips: OperationsWithSettings = { operations: [], settings: [] };

  private settingsType = -1;
  private defaultLocWrapper = new LocationWrapper(0);

  @Input() set isPriceSettings(value: boolean) {
    this.settingsType = -1;
    if (value) {
      this.settingsType = 0;
    }
  }

  @Input() set isWeightSettings(value: boolean) {
    this.settingsType = -1;
    if (value) {
      this.settingsType = 1;
    }
  }

  @Input() set isDimSettings(value: boolean) {
    this.settingsType = -1;
    if (value) {
      this.settingsType = 2;
      this.initDimSettings();
    }
  }

  @Input() set isLocationSettings(value: boolean) {
    this.settingsType = -1;
    if (value) {
      this.settingsType = 3;
    }
  }

  @Input() set isFilterSettings(value: boolean) {
    this.settingsType = -1;
    if (value) {
      this.settingsType = 4;
    }
  }

  @Input() set settings(value: any[]) {
    this.inputOperationsWithChips.settings = value;

    if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
      this.reloadLocationSettings();
    }

    this.checkSettingsExist();
  }

  @Input() set operations(value: any[]) {
    this.inputOperationsWithChips.operations = value;
    this.checkSettingsExist();
  }

  @Input() set chips(value: (ConstantChip | VariableChip)[]) {
    this.values = value;
  }
  @Output() chipsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() openChipsSettings = false;
  @Input() openFieldSettings = false;
  @Input() openSettings = false;

  @Output() settingsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() operationsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() prodSampleInputVo: ProdSampleInputVo;

  // OLD_TODO: Delete this after settings has been implemented and remove can be handled from there!
  public removable = true;
  visible = true;
  addOnBlur = true;

  focused = false;
  onFocusedOut = false;
  private lastSub: Subscription;

  public hasSettings = false;

  constructor(
    private dialog: MatDialog,
    private qtySettingsService: MfieldQuantitySettingsService,
    private dimService: DimSettingsService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.setTitleAsDefaultPlaceholder();
    this.initRemainingChars();
  }

  reloadLocationSettings(): void {
    this.inputOperationsWithChips.settings
      .filter((s) => s instanceof LocationWrapper)
      .forEach((loc: LocationWrapper) => {
        this.qtySettingsService.occupeLocation(this.id, loc.location);
      });
  }

  reloadChipLocationSettings(): void {
    (this.values || [])
      .filter((value) => {
        return value instanceof VariableChip;
      })
      .forEach((vChip: VariableChip) => {
        vChip.settings
          .filter((s) => s instanceof LocationWrapper)
          .forEach((loc: LocationWrapper) => {
            this.qtySettingsService.occupeLocation(this.id, loc.location);
          });
      });

    if (
      Utils.isNullOrUndefined(this.qtySettingsService.locations) &&
      !this.qtySettingsService.isGetLocationsInProgress
    ) {
      this.qtySettingsService.getLocationsByTaskId(this.taskId).subscribe();
    }
  }

  initDimSettings(): void {
    const dimWrapper = this.inputOperationsWithChips.settings.find((s) => s instanceof DimWrapper) as DimWrapper;
    if (!Utils.isNullOrUndefined(dimWrapper)) {
      this.dimService.setDimensionCode(dimWrapper.dimUnit);
    }
  }

  getId(): string {
    return this.id;
  }

  private checkSettingsExist(): void {
    this.hasSettings = !!(
      this.inputOperationsWithChips.settings.length > 0 || this.inputOperationsWithChips.operations.length
    );
  }

  private checkChipSettingsExist(values): void {
    if (Utils.isNullOrUndefined(values)) {
      return;
    }
    for (const itemValue of values) {
      if (itemValue instanceof VariableChip) {
        if (
          (!Utils.isNullOrUndefined(itemValue.operations) && itemValue.operations.length > 0) ||
          (!Utils.isNullOrUndefined(itemValue.settings) && itemValue.settings.length > 0)
        ) {
          itemValue.hasSettings = true;
        }
      }
    }
  }

  public getMultilineStructure(): any {
    return this.values.map((chip) => {
      if (chip instanceof ConstantChip) {
        return chip.value;
      } else {
        // We are removing the 'constant' and 'hasSettings' properties
        const { constant, hasSettings, ...chipWithoutConstantProperty } = chip;
        // We need split the path if path coming from product samples
        chipWithoutConstantProperty.path = chipWithoutConstantProperty.path.toString().split(' / ');
        chipWithoutConstantProperty.path.splice(0, 0, chip.fileId);
        delete chipWithoutConstantProperty.fileId;
        delete chipWithoutConstantProperty.color;
        return chipWithoutConstantProperty;
      }
    });
  }

  public getStructure(): FieldStructureWithId {
    const chips = this.mapReturnData(this.values);
    if (this.values.length > 0 && this.alterValues.length > 0 && this.values[0] instanceof VariableChip) {
      chips[0]['alt_fields'] = this.mapReturnData(this.alterValues);
    }
    this.setDefaultWeightSetting(this.inputOperationsWithChips.settings);
    return { [this.id]: [{ chips: chips, ...this.inputOperationsWithChips }] };
  }

  private mapReturnData(values): any {
    return values.map((chip) => {
      if (chip instanceof ConstantChip) {
        return chip.value;
      } else {
        // We are removing the 'constant' and 'hasSettings' properties
        const { constant, hasSettings, pathPieces, regeneratePath, ...chipWithoutConstantProperty } = chip;
        // We need split the path if path coming from product samples
        // this.setDefaultWeightSetting(chipWithoutConstantProperty.settings);
        chipWithoutConstantProperty.path = chipWithoutConstantProperty.path.toString().split(' / ');
        chipWithoutConstantProperty.path.splice(0, 0, chip.fileId);
        delete chipWithoutConstantProperty.fileId;
        delete chipWithoutConstantProperty.color;
        return chipWithoutConstantProperty;
      }
    });
  }

  private setDefaultWeightSetting(settings: FieldSetting[]): void {
    if (this.settingsType === DroppableInputComponent.SETTINGS_WEIGHT && settings.length < 1) {
      settings.push(new WeightWrapper(DEFAULT_WEIGHT_UNIT));
    }
  }

  public getPureValue(): any {
    return this.values.map((chip) => {
      if (chip instanceof ConstantChip) {
        return chip.value;
      } else {
        // We are removing the 'constant' and 'hasSettings' properties
        const { constant, hasSettings, ...chipWithoutConstantProperty } = chip;
        // We need split the path if path coming from product samples
        return chipWithoutConstantProperty.path;
      }
    });
  }

  get label(): string {
    if (this.focused) {
      return this.title;
    }
    return this.values?.length > 0 ? this.title : this.placeholder;
  }

  private setTitleAsDefaultPlaceholder(): void {
    if (!this.placeholder) {
      this.placeholder = this.title;
    }
  }

  public addUserTyped(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (value) {
      this.values.push(new ConstantChip(value));
      this.updateChanges();
      this.reCalcRemainingChars();

      // open input settings if field is qty
      if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
        this.addDefaultLocationToInputSettings();
        this.onInputSettingsClick();
      }
    }
    if (input) {
      input.value = '';
    }
  }

  private getLocWrappersFromSettings(settings: FieldSetting[]): LocationWrapper[] {
    return settings.filter((s) => s instanceof LocationWrapper) as LocationWrapper[];
  }

  private addDefaultLocationToInputSettings(): void {
    const locw = this.inputOperationsWithChips.settings.find((s) => s instanceof LocationWrapper);
    if (
      Utils.isNullOrUndefined(locw) &&
      this.qtySettingsService.isLocationFree(this.id, this.defaultLocWrapper.location)
    ) {
      this.qtySettingsService.occupeLocation(this.id, this.defaultLocWrapper.location);
      this.inputOperationsWithChips.settings.push(new LocationWrapper(this.defaultLocWrapper.location));
    }
  }

  private addDefaultLocationToChipSettings(chip: VariableChip): void {
    const locw = chip.settings.find((s) => s instanceof LocationWrapper);
    if (
      Utils.isNullOrUndefined(locw) &&
      this.qtySettingsService.isLocationFree(this.id, this.defaultLocWrapper.location)
    ) {
      this.qtySettingsService.occupeLocation(this.id, this.defaultLocWrapper.location);
      chip.settings.push(new LocationWrapper(this.defaultLocWrapper.location));
    }
  }

  public addDroppedItem(
    values,
    fileId: number,
    path: string,
    index = -1,
    openSettings: boolean = false,
    isAlterField = false,
    chipColor?: ChipColor
  ): void {
    this.dragover = false;
    const canMoreVariablesAdded = () => {
      const droppedChipsCount = values.filter((chip) => chip instanceof VariableChip).length;
      return this.maxVariables > droppedChipsCount && this.maxValues > values.length;
    };
    const isDroppedTypeAppropriate = () => {
      // OLD_TODO: implement
      return true;
    };

    if (canMoreVariablesAdded() && isDroppedTypeAppropriate()) {
      const chip = new VariableChip(path, fileId, chipColor);
      if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS && values.length === 0) {
        this.addDefaultLocationToChipSettings(chip);
      }
      if (index === -1) {
        values.push(chip);
      } else {
        values.splice(index, 0, chip);
      }
      if (openSettings) {
        this.onChipSettingsClick(chip, null, isAlterField);
      }

      this.updateChanges();
    }
    this.checkSettingsExist();
  }

  toggleAlterFields(): void {
    this.toggledAlterFields = !this.toggledAlterFields;
  }

  private removeLocationsFromSettings(settings: FieldSetting[]): void {
    settings.forEach((s) => {
      if (s instanceof LocationWrapper) {
        this.qtySettingsService.removeLocation(this.id, s.location);
      }
    });
  }

  public remove(values, chip: ConstantChip | VariableChip): void {
    this.focused = false;
    const index = values.indexOf(chip);
    if (index >= 0) {
      if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
        if (chip instanceof VariableChip) {
          this.removeLocationsFromSettings(chip.settings);
        }

        if (chip instanceof ConstantChip) {
          this.removeLocationsFromSettings(this.inputOperationsWithChips.settings);
          this.inputOperationsWithChips.settings = this.inputOperationsWithChips.settings.filter(
            (s) => !(s instanceof LocationWrapper)
          );
        }
      }

      this.checkSettingsExist();
      values.splice(index, 1);
      this.updateChanges();
      if (chip instanceof ConstantChip) {
        this.reCalcRemainingChars();
      }
    }
  }

  public removeChipsByFileID(fileId: number): void {
    const chipsObjectArray = this.values.filter((chip) => {
      if (chip instanceof VariableChip) {
        return chip.fileId === fileId;
      }
    });
    if (Utils.isNullOrUndefined(chipsObjectArray)) {
      return;
    }
    chipsObjectArray.forEach((chip) => {
      this.remove(this.values, chip);
    });
    chipsObjectArray.forEach((chip) => {
      this.remove(this.alterValues, chip);
    });
  }

  private reCalcRemainingChars(): void {
    if (!this.maxLength) {
      return;
    }
    const currentCharCount = this.values
      .filter((chip) => chip instanceof ConstantChip)
      .map((chip: ConstantChip) => chip.value.length)
      .reduce((previousVal, currentVal) => previousVal + currentVal, 0);
    this.remainingChars = this.maxLength - currentCharCount;
  }

  private initRemainingChars(): void {
    this.remainingChars = this.maxLength;
  }

  public onInputFocus(): void {
    this.focused = true;
  }

  public onInputFocusOut(event, index, values): void {
    this.addUserTypedChip(event.target, index + 1, values);
    this.focused = false;
    this.onFocusedOut = true;
  }

  public addUserTypedChip(input, index, values): void {
    const itemsNum = values.length;
    if (itemsNum >= this.maxValues) {
      this.resetInput(input);
      return;
    }
    const value = input.value;
    if (value) {
      if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
        this.addDefaultLocationToInputSettings();
        this.onInputSettingsClick();
      }
      values.splice(index, 0, new ConstantChip(value));

      // this.values.push(new ConstantChip(value));
      this.updateChanges();
      this.reCalcRemainingChars();
    }
    if (input) {
      this.resetInput(input);
    }
  }

  private resetInput(input): void {
    input.value = '';
    input.style.width = '5px';
  }

  public touched(touched: boolean): void {
    this.onFocusedOut = touched;
    this.validate();
  }

  public selectChip(): void {}

  public onChipSettingsClick(chip: VariableChip, event?: MouseEvent, fromAlterField = false): void {
    // We stop event propagation to avoid the click on the chip,
    // which invokes selectChip() and that would cause an infinite loop
    if (event) {
      event.stopPropagation();
    }
    this.openChipSettings(chip, fromAlterField);
  }

  openChipSettings(chip: VariableChip, fromAlterField = false): void {
    this.cancelLastDialogSubscription();

    this.lastSub = this.openSettingsWithData(
      chip,
      true,
      DroppableInputComponent.IS_CHIP_SETTINGS.includes(this.settingsType) ? this.settingsType : -1,
      fromAlterField
    )
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          console.log(value);
          chip.operations = value.operations;
          chip.settings = value.settings;
        }
        chip.hasSettings = this.anySettingsPresent(chip.operations, chip.settings);
      });
  }

  public onInputSettingsClick(): void {
    this.cancelLastDialogSubscription();
    let settingsTypeTemp = this.settingsType;
    if (DroppableInputComponent.IS_CHIP_SETTINGS.includes(this.settingsType) && this.isVariableChip()) {
      settingsTypeTemp = -1;
    }

    const isChipSettings = () => {
      return DroppableInputComponent.SETTINGS_LOCATIONS === this.settingsType && this.isVariableChip();
    };

    this.lastSub = this.openSettingsWithData(this.inputOperationsWithChips, isChipSettings(), settingsTypeTemp)
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.inputOperationsWithChips.operations = value.operations;
          this.inputOperationsWithChips.settings = value.settings;
          this.settingsChange.emit(this.inputOperationsWithChips.settings);
          this.operationsChange.emit(this.inputOperationsWithChips.operations);
        }
        this.hasSettings = this.anySettingsPresent(
          this.inputOperationsWithChips.operations,
          this.inputOperationsWithChips.settings
        );
      });
  }

  private anySettingsPresent(operations: FieldOperation[], settings: FieldSetting[]): boolean {
    return settings.length > 0 || operations.length > 0;
  }

  private openSettingsWithData(
    data: OperationsWithSettings,
    isChipSettings = false,
    settingsType = -1,
    isAlterField = false
  ): MatDialogRef<FieldSettingsComponent, OperationsWithSettings> {
    return this.dialog.open(
      FieldSettingsComponent,
      new SettingsDialogConfig(data, this.id, this.taskId, settingsType, isChipSettings, isAlterField)
    );
  }

  private cancelLastDialogSubscription(): void {
    if (this.lastSub) {
      this.lastSub.unsubscribe();
    }
  }

  drop(event: CdkDragDrop<ProductField[]>, values, isAlterField = false): void {
    if (!Utils.isNullOrUndefined(event.item.data)) {
      let openSettings = false;
      if (
        ['PRICE', 'ORIGINAL_PRICE', 'DISCOUNTED_PRICE', 'COST_PER_ITEM'].includes(this.id) ||
        this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS
      ) {
        openSettings = true;
      }
      const data = event.item.data;
      this.addDroppedItem(
        values,
        data.fileId,
        data.path,
        event.currentIndex,
        openSettings,
        isAlterField,
        data.chipColor
      );
    } else {
      moveItemInArray(this.values, event.previousIndex, event.currentIndex);
    }
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};
  dragover: boolean;

  updateChanges(): void {
    this.onChange(this.values);
    this.validate();
  }

  writeValue(values: (ConstantChip | VariableChip)[]): void {
    this.values = values;
    // && this.values[0] instanceof VariableChip && !Utils.isNullOrUndefined(this.values[0].altFields)
    if (!Utils.isNullOrUndefined(this.values) && this.values.length > 0) {
      const firstElement = this.values[0];
      if (firstElement instanceof VariableChip && !Utils.isNullOrUndefined(firstElement.altFields)) {
        this.alterValues = firstElement.altFields || [];
        this.checkChipSettingsExist(this.alterValues);
      }
    }
    this.updateChanges();
    if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
      this.reloadChipLocationSettings();
    }
    this.checkChipSettingsExist(this.values);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): void {
    this.errors.required = false;
    delete this.errors.message;
    if (this.required) {
      if (Utils.isNullOrUndefined(this.values) || this.values.length < 1) {
        this.errors.required = true;
        return;
      } else {
        this.errors.required = false;
      }

      if (this.settingsType === DroppableInputComponent.SETTINGS_PRICE) {
        this.priceValidate();
      }
    }

    if (this.settingsType === DroppableInputComponent.SETTINGS_LOCATIONS) {
      this.quantitySettingsValidate();
    }
  }

  quantitySettingsValidate(): boolean {
    if (!(this.values || []).length) {
      return true;
    }

    const vChipWithLocSettings = this.values.filter((chip) => {
      return chip instanceof VariableChip && this.getLocWrappersFromSettings(chip.settings).length > 0;
    });
    const resp =
      vChipWithLocSettings.length > 0 ||
      this.getLocWrappersFromSettings(this.inputOperationsWithChips.settings).length > 0;

    if (resp === false) {
      this.errors.required = true;
      this.errors['message'] = 'Location is not set to one of type of quantity field';
    }
    return resp;
  }

  isVariableChip(): boolean {
    const isVarChip = this.values.findIndex((value) => {
      return !value.constant;
    });
    return isVarChip > -1;
  }

  chipPriceValidate(): boolean {
    let isPriceSetted = true;
    let isVarChip = false;
    this.values.forEach((chip) => {
      if (!chip.constant) {
        isVarChip = true;
        const vChip = chip as VariableChip;
        if (Utils.isNullOrUndefined(vChip.settings) || vChip.settings.length === 0) {
          isPriceSetted = false;
        }
        vChip.settings.forEach((value) => {
          if (Utils.isNullOrUndefined(value as CurrencyWrapper)) {
            isPriceSetted = false;
          }
        });
      }
    });
    return isPriceSetted;
  }

  priceValidate(): boolean {
    const isVarChip = this.isVariableChip();
    let isPriceSetted = this.chipPriceValidate();
    if (!isVarChip) {
      isPriceSetted = false;
      this.inputOperationsWithChips.settings.forEach((wrapper) => {
        if (wrapper instanceof CurrencyWrapper) {
          isPriceSetted = true;
        }
      });
    }
    this.errors.required = !isPriceSetted;
    if (!isPriceSetted) {
      this.errors['message'] = 'Price currency is not set';
    }
    return isPriceSetted;
  }

  hasErrors(): boolean {
    // if (!this.required) {
    //     return false;
    // }
    for (const errorKey of Object.keys(this.errors)) {
      if (this.errors[errorKey] === true) {
        console.log(this.errors[errorKey]);
        return true;
      }
    }
    return false;
  }

  changeInputEvent($event: KeyboardEvent): void {
    console.log($event);
  }

  newRowEventHandler(event, index, values): void {
    if (event.target.value) {
      this.addUserTypedChip(event.target, index + 1, values);
      return;
    }
    if (!this.prevTypedValue) {
      this.newRow.emit(this.index);
    }
  }

  backspace($event): void {
    const value = $event.target.value;
    if (!value && !this.previousInputValue) {
      this.removeRow.emit(this.index);
    }
    this.previousInputValue = value;
  }

  mainChipListClicked(event: MouseEvent): void {
    if (!event.target['classList'].contains('index_selector')) {
      this.chipListClicked(this.inputChips);
    }
  }

  alterChipListClicked(): void {
    this.chipListClicked(this.alterInputChips);
  }

  chipListClicked(inputChips: QueryList<ElementRef>): void {
    if (Utils.isNullOrUndefined(inputChips.last.nativeElement)) {
      return;
    }

    let lastInput = null;
    let lastIndex = -1;
    inputChips.forEach((item) => {
      if (lastIndex < item.nativeElement.dataset.index) {
        lastIndex = item.nativeElement.dataset.index;
        lastInput = item.nativeElement;
      }
    });
    if (!Utils.isNullOrUndefined(lastInput)) {
      lastInput.focus();
    }
  }

  pathPieces(path: string): any[] {
    const pieces = path.split('#');
    let lastIndex = 0;
    const objArray = [];
    pieces.forEach((piece) => {
      lastIndex += piece.length;
      objArray.push({ index: lastIndex, piece: piece });
    });
    return objArray;
  }

  trackPath(index: number, element: VariableChip): string | any[] {
    return element.path;
  }

  pathArrayIndexChanged(event, c: VariableChip, piece: PathPiece): void {
    piece.index = event.target.value;
    c.regeneratePath();
    console.log(c);
  }

  ngOnDestroy(): void {
    this.removeLocationsFromSettings(this.inputOperationsWithChips.settings);
    this.values.forEach((chip) => {
      if (chip instanceof VariableChip) {
        this.removeLocationsFromSettings(chip.settings);
      }
    });
  }

  addClicked(): void {
    this.dialog
      .open<ProdSampleDialogComponent, ProdSampleDialogData, ProdSampleDialogResult>(ProdSampleDialogComponent, {
        viewContainerRef: this.viewContainerRef,
        autoFocus: false,
        data: {
          fileId: this.prodSampleInputVo.fileId,
          catalogId: this.prodSampleInputVo.taskId,
          extension: this.prodSampleInputVo.extension,
        },
        panelClass: 'custom-modal-full-width-lt-md',
      })
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe((result) => this.handleFieldDialogResult(result));
  }

  private handleFieldDialogResult(result: ProdSampleDialogResult): void {
    this.addDroppedItem(
      this.values,
      result.fileId,
      result.selectedFieldName,
      this.values.length,
      false,
      false,
      this.prodSampleInputVo.chipColor
    );
  }
}

export class SettingsDialogConfig extends MatDialogConfig<SettingsDialogData> {
  width = '1028px';
  maxWidth = '100%';
  autoFocus = false;

  constructor(
    data: OperationsWithSettings,
    fieldName = null,
    taskId = null,
    settingsType: number = -1,
    isChipSettings = false,
    isAlterField = false
  ) {
    super();
    this.data = <SettingsDialogData>{
      data: data,
      settingsType: settingsType,
      isChipSettings: isChipSettings,
      taskId: taskId,
      fieldName: fieldName,
      isAlterField: isAlterField,
    };
  }
}

export interface SettingsDialogData {
  data: OperationsWithSettings;
  settingsType: number;
  isPriceSettings: boolean;
  isWeightSettings: boolean;
  isChipSettings: boolean;
  taskId?: any;
  fieldName?: string;
  isAlterField?: boolean;
}
