<div class="shipping-details-zones" *ngIf="shippingInfos">
  <mat-expansion-panel *ngFor="let shippingInfo of shippingInfos; let first = first" [expanded]="first">
    <mat-expansion-panel-header>
      <mat-panel-title
        class="h5"
        [style.margin]="'0'"
        [class.content-disabled]="hasAppliedShippingZone ? !isAppliedShippingZone : false"
      >
        {{ shippingInfo.name }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-shipping-details-rates-table
      *ngIf="!isMobileSize"
      [rates]="shippingInfo.countryShippingInfos"
      [supplierCurrency]="supplierCurrency"
      [isAppliedShippingZone]="isAppliedShippingZone"
      [hasAppliedShippingZone]="hasAppliedShippingZone"
    ></app-shipping-details-rates-table>

    <app-shipping-details-rates-mobile
      *ngIf="isMobileSize"
      [rates]="shippingInfo.countryShippingInfos"
      [supplierCurrency]="supplierCurrency"
      [isAppliedShippingZone]="isAppliedShippingZone"
      [hasAppliedShippingZone]="hasAppliedShippingZone"
    ></app-shipping-details-rates-mobile>
  </mat-expansion-panel>
</div>
