import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { selectedCurrencySelector } from 'app/store/currency/currency.selector';
import { Observable, switchMap } from 'rxjs';
import { CurrencyTransformPipe } from './currency-transform-pipe.pipe';

@Pipe({
  name: 'currencyTransformToSelected',
  standalone: true,
})
export class CurrencyTransformToSelectedPipe implements PipeTransform {
  constructor(private _store: Store<AppState>, private _currenctTransform: CurrencyTransformPipe) {}

  transform(value: number, from?: string): Observable<string> {
    return this._store
      .select(selectedCurrencySelector)
      .pipe(switchMap((transformed) => this._currenctTransform.transform(value, transformed)));
  }
}
