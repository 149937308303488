import { Injectable } from '@angular/core';

import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { map, filter, zip } from 'rxjs';

import { SearchWidgetService } from 'app/service/search-widget/search-widget.service';

import { Store } from '@ngrx/store';
import { categorySelector } from 'app/store/category/category.selector';
import { typesIdsByNameSelector, userPreferencesSelector } from 'app/store/preferences/preferences.selector';

import { cloneDeep, shuffle } from 'lodash';

import { WidgetType } from 'app/vo/widget';

import type { AppState } from '../../../../app.state';
import type { CategoryVo } from 'app/vo/category-vo';
import type { Widget } from 'app/vo/widget';
import type { Observable } from 'rxjs';

@Injectable()
export class PreferedCategoryWidgetsService {
  public static MAX_WIGDETS = 5;

  public widgets$: Observable<Widget[]>;

  constructor(private store: Store<AppState>, private searchWidgetService: SearchWidgetService) {
    this.widgets$ = this.initWidgetsByPreferenceObservable();
  }

  private initWidgetsByPreferenceObservable(): Observable<Widget[]> {
    return zip([
      this.getDisplayedCategoryIDs().pipe(omitNullOrUndefined()),
      this.searchWidgetService.getWidgets(WidgetType.CATEGORY_CARD).pipe(filter(Boolean)),
    ]).pipe(
      map(([displayedCategoryIDs, allWidgets]) => {
        // keeping only those widgets which actually are prefered by the user
        const widgets: Widget[] = [];

        displayedCategoryIDs.forEach((categoryID: number): void => {
          const widget = allWidgets.find(
            (_widget) =>
              this.searchWidgetService.mapWidgetFilterDataToMarketplaceFilter(_widget.filterData).category ===
              categoryID
          );

          if (!widget) {
            return;
          }

          widgets.push(widget);
        });

        return widgets;
      })
    );
  }

  private getDisplayedCategoryIDs(): Observable<number[]> {
    return zip([
      this.getPreferedFirstLevelCategories().pipe(omitNullOrUndefined()),
      this.store.select(categorySelector).pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([firstLevelPreferedCategories, allProductsCategory]) => {
        let displayedCategories: CategoryVo[];

        switch (firstLevelPreferedCategories.length) {
          case 0:
            displayedCategories = this.handleZeroPreferedCategoriesCase(allProductsCategory);
            break;
          case 1:
            displayedCategories = this.handleOnePreferedCategoryCase(firstLevelPreferedCategories[0]);
            break;
          default:
            displayedCategories = this.handleMultiplePreferedCategoriesCase(firstLevelPreferedCategories);
            break;
        }

        return displayedCategories.map((preferedCategory): number => preferedCategory.id);
      })
    );
  }

  private handleZeroPreferedCategoriesCase(allProductsCategory: CategoryVo): CategoryVo[] {
    return allProductsCategory.children.slice(0, PreferedCategoryWidgetsService.MAX_WIGDETS);
  }

  private handleOnePreferedCategoryCase(preferedCategory: CategoryVo): CategoryVo[] {
    return [preferedCategory, ...preferedCategory.children.slice(0, PreferedCategoryWidgetsService.MAX_WIGDETS - 1)];
  }

  private handleMultiplePreferedCategoriesCase(firstLevelPreferedCategories: CategoryVo[]): CategoryVo[] {
    // if the user has more than the max number of displayable categories, we return with the first 5 categories
    if (firstLevelPreferedCategories.length > PreferedCategoryWidgetsService.MAX_WIGDETS) {
      return firstLevelPreferedCategories.slice(0, PreferedCategoryWidgetsService.MAX_WIGDETS);
    }

    const preferedCategories: CategoryVo[] = [...firstLevelPreferedCategories];

    const numberOfRandomCategories = PreferedCategoryWidgetsService.MAX_WIGDETS - firstLevelPreferedCategories.length;

    /* we are storing those second level categories which haven't been added
    to the preferedCategories list in order ot prevent duplicates */
    const secondLevelCategories: { [key: number]: CategoryVo[] } = firstLevelPreferedCategories.reduce(
      (dict, category, i) => {
        dict[i] = shuffle(cloneDeep(category.children));
        return dict;
      },
      {}
    );

    let index = 0;

    let count = 0;

    while (count !== numberOfRandomCategories) {
      if (secondLevelCategories[index].length === 0) {
        continue;
      }

      const secondLevelCategory: CategoryVo = secondLevelCategories[index].pop();

      preferedCategories.push(secondLevelCategory);

      index = index === firstLevelPreferedCategories.length - 1 ? 0 : index + 1;

      count += 1;
    }

    return preferedCategories;
  }

  private getPreferedFirstLevelCategories(): Observable<CategoryVo[]> {
    return zip([
      this.getPreferedCategoryIDs().pipe(omitNullOrUndefined()),
      this.store.select(categorySelector).pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([preferedCategoryIDs, allProductsCategory]) => {
        return allProductsCategory.children.filter((category: CategoryVo): boolean => {
          return preferedCategoryIDs.includes(category.id);
        });
      })
    );
  }

  private getPreferedCategoryIDs(): Observable<number[]> {
    return zip([
      this.store.select(userPreferencesSelector).pipe(omitNullOrUndefined()),
      this.getSynceeCategoryPreferenceTypeID().pipe(omitNullOrUndefined()),
    ]).pipe(
      map(([userPreferences, categoryPreferenceTypeID]) => {
        return userPreferences
          .filter((userPreference) => userPreference.preferenceTypeId === categoryPreferenceTypeID)
          .map((userPreference): number => +userPreference.preferenceValue);
      })
    );
  }

  private getSynceeCategoryPreferenceTypeID(): Observable<number> {
    return this.store.select(typesIdsByNameSelector).pipe(
      omitNullOrUndefined(),
      map((typesIDsByName): number => {
        return typesIDsByName?.SYNCEE_CATEGORY;
      })
    );
  }
}
