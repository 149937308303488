import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SupplierOrderLimit } from '../../../service/manage-orders/syncee-orders.service';
import { CurrencyExchangeToSelectedPipe } from '../../pipes/currency-exchange-to-selected.pipe';
import { CurrencyTransformToSelectedPipe } from '../../pipes/currency-transform-to-selected.pipe';
import { DataListingDialogService } from '../dialogs/data-listing-dialog/data-listing-dialog.service';
import { SupplierVo } from '../../../vo/search-product-vo';
import { take } from 'rxjs/operators';
import { SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { CurrencyExchangeToPipe } from '../../pipes/currency-exchange-to.pipe';
import { CurrencyTransformPipe } from '../../pipes/currency-transform-pipe.pipe';
import { MinimumOrderAmountDisplayService } from '../../../service/minimum-order-amount-display/minimum-order-amount-display.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-minimum-order-display-with-dialog',
  templateUrl: './minimum-order-display-with-dialog.component.html',
  styleUrls: ['./minimum-order-display-with-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, CurrencyExchangeToSelectedPipe, CurrencyTransformToSelectedPipe],
  providers: [
    CurrencyExchangeToSelectedPipe,
    CurrencyTransformToSelectedPipe,
    CurrencyExchangeToPipe,
    CurrencyTransformPipe,
    MinimumOrderAmountDisplayService,
  ],
})
export class MinimumOrderDisplayWithDialogComponent implements OnInit {
  @Input() supplier: SupplierVo;
  @Input() supplierUserId: number;
  amount: number;
  supplierOrderLimit: SupplierOrderLimit[];
  minimumOrderDisplay: string;

  constructor(
    private dataListingDialogService: DataListingDialogService,
    private translateService: TranslateService,
    private supplierGatewayService: SupplierGatewayService,
    private minimumOrderAmountDisplayService: MinimumOrderAmountDisplayService
  ) {}

  ngOnInit(): void {
    this.getSupplierOrderLimit();
  }

  private getSupplierOrderLimit(): void {
    this.supplierGatewayService
      .getSupplierOrderLimit([this.supplierUserId])
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.supplierOrderLimit = res;
          this.getMinAmount();
        },
      });
  }

  private getMinAmount(): void {
    if (!this.supplierOrderLimit || this.supplierOrderLimit.length == 0) {
      this.amount = 0;
    } else {
      if (this.supplierOrderLimit.length == 1) {
        this.amount = this.supplierOrderLimit[0].amount;
      } else {
        this.amount = this.supplierOrderLimit.find((elem) => elem.orderCount === -1).amount;
      }
    }
    this.getMinimumOrderAmountDisplay();
  }

  getMinimumOrderAmountDisplay(): void {
    this.minimumOrderAmountDisplayService
      .getMinimumOrderAmountDisplay(this.supplierOrderLimit, this.supplier.paymentCurrency)
      .pipe(untilDestroyed(this))
      .subscribe((content) => {
        this.minimumOrderDisplay = content;
      });
  }

  openOrderAndCatalogInformation(): void {
    this.dataListingDialogService
      .open(
        this.getTranslation('PRODUCT_PAGE.WHOLESALE_MINIMUM_ORDER.DIALOG_CONTENT.TITLE'),
        [
          {
            key: 'STOREFRONT.DIALOG.ORDER_AND_CATALOG.SUPPLIER_ORDER_LIMIT.LABEL',
            value: this.minimumOrderDisplay,
          },
        ],
        this.getTranslation('PRODUCT_PAGE.WHOLESALE_MINIMUM_ORDER.DIALOG_CONTENT.BACK_TO'),
        { translateValue: false, translateBackTo: false }
      )
      .subscribe();
  }

  private getTranslation(key: string, interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams);
  }
}
