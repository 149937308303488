import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { IsProductUsedResponse } from 'app/service/marketplace/explore-products/is-product-used';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { selectedCurrencySelector } from 'app/store/currency/currency.selector';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { RolesEnum } from 'app/vo/roles/roles';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { Observable, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ExploreProductsService } from '../../../service/marketplace/explore-products/explore-products.service';
import { ProductSearchService } from '../../../service/product-search/product-search.service';
import { SearchSessionService } from '../../../service/search-session/search-session.service';
import { omitNullOrUndefined } from '../../../utils/operator/omit-null-or-undefined';
import { ProductsListSliderBoxComponent } from '../products-list-slider-box/products-list-slider-box.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-recently-viewed-products',
  templateUrl: './recently-viewed-products.component.html',
  styleUrls: ['./recently-viewed-products.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    TranslateModule,
    ProductsListSliderBoxComponent,
    MatProgressSpinnerModule,
    SkeletonModule,
  ],
})
export class RecentlyViewedProductsComponent implements OnInit {
  @Input() wrapperClass: string;
  @Input() generateMicrodata = false;

  productHistory: SearchProductVO[];
  isProductUsed: IsProductUsedResponse;
  ecomCurrency: Observable<string> = of('USD');
  noSearchHistory: boolean;
  isLoading: boolean;

  constructor(
    private _exploreProducts: ExploreProductsService,
    private store: Store<AppState>,
    private productSearchService: ProductSearchService,
    private searchSessionService: SearchSessionService,
    private navigationEventService: NavigationEventService
  ) {
    this.ecomCurrency = this.store.select(selectedCurrencySelector);
  }

  ngOnInit(): void {
    this.fetchRecentlyViewedProducts();
  }

  fetchRecentlyViewedProducts(): void {
    this.searchSessionService.productClicks$
      .pipe(
        tap(() => (this.isLoading = true)),
        omitNullOrUndefined(),
        tap((ids) => {
          this.noSearchHistory = ids.length === 0;
          this.isLoading = ids.length > 0;
        }),
        filter((ids) => !!ids.length),
        switchMap((ids) => this.productSearchService.getProductBySynceeIds(ids)),
        tap((products) => {
          this.productHistory = products;
          this.noSearchHistory = products.length === 0;
        }),
        switchMap((products) => (!!products?.length ? this.getUsedProducts(products) : of([]))),
        tap((inCatalog) => (this.isProductUsed = inCatalog)),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe({ next: () => (this.isLoading = false), error: () => (this.isLoading = false) });
  }

  private getUsedProducts(productList?: SearchProductVO[]): Observable<IsProductUsedResponse> {
    return this.store.select(getSelectedEcomByRole(RolesEnum.RETAILER)).pipe(
      takeUntil(this.navigationEventService.onNavigationStart),
      switchMap((ecom) =>
        !!ecom && !!productList?.length
          ? this._exploreProducts.getIsProductUsed(
              ecom.id,
              productList?.map((product) => product.ID)
            )
          : of([])
      )
    );
  }
}
