<div class="outer-container" [style.height]="isLoggedIn ? (ltMd ? '345px' : '405px') : ltMd ? '290px' : '350px'">
  <div
    class="product-card-container inner-container"
    [ngStyle]="{ 'user-select': isAdmin ? 'auto' : 'none' }"
    (mouseenter)="handleMouseEnter()"
    (mouseleave)="handleMouseLeave()"
    [class]="'status-' + status"
    [class.hovered]="debouncedHovered"
    [style.border-color]="config[status].borderColor"
  >
    <!-- SEO tags -->
    <div *ngIf="generateMicrodata" class="item-display-none" itemscope itemtype="https://schema.org/Product">
      <meta itemprop="name" [content]="product.TITLE" />
      <meta itemprop="description" [content]="metaTagDescription" />
      <meta
        itemprop="category"
        [content]="
          product.SYNCEE_CATEGORY && product.SYNCEE_CATEGORY.length > 0
            ? (product.SYNCEE_CATEGORY[0] | categoryNameByLanguage | async)
            : 'All products'
        "
      />
      <meta itemprop="image" [content]="(product | productImageList)[0]" />
      <div class="item-display-none" itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
        <meta itemprop="name" [content]="product.BRAND" />
        <meta itemprop="url" [content]="product.USER_ID | createSupplierFullUrl : product.SUPPLIER?.handle" />
        <meta itemprop="logo" [content]="product.USER_ID | supplierLogoFromUserid" />
      </div>
      <div class="item-display-none" itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
        <meta itemprop="sku" [content]="product.ID" />
        <meta itemprop="price" [content]="metaTagPrice" />
        <meta itemprop="priceCurrency" content="USD" />
        <div class="item-display-none" itemprop="seller" itemscope itemtype="https://schema.org/Organization">
          <meta itemprop="name" [content]="product.BRAND" />
        </div>
      </div>
    </div>
    <!-- /SEO tags -->
    <div class="image-container" #imagesContainer>
      <app-favourite-heart *onlyLoggedIn [productId]="product.ID" class="favourite"></app-favourite-heart>
      <app-image-selector
        [images]="product | productImageList"
        [productId]="product.ID"
        [productName]="product.TITLE"
        [supplierUserId]="product.USER_ID"
      ></app-image-selector>
    </div>
    <div #detailsContainer>
      <app-product-details
        [product]="product"
        [ecomCurrency]="ecomCurrency"
        [catalogStatus]="catalogStatus"
      ></app-product-details>
    </div>
    <div class="action" @expandCollapse *ngIf="hovered">
      <app-product-card-action
        [status]="status"
        [selected]="selected"
        (selectedChange)="selectedChange.emit($event)"
        [product]="product"
      ></app-product-card-action>
    </div>
    <div
      class="icon-container"
      *ngIf="config[status].icon"
      [style.background-color]="config[status].iconStyle.backgroundColor"
      [style.color]="config[status].iconStyle.color"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ config[status].icon }}</mat-icon>
    </div>
  </div>
</div>
