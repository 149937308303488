import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

import type { WebVitalMetric } from '../web-vital-metric';

export class WebVitalsAction implements GtmAction {
  event = GtmEvent.web_vitals;

  constructor(public payload: WebVitalsGtmActionPayload) {}
}

export interface WebVitalsGtmActionPayload {
  event_category: string;
  event_action: WebVitalMetric;
  event_value: number;
  event_label: string;
}
