import { Component, Input } from '@angular/core';

import { PreferedCategoryWidgetsService } from './services/prefered-category-widgets.service';

import type { Observable } from 'rxjs';
import type { Widget } from 'app/vo/widget';

@Component({
  selector: 'app-categories-by-preference',
  templateUrl: './categories-by-preference.component.html',
  styleUrls: ['./categories-by-preference.component.scss'],
  providers: [PreferedCategoryWidgetsService],
})
export class CategoriesByPreferenceComponent {
  public widgets$: Observable<Widget[]> = this.prefreredCategoryWidgetsService.widgets$;

  public maxNumberOfWidgets: number = PreferedCategoryWidgetsService.MAX_WIGDETS;

  @Input() title: string;

  @Input() titlePosition: 'start' | 'center';

  @Input() description: string;

  @Input() wrapperClass: string;

  @Input() innerWrapperClass: string;

  @Input() background: string;

  constructor(private prefreredCategoryWidgetsService: PreferedCategoryWidgetsService) {}
}
