import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { flattenCategoriesSelector } from '../../../store/category/category.selector';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { CategoryVo } from '../../../vo/category-vo';
import { CategoryService } from '../../../service/product-search/category.service';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { Preference } from '../../../vo/Preferences/preference';
import { PreferenceStoreService } from '../../../service/preference/preference.store.service';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { PreferenceNameEnum } from '../../../vo/Preferences/preference-name.enum';

@Injectable()
export class PreferencesModuleService {
  constructor(
    private store: Store<AppState>,
    private categoryService: CategoryService,
    private countriesManagerService: CountriesManagerService,
    private preferenceStoreService: PreferenceStoreService
  ) {}

  createPreference(
    selectedCategories: number[],
    selectedShipsFromCountries: string[],
    selectedShipsToCountries: string[]
  ): Observable<Preference[]> {
    return combineLatest([
      this.store.select(flattenCategoriesSelector),
      this.preferenceStoreService.getTypeIdsByNames(),
      this.store.select(getCurrentUserIdSelector),
    ]).pipe(
      filter(([categories]) => !!categories),
      take(1),
      map(([categories, typeIds, userId]) => [
        ...this.mapValuesToPreferences(selectedCategories, typeIds[PreferenceNameEnum.SYNCEE_CATEGORY], userId),
        ...this.mapValuesToPreferences(
          this.getSelectedChildCategoryIds(categories, selectedCategories),
          typeIds.SYNCEE_CATEGORY,
          userId
        ),
        ...this.mapValuesToPreferences(
          selectedShipsFromCountries,
          typeIds[PreferenceNameEnum.SHIPS_FROM_LOCATION],
          userId
        ),
        ...this.mapValuesToPreferences(selectedShipsToCountries, typeIds[PreferenceNameEnum.SHIPS_TO_LOCATION], userId),
      ])
    );
  }

  private mapValuesToPreferences(values: (string | number)[], typeId: number, userId: number): Preference[] {
    return values.map((value) => ({
      preferenceTypeId: typeId,
      preferenceValue: String(value),
      userId: userId,
    }));
  }

  public getSelectedChildCategoryIds(categories: CategoryVo[], selectedCategories: number[]): number[] {
    return categories.filter((category) => selectedCategories.includes(category.parent)).map((category) => category.id);
  }
}
