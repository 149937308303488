import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { flatten, shuffle, uniq } from 'lodash';
import { forkJoin, Subject, switchMap, tap } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SupplierCard } from '../../../main/marketplace/supplier-card/supplier-card.component';
import { CatalogGatewayService, CatalogMainCategories } from '../../../service/catalog/catalog-gateway.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { SupplierSearchGatewayService } from '../../../service/supplier-search/supplier-search-gateway.service';
import { SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { SeoUrlsBySupplierDto, SupplierTaskService } from '../../../service/suppliers/supplier-task.service';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import { SupplierWithBadgesDto } from '../../../vo/supplier/supplier-with-badges-dto';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { SuppliersSliderComponent } from '../suppliers-slider/suppliers-slider.component';

@Component({
  selector: 'app-suppliers-slider-by-filter',
  templateUrl: './suppliers-slider-by-filter.component.html',
  styleUrls: ['./suppliers-slider-by-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, SuppliersSliderComponent, TranslateModule, SkeletonModule],
})
export class SuppliersSliderByFilterComponent implements OnInit, OnDestroy {
  @Input() wrapperClass: string | string[];
  @Input() innerClass: string | string[];
  @Input() selectedEcom: EcomVO;
  @Input() filter: MarketplaceFilter;
  @Input() title: string;
  @Input() shuffle = false;

  suppliers: SupplierCard[];
  supplierImages: Record<number, string[]>;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private catalogGatewayService: CatalogGatewayService,
    private supplierTaskService: SupplierTaskService,
    private supplierSearchGatewayService: SupplierSearchGatewayService,
    private supplierGatewayService: SupplierGatewayService,
    private navigationEventService: NavigationEventService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSuppliers(this.filter);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getSuppliers(marketplaceFilter: MarketplaceFilter): void {
    this.supplierSearchGatewayService
      .getSupplierIdsBySearch(
        this.selectedEcom,
        { ...marketplaceFilter, autoOrder: 'true' },
        {
          from: 0,
          size: 18,
        }
      )
      .pipe(
        map((supplierIdsPage) => supplierIdsPage.content),
        tap((supplierIds) => this.getSupplierImages(supplierIds)),
        switchMap((supplierIds) =>
          this.supplierGatewayService
            .getSuppliers(supplierIds)
            .pipe(
              map((suppliers) =>
                suppliers.sort((a, b) => supplierIds.indexOf(a.userId) - supplierIds.indexOf(b.userId))
              )
            )
        ),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe((res) => {
        this.mapSuppliers(res);
      });
  }

  private getSupplierImages(supplierIds: number[]): void {
    this.supplierGatewayService
      .getRandomSupplierProductImages(supplierIds)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe((imageUrls) => {
        this.supplierImages = this.supplierGatewayService.mapRandomSupplierVosToRecord(imageUrls);
      });
  }

  private mapSuppliers(similarSuppliers: SupplierWithBadgesDto[]): void {
    const userIds = similarSuppliers.map((supplier) => supplier.userId);
    forkJoin([
      this.catalogGatewayService
        .getCatalogCategories(userIds)
        .pipe(takeUntil(this.navigationEventService.onNavigationStart)),
      this.supplierTaskService
        .getSeoUrlsByUserIds(userIds)
        .pipe(takeUntil(this.navigationEventService.onNavigationStart)),
    ])
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe(([mainCategories, seoUrls]) => {
        this.suppliers = this.shuffle
          ? shuffle(this.mapToSupplierCardModel(similarSuppliers, mainCategories, seoUrls))
          : this.mapToSupplierCardModel(similarSuppliers, mainCategories, seoUrls);
      });
  }

  private mapToSupplierCardModel(
    similarSuppliers: SupplierWithBadgesDto[],
    mainCategories: CatalogMainCategories[],
    seoUrls: SeoUrlsBySupplierDto[]
  ): SupplierCard[] {
    return similarSuppliers.map((supplier) => {
      return {
        userId: supplier.userId,
        mainWarehouseCountry: supplier.mainWarehouseLocation,
        mainCategoryIds: this.getUniqeMainCategoryIdsBySupplier(mainCategories, supplier),
        companyName: supplier.companyName,
        seoUrls: seoUrls.length ? this.getSeoUrlsBySupplier(seoUrls, supplier) : [],
        handle: supplier.handle,
      };
    });
  }

  private getSeoUrlsBySupplier(seoUrls: SeoUrlsBySupplierDto[], supplier: SupplierWithBadgesDto): string[] {
    return seoUrls.find((seoUrlsBySupplier) => seoUrlsBySupplier.userId === +supplier.userId)?.seoUrls;
  }

  private getUniqeMainCategoryIdsBySupplier(
    mainCategories: CatalogMainCategories[],
    supplier: SupplierWithBadgesDto
  ): number[] {
    return uniq(
      flatten(
        mainCategories
          .filter((mainCategory) => mainCategory.userId === +supplier.userId)
          .map((mainCategory) => mainCategory.categoryIds)
      )
    );
  }
}
