import { BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { PaginationSizeService } from '../../../../service/pagination-size/pagination-size.service';
import { SupplierSearchGatewayService } from '../../../../service/supplier-search/supplier-search-gateway.service';
import { ProductCardWidths } from '../../../../shared/components/product-card-list/product-card-list.component';
import { IncreasePaginator, SetPaginator } from '../../../../store/product-search/product-search.action';
import {
  FullMarketplaceFilterVO,
  MarketplaceFilter,
  MarketplaceFilterPagination,
} from '../../../../vo/search-product-vo';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss'],
})
export class SupplierListComponent implements OnInit, OnDestroy {
  @Input()
  selectedEcom: EcomVO;

  @Input()
  supplierCardWidth: ProductCardWidths = {
    xs: '50%',
    sm: '50%',
    md: '33.333%',
    lg: '25%',
    xl: '20%',
  };
  @Output() allItemsCountChange = new EventEmitter<number>();

  supplierIds: number[];
  supplierCount: number;
  isLoading = true;
  isError = false;
  numberOfFetchedIds = 0;
  selectedCategoryId: number;
  private maxSupplierToShow = 40;
  private hasAd = false;

  private productSearchFilterStore$: Observable<Partial<FullMarketplaceFilterVO>>;
  private paginatorStore$: Observable<MarketplaceFilterPagination>;
  private filter: MarketplaceFilter;
  private paginator: MarketplaceFilterPagination;
  private unsubscribeAll = new Subject<void>();

  constructor(
    private supplierSearchGatewayService: SupplierSearchGatewayService,
    private store: Store<AppState>,
    private screenManagerService: ScreenManagerService,
    private paginationSizeService: PaginationSizeService,
    private navigationEventService: NavigationEventService
  ) {
    this.setFilterObservable();
    this.setPaginatorObservable();
  }

  ngOnInit(): void {
    this.dispatchInitialPaginator();
    this.createFilterAndPaginatorSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleLoadMore(): void {
    this.store.dispatch(new IncreasePaginator());
  }

  private screenChange$(): Observable<BreakpointState> {
    return this.screenManagerService.observeBreakpoints([
      BreakPoint.xs,
      BreakPoint.sm,
      BreakPoint.md,
      BreakPoint.lg,
      BreakPoint.xl,
    ]);
  }

  private dispatchInitialPaginator(): void {
    this.screenChange$()
      .pipe(take(1))
      .subscribe((res) => {
        const size = this.paginationSizeService.getSizeToPaginate(res, this.maxSupplierToShow, this.supplierCardWidth);
        this.store.dispatch(new SetPaginator({ from: 0, size: this.hasAd ? size - 1 : size }));
      });
  }

  private setPaginatorObservable(): void {
    this.paginatorStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.paginator)
    );
  }

  private setFilterObservable(): void {
    this.productSearchFilterStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
  }

  private searchSuppliers(): void {
    this.isLoading = true;
    this.isError = false;
    this.supplierSearchGatewayService
      .getSupplierIdsBySearch(this.selectedEcom, this.filter, this.paginator)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe({
        next: (supplierUserIdsPage) => {
          this.allItemsCountChange.emit(supplierUserIdsPage.totalElements);
          this.supplierCount = supplierUserIdsPage.totalElements;
          this.supplierIds = supplierUserIdsPage.content;
          this.numberOfFetchedIds += supplierUserIdsPage.content.length;
          this.isLoading = false;
        },
        error: () => {
          this.setErrorState();
        },
      });
  }

  private setErrorState(): void {
    this.isError = true;
    this.isLoading = false;
  }

  private createFilterAndPaginatorSubscription(): void {
    combineLatest([this.productSearchFilterStore$, this.paginatorStore$])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(([filter, paginator]) => {
        this.filter = filter;
        this.selectedCategoryId = filter.category === 1 ? null : filter.category;
        this.paginator = paginator;
        if (paginator.from === 0) {
          this.numberOfFetchedIds = 0;
        }
        this.searchSuppliers();
      });
  }

  get pagination(): MarketplaceFilterPagination {
    return this.paginator;
  }
}
