import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CatalogService } from 'app/service/catalog/catalog.service';
import { CategoryService } from 'app/service/product-search/category.service';
import { getTaskById } from 'app/store/tasks/tasks.selector';
import { MappedCatalogSettings } from 'app/vo/catalog-form-vo';
import { CatalogSettingsVO } from 'app/vo/catalog-settings-vo';
import { SupplierCatalog } from 'app/vo/supplier-catalog/supplier-catalog';
import { SupplierCatalogDetails } from 'app/vo/supplier-catalog/supplier-catalog-details';
import { flatten, isEmpty, uniq, uniqBy } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { StepBase } from '../../../main/taskwizard/step-base';
import { CategoryMappingService } from '../../../service/category-mapping/category-mapping.service';
import { flattenCategoriesSelector } from '../../../store/category/category.selector';
import { CategoryVo } from '../../../vo/category-vo';
import { CategoryMappingProductExampleDialogComponent } from '../category-mapping/category-mapping-product-example-dialog/category-mapping-product-example-dialog.component';
import { CategorySelectorDialogComponentData } from '../category-selector-dialog/category-selector-dialog.component';
import { CategoryData, CategoryMappingData } from '../wizard-category-mapping/model/category-mapping-data';
import { RemoveMappingData } from '../wizard-category-mapping/model/remove-mapping-data';
import { WizardCategoryMappingActionService } from '../wizard-category-mapping/service/wizard-category-mapping-action.service';
import { WizardCategoryMappingModule } from '../wizard-category-mapping/wizard-category-mapping.module';

@Component({
  selector: 'app-category-mapping',
  templateUrl: './new-category-mapping.component.html',
  styleUrls: ['./new-category-mapping.component.scss'],
  providers: [WizardCategoryMappingActionService],
  standalone: true,
  imports: [CommonModule, WizardCategoryMappingModule, TranslateModule],
})
export class NewCategoryMappingComponent implements StepBase, OnInit {
  @Input() taskId: number;
  @Input() comments: any;

  isLoading = true;

  constructor(
    private categoryMappingService: CategoryMappingService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private categoryMappingActionService: WizardCategoryMappingActionService,
    private translate: TranslateService,
    private catalogService: CatalogService,
    private categoryService: CategoryService
  ) {}

  public categoryMappingItems: CategoryMappingData[];

  ngOnInit(): void {
    this.initData().subscribe((data) => {
      this.categoryMappingItems = data;
    });
  }

  initData(): Observable<CategoryMappingData[]> {
    return combineLatest([
      this.categoryMappingService.getProductCategories(this.taskId),
      this.fetchCategoryMapping(),
      this.store.select(flattenCategoriesSelector),
    ]).pipe(
      map(([productCategories, mapping, synceeCategories]) => {
        return this.mapResponseToMappingData(productCategories, mapping, synceeCategories);
      })
    );
  }

  private mapResponseToMappingData(
    productCategories: string[],
    mapping: MappedCategoryResponse[],
    synceeCategories: CategoryVo[]
  ): CategoryMappingData[] {
    const mappedData: CategoryMappingData[] = [];
    const mappingKeyValuePairs = flatten(mapping.map((entry) => Object.entries(entry)));
    productCategories.forEach((pCategory) => {
      mappedData.push({
        id: pCategory,
        name: pCategory,
        mappedCategories: mappingKeyValuePairs
          .map((entry) => {
            const [key, value] = entry;
            const realValue = value as string[];
            const matchingSynceeCategory = synceeCategories.find((item) => item.id === Number(key));
            if (realValue.includes(pCategory)) {
              return { id: key, name: !!matchingSynceeCategory ? matchingSynceeCategory.name : 'Not found' };
            } else {
              return;
            }
          })
          .filter((item) => !!item),
      });
    });
    return mappedData;
  }

  private fetchCategoryMapping(): Observable<MappedCategoryResponse[]> {
    return this.categoryMappingService
      .getCategoryMapping(this.taskId)
      .pipe(map((result) => result as MappedCategoryResponse[]));
  }

  reInit(): void {
    this.initData();
  }

  isStepValid(): boolean {
    return true;
  }

  private mapSettingsToRequest(
    catalogSettingsVO: Omit<CatalogSettingsVO, 'mainCategories'> & { mainCategories: string[] },
    mapping: Mapping,
    categories: CategoryVo[],
    supplierCatalog: SupplierCatalog
  ): MappedCatalogSettings {
    return {
      name: catalogSettingsVO?.name,
      tags: catalogSettingsVO?.tags?.map((tag) => tag.tag) ?? [],
      isHidden: supplierCatalog.isHidden,
      privateCode: catalogSettingsVO.privateCode,
      visibility: supplierCatalog.visibility,
      description: catalogSettingsVO?.description ?? '',
      settings: {
        possibleMargin: `${catalogSettingsVO?.possibleMargin}` ?? '0',
        language: catalogSettingsVO?.language,
        shipping_type: catalogSettingsVO?.shippingType ?? 'both',
        approveNeeded: catalogSettingsVO?.approveNeeded,
        approveDescription: catalogSettingsVO?.approveDescription,
        needResponse: catalogSettingsVO?.needResponse,
        showPrices: catalogSettingsVO?.showPrices,
        sendEmail: catalogSettingsVO?.sendEmail ?? 'dont',
        warehouseLocation: catalogSettingsVO?.warehouseLocation,
        mainCategories:
          uniq(
            mapping?.categoryId
              ?.map((category) => Object.keys(category)[0])
              ?.map((id) => {
                return `${this.categoryMappingService.findParentOfCategory(categories, +id).id}`;
              })
          ) ?? [],
        feedSettings: catalogSettingsVO?.feedSettings
          ? {
              backorder: catalogSettingsVO?.feedSettings.backorder,
              shipping: catalogSettingsVO?.feedSettings.shipping ?? true,
            }
          : undefined,
        translate_source: catalogSettingsVO?.translate_source,
        translate_target: catalogSettingsVO?.translate_target,
      },
    };
  }

  saveStep(): Observable<any> {
    const getTaskById$: Observable<SupplierCatalog> = this.store.select(getTaskById(+this.taskId));
    const getCatalogById$: Observable<SupplierCatalogDetails> = this.catalogService.getSupplierCatalogById(this.taskId);
    const flatCategories$: Observable<CategoryVo[]> = this.store.select(flattenCategoriesSelector);
    const mapping = this.getMappingVoToSend();

    return combineLatest([getCatalogById$, flatCategories$, getTaskById$]).pipe(
      filter(([details, categories, task]) => !!task && !!details && !!categories && !!categories.length),
      mergeMap(([details, categories, task]) =>
        this.catalogService.updateCatalog({
          id: this.taskId.toString(),
          ...this.mapSettingsToRequest(details.settings, mapping, categories, task),
        })
      ),
      mergeMap(() => this.categoryMappingService.saveCategoryMapping(this.taskId, this.getMappingVoToSend()))
    );
  }

  private getMappingVoToSend(): Mapping {
    const mappings: Record<string, string[]> = {};
    const mappingValues: MappedCategoryResponse[] = [];

    this.categoryMappingItems.forEach((entry) => {
      if (!isEmpty(entry.mappedCategories)) {
        entry.mappedCategories.forEach((mappedEntry) => {
          if (!mappings[mappedEntry.id]) {
            mappings[mappedEntry.id] = [entry.name];
          } else {
            mappings[mappedEntry.id].push(entry.name);
          }
        });
      }
    });
    Object.entries(mappings).forEach(([key, value]) => {
      mappingValues.push({ [key]: value });
    });
    return { catalogId: this.taskId, categoryId: mappingValues };
  }

  public addCategory(selectedCategory: CategoryMappingData): void {
    const dialogData: CategorySelectorDialogComponentData = {
      title: `${selectedCategory.name}`,
      instruction: this.translate.instant('SUPPLIER_CATEGORY_MAPPING.DIALOG.SINGLE_ADD_INSTRUCTION'),
      selectedCategories: selectedCategory.mappedCategories,
      variant: 'synceeCategoryTree',
    };
    this.categoryMappingActionService
      .addMapping(selectedCategory, this.categoryMappingItems, dialogData)
      .pipe(take(1))
      .subscribe((result) => this.handleMappingActionResult(result));
  }

  public addInBulk(selectedCategories: CategoryMappingData[]): void {
    const dialogData: CategorySelectorDialogComponentData = {
      title: this.translate.instant('SUPPLIER_CATEGORY_MAPPING.DIALOG.BULK_ADD_TITLE'),
      instruction: this.translate.instant('SUPPLIER_CATEGORY_MAPPING.DIALOG.BULK_ADD_INSTRUCTION'),
      selectedCategories: [],
      variant: 'synceeCategoryTree',
    };
    this.categoryMappingActionService
      .addMappingInBulk(selectedCategories, this.categoryMappingItems, dialogData)
      .pipe(take(1))
      .subscribe((result) => this.handleMappingActionResult(result));
  }

  public removeMapping(data: RemoveMappingData): void {
    this.categoryMappingItems = this.categoryMappingActionService.removeCategory(data, this.categoryMappingItems);
  }

  public removeInBulk(selectedCategories: CategoryMappingData[]): void {
    const selectableCategories: CategoryData[] = uniqBy(
      flatten(selectedCategories.map((entry) => entry.mappedCategories)),
      'id'
    );
    const dialogData: CategorySelectorDialogComponentData = {
      title: this.translate.instant('SUPPLIER_CATEGORY_MAPPING.DIALOG.BULK_REMOVE_TITLE'),
      instruction: this.translate.instant('SUPPLIER_CATEGORY_MAPPING.DIALOG.BULK_REMOVE_INSTRUCTION'),
      categories: selectableCategories,
    };
    this.categoryMappingActionService
      .removeMappingInBulk(selectedCategories, this.categoryMappingItems, dialogData)
      .pipe(take(1))
      .subscribe((result) => this.handleMappingActionResult(result));
  }

  public clearCategoryMapping(ids: string[]): void {
    this.categoryMappingItems = this.categoryMappingActionService.clearSelectedMappings(ids, this.categoryMappingItems);
  }

  public openProductList(categoryName: string): void {
    this.dialog.open(CategoryMappingProductExampleDialogComponent, {
      width: '500px',
      data: {
        taskId: this.taskId,
        productCategory: categoryName,
        productCategoryId: categoryName,
      },
    });
  }

  private handleMappingActionResult(result: CategoryMappingData[]): void {
    if (!!result) {
      this.categoryMappingItems = result;
    }
  }
}

export interface MappedCategoryResponse {
  [synceeCategoryId: number]: string[];
}

export interface Mapping {
  catalogId: number;
  categoryId: {
    [key: number]: string[];
  }[];
}
