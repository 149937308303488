import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { SubStepsStepperComponent } from '../../../components/sub-steps-stepper/sub-steps-stepper.component';
import { Subject } from 'rxjs';
import { ConnectStatus, PaymentAccount, PaymentService } from '../../../../../service/payment/payment.service';
import { SupplierPaymentAccountBillingComponent } from '../../../../../shared/components/supplier-payment-account-billing/supplier-payment-account-billing.component';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { StepDataModel } from '../../../components/sub-steps-stepper/model/step-data.model';
import { PersonalizationStepTitle } from '../../retailer/personalization/personalization.component';
import { SupplierCurrencyService } from 'app/shared/components/supplier-currency-selector/supplier-currency.service';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';

@Component({
  selector: 'app-getting-paid',
  templateUrl: './getting-paid.component.html',
  styleUrls: ['./getting-paid.component.scss'],
})
export class GettingPaidComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('stepperComponent') stepper: SubStepsStepperComponent;
  @ViewChild('billingInfoForm') billingInfoForm: SupplierPaymentAccountBillingComponent;
  currentStepIndex = 0;
  stepTitles = GETTING_PAID_STEP_TITLES;
  paymentIsMissing = false;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;

  private unsubscribeAll: Subject<void>;

  steps: StepDataModel[] = [
    {
      optional: false,
      state: 'current',
      label: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.STEPPER_LABELS.BILLING_INFORMATION',
      isDone: false,
    },
    {
      optional: false,
      state: 'default',
      label: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.STEPPER_LABELS.PAYMENT_PROVIDER',
      isDone: false,
    },
  ];
  paymentAccount: PaymentAccount;
  paymentAccountLoading = true;
  connectStatus: ConnectStatus;

  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    public paymentService: PaymentService,
    private _supplierCurrency: SupplierCurrencyService
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    if (this.isCompleted) {
      this.steps.map((step) => (step.isDone = true));
    }

    this.currentStepIndex = this.initCurrentSubStep(this.steps);

    this.paymentService.getPaymentAccount().subscribe((paymentAccount) => {
      this.paymentAccount = paymentAccount;
      this.paymentAccountLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleSubStepChange(index: number): void {
    this.currentStepIndex = index;
    this.setCurrentSubStep(index);
  }

  nextClicked(): void {
    switch (this.currentStepIndex) {
      case 0: {
        if (this.billingInfoForm.form.valid && !this.billingInfoForm.form.dirty) {
          this.completeCurrentStep();
          this.incrementCurrentSubStep(this.currentStepIndex);
          this.stepper.navigateSteps.next('next');
          break;
        }
        if (this.billingInfoForm.form.valid) {
          this.billingInfoForm
            .saveClicked()
            .pipe(
              filter((isSuccess) => !!isSuccess),
              mergeMap(() => this.paymentService.getPaymentAccount()),
              tap((paymentAccount) => (this.paymentAccount = paymentAccount))
            )
            .subscribe(() => {
              this.completeCurrentStep();
              this.incrementCurrentSubStep(this.currentStepIndex);
              this.stepper.navigateSteps.next('next');
            });
        } else {
          this.billingInfoForm.form.markAllAsTouched();
        }
        break;
      }
      case 1: {
        if (this.connectStatus && (this.connectStatus.stripeConnectStatus || this.connectStatus.paypalConnectStatus)) {
          this.emptyCurrentSubStep();
          this.goToNextStep();
        } else {
          this.paymentIsMissing = true;
        }
      }
    }
  }

  currencyOnChange(currency: string): void {
    this._supplierCurrency.patchCurrency(currency).subscribe();
  }

  private completeCurrentStep(): void {
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: true });
  }

  prevClicked(): void {
    if (this.currentStepIndex === 0) {
      this.emptyCurrentSubStep();
      this.goToPreviousStep();
    } else {
      this.decrementCurrentSubStep(this.currentStepIndex);
      this.stepper.navigateSteps.next('prev');
    }
  }

  handleConnectStatusChanged(event: ConnectStatus): void {
    this.connectStatus = event;
  }
}

const GETTING_PAID_STEP_TITLES: PersonalizationStepTitle[] = [
  {
    title: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.BILLING_INFORMATION.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.BILLING_INFORMATION.SUBTITLE',
  },
  {
    title: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.PAYMENT_PROVIDER.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.GETTING_PAID.PAYMENT_PROVIDER.SUBTITLE',
  },
];
