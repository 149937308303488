import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';

import { Subject, map } from 'rxjs';

import type { Observable } from 'rxjs';
import type { Widget } from 'app/vo/widget';

@Component({
  selector: 'app-category-card-list',
  templateUrl: './category-card-list.component.html',
  styleUrls: ['./category-card-list.component.scss'],
})
export class CategoryCardListComponent implements OnInit, OnDestroy {
  @Input() widgets$: Observable<Widget[]>;

  @Input() maxNumberOfWidgets: number;

  public cardHeight: number;

  private unsubscribe$ = new Subject<void>();

  constructor(private screenManagerService: ScreenManagerService) {}

  public ngOnInit(): void {
    this.initLtMdSubscription();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initLtMdSubscription(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(map((observation) => observation.matches))
      .subscribe((ltMd: boolean): void => {
        this.cardHeight = ltMd ? 120 : 200;
      });
  }
}
