import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-skeleton-products-list-slider-box',
  templateUrl: './skeleton-products-list-slider-box.component.html',
  styleUrls: ['./skeleton-products-list-slider-box.component.scss'],
})
export class SkeletonProductsListSliderBoxComponent implements OnInit, OnDestroy {
  @Input() hasSeeAll = true;

  public static CONFIG: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: BreakPoint.xl,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    swipe: false,
    draggable: false,
  };

  public ltMd$: Observable<boolean>;

  private unsubscribe = new Subject<void>();

  constructor(private screenManagerService: ScreenManagerService) {}

  public ngOnInit(): void {
    this.initScreenSizeSubscription();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initScreenSizeSubscription(): void {
    this.ltMd$ = this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), takeUntil(this.unsubscribe));
  }

  protected SkeletonProductsListSliderBoxComponent = SkeletonProductsListSliderBoxComponent;
}
