import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import {
  LogoutAction,
  RefreshAuthTokens,
  RefreshCrossLoggedUserAuthTokens,
} from 'app/store/authentication/authentication.actions';
import { JwtUtils } from 'app/utils/jwt-utils';
import { Utils } from 'app/utils/utils';
import { LoginVO } from 'app/vo/authentication/login-vo';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '../../layout/components/toolbar/components/notifications/notifications.service';
import { EmailVerificationLinkSentDialogService } from '../../shared/components/email-verification-link-sent-dialog/email-verification-link-sent-dialog.service';
import { USER_ROLES } from '../../utils/Constants';
import { RoleTypesEnum, ROLE_TYPE } from '../../vo/roles/roles';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { ChatMinimizationService } from '../chat-minimization/chat-minimization.service';
import { IntercomService } from '../intercom/intercom.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { SCOPES } from '../permission/scopes';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestService } from '../rest/rest.service';
import { AuthenticationStorage } from './authentication-storage';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private restService: RestService,
    private _cookieService: CookieService,
    private _catalogSidebarService: CatalogSidebarService,
    private notificationPanelService: NotificationsService,
    private marketplaceEcomService: MarketplaceEcomService,
    private bootstrapService: BootstrapService,
    private chatMinimizationService: ChatMinimizationService,
    private microserviceApiService: SpringRestService,
    private store: Store<AppState>,
    private emailVerificationDialog: EmailVerificationLinkSentDialogService,
    private intercomService: IntercomService
  ) {}

  public getNavigationForUser(): any[] {
    let toNavigate = '/';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (Utils.isNullOrUndefined(currentUser)) {
      return null;
    }

    switch (currentUser.role) {
      case USER_ROLES.SUPPLIER:
        toNavigate = '/quick-guide';
        break;
      case USER_ROLES.AGENT:
        toNavigate = '/quick-guide';
        break;
      case USER_ROLES.GLOBAL_ADMIN:
        toNavigate = '/kiosk';
        break;
      // default:
      //     toNavigate = '/';
    }
    return [toNavigate];
  }

  public loginToUser(email: string): Observable<LoginVO> {
    const requestOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/admin-to-user', { email }, requestOptions, true);
  }

  public login(credentials: Credentials): Observable<LoginVO> {
    const requestOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.microserviceApiService.post(
      MicroserviceNames.AUTH,
      '/token',
      this.toLoginRequest(credentials),
      requestOptions,
      true
    );
  }

  public register(data: RegistrationReqModel): Observable<void> {
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/registration', data, {}, true).pipe(
      switchMap(() => {
        return this.emailVerificationDialog.open(data.email);
      })
    );
  }

  public socialLogin(credentials): Observable<LoginVO> {
    return this.restService.post('UserService/socialLogin', credentials).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  public socialGoogleLogin(jwt: string, role: RoleTypesEnum): Observable<LoginVO> {
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/google-login', { jwt, role }, {}, true);
  }

  public socialFacebookLogin(code: string, role: RoleTypesEnum): Observable<LoginVO> {
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/facebook-login', { code, role }, {}, true);
  }

  public forgotPassword(email: string): Observable<void> {
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/forgot-password', { email }, {}, true);
  }

  public resetPassword(password: string, token: string): Observable<void> {
    return this.microserviceApiService.post(
      MicroserviceNames.AUTH,
      '/password-change-by-code',
      { passwordChangeToken: token, password },
      {},
      true
    );
  }

  public socialRegistration(credentials): Observable<LoginVO> {
    return this.restService.post('UserService/socialRegistration', credentials).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  private toLoginRequest({ email, password }: Credentials): string {
    return new HttpParams()
      .set('username', email)
      .set('password', password)
      .set('client_id', 'public-client')
      .set('grant_type', 'password')
      .set('scope', 'user.read')
      .toString();
  }

  private toRefreshTokenRequest(refreshToken: string): string {
    return new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token')
      .set('client_id', 'public-client')
      .set('scope', 'user.read')
      .toString();
  }

  private _refreshToken(refreshToken: string): Observable<LoginVO> {
    const requestOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this.microserviceApiService.post(
      MicroserviceNames.AUTH,
      '/token',
      this.toRefreshTokenRequest(refreshToken),
      requestOptions,
      true
    );
  }

  public refreshToken(refreshToken: string): Observable<boolean> {
    return this._refreshToken(refreshToken).pipe(
      tap((authData: LoginVO) => {
        const parsedData = this.getParsedAuthData(authData);
        AuthenticationStorage.storeData(parsedData);
        this.store.dispatch(new RefreshAuthTokens(parsedData));
      }),
      map((resp) => true)
      // map(() => {
      //   throw new HttpErrorResponse({
      //     error: 'your error',
      //     status: 401,
      //     statusText: 'Warning',
      //   });
      // })
    );
  }

  public refreshLoggedUserToken(refreshToken: string): Observable<boolean> {
    return this._refreshToken(refreshToken).pipe(
      tap((authData: LoginVO) => {
        const parsedData = this.getParsedAuthData(authData);
        AuthenticationStorage.storeLoggedUserData(parsedData);
        this.store.dispatch(new RefreshCrossLoggedUserAuthTokens(parsedData));
      }),
      map((resp) => true)
    );
  }

  public isTokenPresent(): boolean {
    const data = AuthenticationStorage.getAuthData();
    const cookieCheck = this._cookieService.check('JWT');
    const headerCheck = !Utils.isNullOrUndefined(data?.accessToken);
    return headerCheck || cookieCheck;
  }

  public getAuthToken(): string {
    const authData = this.isLoggedUserByAdmin()
      ? AuthenticationStorage.getLoggedUserAuthData()
      : AuthenticationStorage.getAuthData();

    return authData && `Bearer ${authData.accessToken}`;
  }

  public logoutWithLoginDialog(): void {
    this.store.dispatch(new LogoutAction({ url: '/login', queryParams: { requestUrl: window.location.pathname } }));
  }

  public logoutHandle(): void {
    this.removeUserDataFromLocalStorage();
    this.removeEcomDataFromLocalStorage();
    this.notificationPanelService.stopWatcher();
    this.bootstrapService.handleUserLogout();
    this._cookieService.deleteAll();
    this._catalogSidebarService.clearAll();
    this.intercomService.shutDownIntercom();
    this.intercomService.initGuestIntercom();
    this.marketplaceEcomService.remove();
    this.removeAdminSelectedUserFromSession();
    this.clearChatsFromLocalStorage();
  }

  public removeEcomDataFromLocalStorage(): void {
    localStorage.removeItem('supplierEcoms');
    localStorage.removeItem('retailerEcoms');
    localStorage.removeItem('selectedRetailerEcom');
    localStorage.removeItem('selectedSupplierEcom');
    localStorage.removeItem('selectedRetailerEcomId');
    localStorage.removeItem('selectedSupplierEcomId');
    localStorage.removeItem('selectedImportList');
  }

  public removeUserDataFromLocalStorage(): void {
    AuthenticationStorage.clear();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('actualRole');
    localStorage.removeItem('scopes');
  }

  public removeAdminSelectedUserFromSession(): void {
    this.removeEcomDataFromLocalStorage();
    localStorage.removeItem('userId');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('currentUser');
    AuthenticationStorage.clearLoggedUser();
  }

  public getParsedAuthData(authData: LoginVO): ParsedAuthData {
    return {
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      scopes: JwtUtils.getScopes(authData.accessToken),
      expiresIn: JwtUtils.getExpiresTimestamp(authData.accessToken),
      refreshTokenExpiresIn: authData.refreshTokenExpiresIn,
      tokenType: authData.tokenType,
    };
  }

  public clearChatsFromLocalStorage(): void {
    this.chatMinimizationService.clearAllMinimizedChats();
  }

  public isUserLoggedIn(): boolean {
    return !Utils.isNullOrUndefined(localStorage.getItem('currentUser'));
  }

  public isLoggedUserByAdmin(): boolean {
    return !!AuthenticationStorage.getLoggedUserAuthData();
  }

  changeOldPassword(data: ChangeOldPasswordData): Observable<void> {
    return this.microserviceApiService.post(MicroserviceNames.AUTH, '/password-change', data, undefined, true);
  }

  getParsedAuthDataOperator(): (source: Observable<LoginVO>) => Observable<ParsedAuthData> {
    return (source: Observable<LoginVO>): Observable<ParsedAuthData> =>
      source.pipe(
        map((loginVo) => {
          return this.getParsedAuthData(loginVo);
        }),
        tap((data) => AuthenticationStorage.storeLoggedUserData(data))
      );
  }
}

export class Credentials {
  email: string;
  password: string;
  role?: ROLE_TYPE;
}

export class RegistrationSupplierReqModel {
  email: string;
  password: string;
  role: RoleTypesEnum;
}

export class RegistrationReqModel {
  email: string;
  password: string;
  role: RoleTypesEnum;
}

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export interface ParsedAuthData extends AuthTokens {
  scopes: SCOPES[];
  expiresIn: number;
  refreshTokenExpiresIn: number;
  tokenType: string;
}

export interface ChangeOldPasswordData {
  oldPassword: string;
  newPassword: string;
}
