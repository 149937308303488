import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MetaTagsService, TruncateType } from 'app/service/meta-tags/meta-tags.service';
import { SearchSessionEventService } from 'app/service/search-session/search-session-event.service';
import { SearchSessionMapperService } from 'app/service/search-session/search-session-mapper.service';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { assign, castArray, isEqualWith, isNil, pick, pickBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { BreadcrumbItem } from '../../../../../@fuse/types';
import { AppState } from '../../../../app.state';
import { FilterService } from '../../../../layout/components/filter/filter.service';
import { LayoutService } from '../../../../layout/layout.service';
import { MARKETPLACE_NAVIGATION } from '../../../../navigation/navigation-routes/common/marketplace.navigation';
import { AuthorizationEcomService } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { CatalogSidebarService } from '../../../../service/catalog-sidebar/catalog-sidebar.service';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { CategoryService } from '../../../../service/product-search/category.service';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { SimpleBannerWithImageOptions } from '../../../../shared/components/simple-banner-with-image/simple-banner-with-image.component';
import { GetAssetFromStoragePipe } from '../../../../shared/pipes/get-asset-from-storage';
import { categorySelector } from '../../../../store/category/category.selector';
import { selectedCurrencySelector } from '../../../../store/currency/currency.selector';
import {
  AddFilterWithRedirectAction,
  ClearFilter,
  SetFilterAction,
  SetSearchType,
} from '../../../../store/product-search/product-search.action';
import {
  filterPaginatorSelector,
  filterSelector,
  searchTypeSelector,
} from '../../../../store/product-search/product-search.selector';
import { CategoryVo } from '../../../../vo/category-vo';
import {
  FilterItemsVO,
  MarketplaceFilter,
  MarketplaceFilterSort,
  SearchProductVO,
} from '../../../../vo/search-product-vo';
import { AIChatMessageType } from '../../../ai-chat/model/messages/ai-chat-message';
import { AiChatMessagesService, isAiChatMessageTypeOperator } from '../../../ai-chat/services/ai-chat-messages.service';
import { SnippetEnum } from '../../../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../../../setup-guide/service/setup-guide.service';
import { GTMFilterService } from '../../marketplace-shared-components/service/gtm-filter.service';
import { InitGtmFiltersService } from '../../marketplace-shared-components/service/init-gtm-filters.service';
import { MarketplaceView } from '../marketplace-view-selector/marketplace-view-selector.component';

const BASE_HIDDEN_FILTERS: (keyof MarketplaceFilter)[] = ['shippingType'];
const GUEST_HIDDEN_FILTERS: (keyof MarketplaceFilter)[] = [
  'minShippingTime',
  'maxShippingTime',
  'maxPrice',
  'minPrice',
  'sku',
  'stock',
];

@UntilDestroy()
@Component({
  selector: 'app-explore-products-search',
  templateUrl: './explore-products-search.component.html',
  styleUrls: ['./explore-products-search.component.scss'],
  providers: [GetAssetFromStoragePipe],
})
export class ExploreProductsSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('filterRow') filterRow: ElementRef<HTMLElement>;
  public products: SearchProductVO[] = [];
  public resultNumber = 0;
  public ecomCurrency = 'USD';
  public selectedEcom: EcomVO;
  public loading = true;
  public isMdView$: Observable<boolean>;
  public title: Observable<string>;
  private readonly _searchType: Observable<MarketplaceView>;
  private searchTypeChange = new BehaviorSubject<MarketplaceView>('PRODUCTS');
  private filter$: Observable<MarketplaceFilter>;
  private paginator$: Observable<MarketplaceFilterSort>;
  private intersectionObserver: IntersectionObserver;
  nextSubject: Subject<void>;
  prevSubject: Subject<void>;
  affiliateCta: SimpleBannerWithImageOptions;
  hiddenFilters: (keyof MarketplaceFilter)[] = BASE_HIDDEN_FILTERS;
  private unsubscribeAll = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private fuseConfigService: FuseConfigService,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private catalogSidebarService: CatalogSidebarService,
    private ecomMPService: MarketplaceEcomService,
    private authorizationService: AuthorizationEcomService,
    private screenManagerService: ScreenManagerService,
    private categoryService: CategoryService,
    private translateService: TranslateService,
    private initGtmFiltersService: InitGtmFiltersService,
    private setupGuideService: SetupGuideService,
    private aiChatMessagesService: AiChatMessagesService,
    private metaTagsService: MetaTagsService,
    public layoutService: LayoutService,
    private searchSessionEventService: SearchSessionEventService,
    private searchSessionMapperService: SearchSessionMapperService,
    private getAssetFromStoragePipe: GetAssetFromStoragePipe,
    private gtmFilterService: GTMFilterService
  ) {
    this.filter$ = this.store.select(filterSelector);
    this.paginator$ = this.store.select(filterPaginatorSelector);
    this._searchType = this.store.select(searchTypeSelector);
    this.nextSubject = new Subject();
    this.prevSubject = new Subject();
    this.initTheme();
    this.createIsMdViewSubscription();
    this.title = this.getTitle();
  }

  ngOnInit(): void {
    this.initByQueryParams();
    this.subscribeToQueryChanges();
    this.initByAuthenticatedUserWithEcom();
    this.getCurrency();
    this.initAuthenticatedUser();
    this.sendFiltersToGtm();
    this.subscribeToAiChat();
    this.initAffiliateBannerOptions();
    this.hideFiltersForGuests();
  }

  private hideFiltersForGuests(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(omitNullOrUndefined(), untilDestroyed(this))
      .subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated) {
            this.hiddenFilters = BASE_HIDDEN_FILTERS;
          } else {
            this.hiddenFilters = [...BASE_HIDDEN_FILTERS, ...GUEST_HIDDEN_FILTERS];
          }
        },
      });
  }

  private subscribeToAiChat(): void {
    this.aiChatMessagesService.lastMessage
      .pipe(debounceTime(0), untilDestroyed(this), isAiChatMessageTypeOperator(AIChatMessageType.FILTER))
      .subscribe((message) => {
        this.store.dispatch(new SetFilterAction({ filter: message.payload.filter, skipLocationChange: true }));
      });
  }

  private getTitle(): Observable<string> {
    return combineLatest([
      this.store.select(categorySelector),
      this.filter$.pipe(
        select((state) => state.category),
        filter((category) => !!category)
      ),
      this.filter$.pipe(select((state) => state.search)),
      this.searchType,
    ]).pipe(
      untilDestroyed(this),
      tap(([categoryTree, categoryId, term, type]) => {
        this.addMetaTags(categoryTree, categoryId, undefined, type);
      }),
      map(([categoryTree, categoryId, term, type]) => this.mapTitle(categoryTree, categoryId, term, type))
    );
  }

  private mapTitle(categoryTree: CategoryVo, categoryId: number, term: string, type: MarketplaceView): string {
    if (!!term) {
      return this.translateService.instant('PRODUCT_LIST.SEARCH_RESULT') + ` '${term}'`;
    } else if (!!categoryTree && !!categoryId && categoryId !== 1) {
      return this.categoryService.getNameForCategory(
        this.categoryService.searchInCategories(categoryTree, categoryId),
        'en'
      );
    } else {
      return this.translateService.instant('PRODUCT_LIST.' + type);
    }
  }

  private createIsMdViewSubscription(): void {
    this.isMdView$ = this.screenManagerService.observeBreakpoint(BreakPoint.md).pipe(
      untilDestroyed(this),
      map((event) => event.matches)
    );
  }

  private initAuthenticatedUser(): void {
    this.authorizationService.isAuthenticatedUser().subscribe(() => {
      this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_EXPLORE_PRODUCTS);
      this.catalogSidebarService.getRetailerToCatalogList();
    });
  }

  private initByAuthenticatedUserWithEcom(): void {
    this.ecomMPService
      .getEcomWithSubscriptionFromStore()
      .pipe(untilDestroyed(this))
      .subscribe((ecomVO: EcomVO) => {
        this.handlePlansLoaded(ecomVO);
      });
  }

  ngAfterViewInit(): void {
    this.intersectionObserver = this.getIntersectionObserver();
    this.intersectionObserver.observe(this.filterRow.nativeElement);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.store.dispatch(new ClearFilter());
    this.store.dispatch(new SetSearchType({ value: 'PRODUCTS' }));
    this.intersectionObserver.disconnect();
  }

  private getCurrency(): void {
    this.store.select(selectedCurrencySelector).subscribe((currency) => {
      this.ecomCurrency = currency;
    });
  }

  handleResultNumberChanged(value: number): void {
    this.resultNumber = value;
  }

  private handlePlansLoaded(selectedEcom: EcomVO): void {
    this.selectedEcom = selectedEcom;
    this.loading = false;
  }

  private writeToQueryParams(
    filterToWrite: MarketplaceFilter,
    paginator: MarketplaceFilterSort,
    searchType: MarketplaceView,
    currentParams: Params
  ): void {
    this.store
      .select(categorySelector)
      .pipe(
        filter((category) => !!category),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((categoryTree) => {
        const tree = pickBy({ ...filterToWrite, searchType: searchType, ...paginator }, (o) => !isNil(o));

        const paramsAreEqual = isEqualWith(tree, currentParams, (o1, o2) => {
          // This is NOT a strict equal check _on purpose_
          if (o1 == o2) {
            return true;
          }
        });

        if (paramsAreEqual) {
          return;
        }

        const params: string[] = [];

        Object.keys(tree).map((k) => params.push(`${k}=${castArray(tree[k]).join(',')}`));

        const categoryId = !filterToWrite || !filterToWrite.category ? 1 : filterToWrite.category;
        const categoryHandle = this.categoryService.getHandleForCategory(
          this.categoryService.searchInCategories(categoryTree, categoryId)
        );

        this.router
          .navigateByUrl(`/${MARKETPLACE_NAVIGATION.COLLECTIONS}/${categoryId}/${categoryHandle}?${params.join('&')}`)
          .then(() => this.initTheme());
      });
  }

  // private writeToQueryParams(filterToWrite: MarketplaceFilter, paginator: MarketplaceFilterSort): void {
  //   const tree = this.router.createUrlTree([], {
  //     queryParams: { ...filterToWrite, searchType: this.searchType, ...paginator },
  //   });
  //   this.router.navigate(
  //     [
  //       `/collections/${
  //         isNullOrUndefined(filter) || isNullOrUndefined(filterToWrite.category) ? 1 : filterToWrite.category
  //       }/asd`,
  //     ],
  //     {
  //       relativeTo: this.activatedRoute,
  //       queryParams: tree.queryParams,
  //     }
  //   );
  // }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        categorySelector: {
          redirect: false,
        },
        breadcrumb: {
          hidden: false,
          path: this.setBreadCrumbPath(),
        },
        navbar: {
          hidden: true,
        },
        contentContainer: 'base-container',
        aiChat: {
          hidden: false,
        },
        toolbar: {
          position: 'sticky-on-scroll',
        },
      },
    };
  }

  private setBreadCrumbPath(): BreadcrumbItem[] {
    return [
      { translationKey: 'NAV.HOME', url: '/', shouldTranslate: true },
      { translationKey: 'NAV.SEARCH_PRODUCTS', url: null, shouldTranslate: true },
    ];
  }

  private initByQueryParams(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.initFilterByQueryParams(params);
      if (!!params.searchType) {
        this.handleSearchTypeChange(params.searchType);
      }
    });
  }

  handleSearchTypeChange(value: MarketplaceView): void {
    this.store.dispatch(new SetSearchType({ value }));
    this.gtmFilterService.position = value;
  }

  private initFilterByQueryParams(params: Params): void {
    const newFilter: MarketplaceFilter = {};
    const filterKeys = new FilterItemsVO().getParams().map((param) => param.name);
    assign(newFilter, pick(params, filterKeys));
    const order: MarketplaceFilterSort =
      !!params.sortOrder && !!params.sortField ? { sortOrder: params.sortOrder, sortField: params.sortField } : {};
    const normalizedFilter = this.filterService.normalizeFilterFromStrings(newFilter);
    const categoryFromRoute = Number(this.activatedRoute.snapshot.paramMap.get('category_id'));
    this.store.dispatch(
      new AddFilterWithRedirectAction({
        filter: { ...normalizedFilter, category: categoryFromRoute, ...order },
        needRedirect: false,
      })
    );
  }

  private sendFiltersToGtm(): void {
    combineLatest([this.filter$.pipe(omitNullOrUndefined(), take(1)), this.searchType.pipe(take(1))]).subscribe(
      ([filterValue, searchType]): void => {
        this.initGtmFiltersService.initFilterGtm(filterValue, searchType);
      }
    );
  }

  private subscribeToQueryChanges(): void {
    combineLatest([this.filter$, this.searchType, this.paginator$, this.activatedRoute.queryParams])
      .pipe(untilDestroyed(this), takeUntil(this.unsubscribeAll))
      .subscribe(([filterFromStore, searchType, paginator, currentParams]) => {
        this.writeToQueryParams(filterFromStore, paginator, searchType, currentParams);
        this.sendSearchDataEvent(filterFromStore);
      });
  }

  private getIntersectionObserver(): IntersectionObserver {
    return new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle('pinned', !e.isIntersecting);
      },
      {
        threshold: 1.0,
      }
    );
  }

  get searchType(): Observable<MarketplaceView> {
    return this._searchType;
  }

  private addMetaTags(categoryTree: CategoryVo, categoryId: number, _: string, type: MarketplaceView): void {
    let categoryName: string;
    if (!!categoryTree && !!categoryId && categoryId !== 1) {
      categoryName = this.categoryService.getNameForCategory(
        this.categoryService.searchInCategories(categoryTree, categoryId),
        'en'
      );
    } else {
      categoryName = type.substring(0, 1) + type.substring(1, type.length).toLowerCase();
    }

    this.metaTagsService.updateTags(
      categoryName,
      `Start and grow your dropshipping business with Syncee. Find millions of ${categoryName} products with fast shipping. Enjoy an all-in-one dropshipping solution.`,
      'https://app.syncee.co/assets/images/syncee-global-dropshipping-banner.png',
      location.href,
      TruncateType.SYNCEE
    );
  }

  private sendSearchDataEvent(marketplaceFilter: MarketplaceFilter): void {
    this.searchSessionEventService.sendSearchDataEvent(
      this.searchSessionMapperService.mapMarketplaceFilterToSearchSessionData(marketplaceFilter)
    );
  }

  private initAffiliateBannerOptions(): void {
    this.translateService
      .stream('AFFILIATE_BANNER')
      .pipe(untilDestroyed(this), omitNullOrUndefined())
      .subscribe((translation): void => {
        this.affiliateCta = {
          text: translation['DESCRIPTION'],
          translateText: false,
          image: {
            src: this.getAssetFromStoragePipe.transform('images/inapp-ads/category-cta-bg.png'),
            alt: translation['DESCRIPTION'],
            height: 144,
            width: 461,
          },
          imageLtSm: {
            src: this.getAssetFromStoragePipe.transform('images/inapp-ads/mobil-category-cta-bg.png'),
            alt: translation['DESCRIPTION'],
            height: 144,
            width: 461,
          },
          button: {
            type: 'inner_redirect',
            url: '/referrals/my-referrals',
            textKey: translation['BUTTON'],
            buttonClass: 'syncee-blue-button',
          },
          containerClasses: ['syncee-grey-50-bg'],
        };
      });
  }
}
