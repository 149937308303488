import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PreferencesState } from './preferences.reducer';
import { PreferenceTypeRecord } from '../../service/preference/preference.store.service';
import { fromPairs } from 'lodash';
import { PreferenceNameEnum } from '../../vo/Preferences/preference-name.enum';

const getPreferenceState = createFeatureSelector<PreferencesState>('preferences');

export const userPreferencesSelector = createSelector(getPreferenceState, (state) => {
  if (!!state && !!state.preferences) {
    return state.preferences;
  }
});

export const preferenceTypesSelector = createSelector(getPreferenceState, (state) => {
  if (!!state && state.preferenceTypes) {
    return state.preferenceTypes;
  }
});

export const typesIdsByNameSelector = createSelector(getPreferenceState, (state): PreferenceTypeRecord => {
  if (!!state && !!state.preferenceTypes) {
    const nameIdPairs = state.preferenceTypes.map((type) => [type.name, type.id]);
    return fromPairs(nameIdPairs) as PreferenceTypeRecord;
  }
});

export const userLocationPreferenceSelector = createSelector(
  getPreferenceState,
  (state) =>
    !!state &&
    !!state.preferenceTypes &&
    !!state.preferences &&
    state.preferences.find(
      (preference) =>
        preference.preferenceTypeId ===
        state.preferenceTypes.find((type) => type.name === PreferenceNameEnum.MY_LOCATION)?.id
    )?.preferenceValue
);

export const shipsToPreferenceSelector = createSelector(getPreferenceState, (state) => {
  const preferenceTypeId =
    !!state.preferenceTypes &&
    state.preferenceTypes.find((type) => type.name === PreferenceNameEnum.SHIPS_TO_LOCATION)?.id;
  return (
    !!state &&
    !!state.preferenceTypes &&
    !!state.preferences &&
    state.preferences
      .filter((preference) => preference.preferenceTypeId === preferenceTypeId)
      .map((preference) => preference.preferenceValue)
  );
});
