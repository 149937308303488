import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { CategoryVo } from '../../../../vo/category-vo';
import { categorySelector } from '../../../../store/category/category.selector';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { ChipListSelectorItem } from '../../../../shared/components/chip-list-selector/chip-list-selector.component';
import { CategoryService } from '../../../../service/product-search/category.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-preference-category',
  templateUrl: './preference-category.component.html',
  styleUrls: ['./preference-category.component.scss'],
})
export class PreferenceCategoryComponent implements OnInit, OnDestroy {
  @Input() set selectedCategories(value: number[]) {
    this.selectedCategoriesChanged.next(value);
  }
  @Input() title?: string;
  @Input() subTitle?: string;
  @Output() selectedCategoriesChange = new EventEmitter<number[]>();
  chipItems: ChipListSelectorItem[] = [];
  selectedCategoryChips: ChipListSelectorItem[] = [];
  private readonly currentLang: string;
  private categories: CategoryVo;
  private unsubscribeAll: Subject<void>;
  private selectedCategoriesChanged: BehaviorSubject<number[]>;

  constructor(
    private store: Store<AppState>,
    private categoryService: CategoryService,
    private translateService: TranslateService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.unsubscribeAll = new Subject<void>();
    this.selectedCategoriesChanged = new BehaviorSubject<number[]>([]);
  }

  ngOnInit(): void {
    combineLatest([this.selectedCategoriesChanged, this.getCategories()])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(([selectedCategories, categories]) => {
        this.selectedCategoryChips = this.mapCategoryIdsToChipListSelectorItems(categories, selectedCategories);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private getCategories(): Observable<CategoryVo> {
    return this.store.select(categorySelector).pipe(
      filter((categories) => !!categories),
      tap((categories) => (this.categories = categories)),
      tap((categories) => this.setCategoriesChips(categories))
    );
  }

  private setCategoriesChips(categories: CategoryVo): void {
    this.chipItems = categories.children.map((category) => this.mapCategoryVoToChipListSelectorItem(category));
  }

  private mapCategoryIdsToChipListSelectorItems(
    categoryTree: CategoryVo,
    categoryIds: number[]
  ): ChipListSelectorItem[] {
    return categoryIds
      .map((categoryId) => {
        const cat = this.categoryService.searchInCategories(categoryTree, categoryId);
        return !!cat ? this.mapCategoryVoToChipListSelectorItem(cat) : null;
      })
      .filter((item) => !!item);
  }

  private mapCategoryVoToChipListSelectorItem(category: CategoryVo): ChipListSelectorItem {
    return {
      id: category.id.toString(),
      title: this.categoryService.getNameForCategory(category, this.currentLang),
      icon: {
        icon: `https://storage.googleapis.com/syncee-images/category-icons/${category.id}.svg`,
        type: 'url',
      },
    };
  }

  private mapChipListSelectorItemsToCategoryIds(items: ChipListSelectorItem[]): number[] {
    return items.map((item) => Number(item.id));
  }

  handleItemChange(event: ChipListSelectorItem[]): void {
    this.selectedCategoriesChange.emit(this.mapChipListSelectorItemsToCategoryIds(event));
  }
}
