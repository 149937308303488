import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { EventNameGtm } from '../model/event-name-gtm';

export class ProductViewGtmAction implements GtmAction {
  event = GtmEvent.product_view;

  constructor(public payload?: ProductViewGtmActionPayload) {
    this.payload = { ...payload, event_name: GtmEvent.product_view };
  }
}

// tslint:disable-next-line:no-empty-interface
export interface ProductViewGtmActionPayload extends EventNameGtm {}
