import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingZoneDto } from '../../../../vo/shipping-zones/shipping-zone-dto';
import { ContinentShippingInfo } from '../service/continent-shipping-info';
import { ShippingDetailsZonesService } from '../service/shipping-details-zones.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ShippingDetailsRatesTableComponent } from '../shipping-details-rates-table/shipping-details-rates-table.component';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShippingDetailsRatesMobileComponent } from '../shipping-details-rates-mobile/shipping-details-rates-mobile.component';

@UntilDestroy()
@Component({
  selector: 'app-shipping-details-zones',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, ShippingDetailsRatesTableComponent, ShippingDetailsRatesMobileComponent],
  providers: [ShippingDetailsZonesService],
  templateUrl: './shipping-details-zones.component.html',
  styleUrls: ['./shipping-details-zones.component.scss'],
})
export class ShippingDetailsZonesComponent implements OnInit {
  @Input() shippingZones: ShippingZoneDto[];
  @Input() isWorldwide: boolean;
  @Input() supplierCurrency: string;
  @Input() hasAppliedShippingZone: boolean;
  @Input() isAppliedShippingZone: boolean;

  shippingInfos: ContinentShippingInfo[];
  isMobileSize: boolean;

  constructor(
    private shippingDetailsZonesService: ShippingDetailsZonesService,
    private screenManager: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.checkMobileSize();
    this.shippingInfos = this.shippingDetailsZonesService.createShippingInformation(
      this.shippingDetailsZonesService.mapToObjectWithConcatenatedCountries(this.shippingZones),
      this.isWorldwide
    );
  }

  private checkMobileSize(): void {
    this.screenManager
      .observeBreakpoint(BreakPoint.sm)
      .pipe(untilDestroyed(this))
      .subscribe((breakPointState) => {
        this.isMobileSize = breakPointState.matches;
      });
  }
}
