<div>
  <div class="product-card-action-general-container">
    <div
      class="default-container typography-label"
      [style.background-color]="config.none.actionButtonStyle.backgroundColor"
      [style.color]="config.none.actionButtonStyle.color"
      (click)="handleButtonClicked()"
      *ngIf="hasAvailableEcom"
    >
      <ng-container *ngIf="hasMoreCatalog; else defaultText">
        <ng-container *ngIf="selectedCatalog; else defaultText"
          >{{ 'PRODUCT_CARD_ACTION_DEFAULT.ADD_TO' | translate }} "{{ selectedCatalog.name }}"</ng-container
        >
      </ng-container>
    </div>
  </div>
  <ng-template #defaultText> {{ 'PRODUCT_CARD_ACTION_DEFAULT.ADD_TO_IMPORT_LIST' | translate }} </ng-template>
</div>
