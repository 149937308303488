import { Component, Input, OnInit } from '@angular/core';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { SupplierGatewayService } from 'app/service/suppliers/supplier-gateway.service';
import { switchMap } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CatalogGatewayService } from '../../../../service/catalog/catalog-gateway.service';
import { PreferenceStoreService } from '../../../../service/preference/preference.store.service';
import { SupplierCard } from '../../supplier-card/supplier-card.component';

@Component({
  selector: 'app-recommended-suppliers-by-location-slider',
  templateUrl: './recommended-suppliers-by-location-slider.component.html',
  styleUrls: ['./recommended-suppliers-by-location-slider.component.scss'],
})
export class RecommendedSuppliersByLocationSliderComponent implements OnInit {
  @Input() selectedEcom: EcomVO;
  suppliers: SupplierCard[];
  isLoading = true;
  supplierImages: Record<number, string[]>;

  constructor(
    private supplierGatewayService: SupplierGatewayService,
    private preferenceStoreService: PreferenceStoreService,
    private catalogGatewayService: CatalogGatewayService,
    private navigationEventService: NavigationEventService
  ) {}

  ngOnInit(): void {
    this.fetchSuppliers();
  }

  private fetchSuppliers(): void {
    this.preferenceStoreService
      .getPreferredLocation()
      .pipe(
        switchMap((location) =>
          this.supplierGatewayService.getSupplierRecommendations(location.code, { page: 0, size: 20 })
        ),
        map((springPage) => springPage.content),
        tap((suppliers) => this.getSupplierImages(suppliers.map((supplier) => supplier.userId))),
        switchMap((suppliers) =>
          this.catalogGatewayService
            .getCatalogCategories(suppliers.map((supplier) => supplier.userId))
            .pipe(
              map((catalogCategories) =>
                this.supplierGatewayService.mapSupplierDtoToSupplierCardModel(suppliers, catalogCategories)
              )
            )
        ),
        takeUntil(this.navigationEventService.onNavigationStart)
      )
      .subscribe((suppliers) => {
        this.suppliers = suppliers;
        this.isLoading = false;
      });
  }
  private getSupplierImages(supplierIds: number[]): void {
    this.supplierGatewayService
      .getRandomSupplierProductImages(supplierIds)
      .pipe(takeUntil(this.navigationEventService.onNavigationStart))
      .subscribe((imageUrls) => {
        this.supplierImages = this.supplierGatewayService.mapRandomSupplierVosToRecord(imageUrls);
      });
  }
}
