import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../service/user/user.service';
import { DOCUMENT } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EcomService } from '../../../service/ecom/ecom.service';
import { NotificationService } from '../../notification/notification.service';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-connect-your-store-install',
  templateUrl: './connect-your-store-install-dialog.component.html',
  styleUrls: ['./connect-your-store-install-dialog.component.scss'],
})
export class ConnectYourStoreInstallDialogComponent implements OnInit {
  wpUrl: string;
  jumpseller: UntypedFormGroup;
  shoprenter: UntypedFormGroup;
  selectedType = '';

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(MAT_DIALOG_DATA) public data,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private ecomService: EcomService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.selectionChange(this.data.ecomType);
  }

  initJumpsellerInstall(): void {
    this.jumpseller = this.fb.group({
      domain: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\-.]+\\.jumpseller\\.com$')]],
    });
  }

  selectionChange(ecomType): void {
    this.selectedType = ecomType;
    switch (ecomType) {
      case 'jumpseller':
        this.initJumpsellerInstall();
        break;
      case 'shoprenter':
        this.initShoprenterInstall();
        break;
    }
  }

  installJumpseller(): void {
    this.ecomService
      .installJumpseller({
        domain: this.jumpseller.get('domain').value,
        name: null,
        storeKey: null,
        role: this.data.role,
      })
      .subscribe(
        (response) => {
          const url = response;

          if (!Utils.isNullOrUndefined(url)) {
            this.document.location.href = url;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  installWoocommerce(): void {
    if (!this.isValidUrl(this.wpUrl)) {
      this.notificationService.error('The given URL must be valid! (for example: "https://www.mystore.co")');
      if (!this.wpUrl.startsWith('https://')) {
        this.notificationService.error('The given URL must start with "https://" !');
      }
      return;
    }
    const domain = new URL(this.wpUrl);
    const userData = btoa(`${this.userService.getCachedUser().id}|${domain.hostname}`);

    let callbackUrl = `${environment.installerURL}/api/woo_auth/woo_auth`;
    if (this.userService.getCachedUser().role === 1) {
      callbackUrl = `${environment.installerURL}/api/woo_supplier_auth/woo_auth`;
    }

    const returnUrl = window.location.hostname;
    const fullUrl = `https://${domain.hostname}/wc-auth/v1/login/?app_name=Syncee&user_id=${userData}&return_url=${returnUrl}&callback_url=${callbackUrl}&returnUrl=${returnUrl}&scope=read_write`;
    this.document.location.href = fullUrl;
  }

  initShoprenterInstall(): void {
    this.shoprenter = this.fb.group({
      domain: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\-.]+\\.shoprenter\\.hu')]],
    });
  }

  installShoprenter(): void {
    this.ecomService
      .installShoprenter({
        domain: this.shoprenter.get('domain').value,
        name: null,
        storeKey: null,
        role: this.data.role,
      })
      .subscribe(
        (response) => {
          const url = response;

          if (!Utils.isNullOrUndefined(url)) {
            this.document.location.href = url;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  get jumpsellerDomain(): AbstractControl<any, any> {
    return this.jumpseller.get('domain');
  }

  get shoprenterDomain(): AbstractControl<any, any> {
    return this.shoprenter.get('domain');
  }

  isValidUrl(url: string): boolean {
    try {
      // tslint:disable-next-line:no-unused-expression
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }
}
