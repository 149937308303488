import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SupplierBlock } from 'app/main/marketplace/supplier-card/supplier-slider-box/supplier-slider-box.component';
import { SupplierSettingsDialogComponent } from 'app/main/users/supplier-settings-dialog/supplier-settings-dialog.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../../utils/utils';
import { CatalogVisibilityVO } from '../../vo/catalog-visibility-vo';
import { InternationalShippingVo, InternationShippingCompany } from '../../vo/international-shipping-vo';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestResponseWithPagination } from '../rest/rest-response';
import { RestService } from '../rest/rest.service';
import { User } from '../user/user';
import { SupplierDetails } from './supplier-details.service';

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  constructor(
    private restService: RestService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _springService: SpringRestService
  ) {}

  public getAllApprovedCatalogsBySeoUrl(seoUrl: string): Observable<SupplierCatalogListItem[]> {
    return this.restService
      .get(`SupplierCatalogService/getAllApprovedCatalogsBySeoUrl?seoUrl=${seoUrl}`)
      .pipe(map((response) => response.getData()));
  }

  public getSupplierDetails(): Observable<any> {
    return this.restService
      .get(`SupplierDetailsService/getSupplierDetails`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getSupplierInformation(params): Observable<SupplierDetails> {
    return this.restService
      .get(`SupplierDetailsService/getSupplierDetails`, params)
      .pipe(map((response) => response.getData()[0]));
  }

  public getCatalogInformation(catalogID): Observable<any> {
    return this.restService
      .get(`SupplierCatalogService/getCatalogDetails?catalogId=${catalogID}`)
      .pipe(map((response) => response.getData()));
  }

  public searchSupplierCatalogs(params): Observable<any> {
    return this.restService
      .get(`SupplierCatalogService/searchSupplierCatalogs`, params)
      .pipe(map((response) => response.getData()));
  }

  public checkSupplierDetails(user: User): void {
    this.getSupplierInformation(user.id).subscribe((response) => {
      if (Utils.isNullOrUndefined(response[0].website)) {
        this.dialog.open(SupplierSettingsDialogComponent, { disableClose: true, height: '80%' });
      }
    });
  }

  public getReviewsBySupplier(params): Observable<any> {
    return this.restService
      .get(`SupplierReviewService/getReviewsBySupplierId`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public updateSupplierReview(params): Observable<any> {
    return this.restService
      .post(`SupplierReviewService/updateReview`, params)
      .pipe(map((response) => response.getData()));
  }

  public addSupplierReview(params): Observable<any> {
    return this.restService.post(`SupplierReviewService/addReview`, params).pipe(map((response) => response.getData()));
  }

  public deleteReview(params): Observable<any> {
    return this.restService
      .post(`SupplierReviewService/deleteReview`, params)
      .pipe(map((response) => response.getData()));
  }

  public getShippingZones(): Observable<any> {
    return this.restService.get(`ShippingZoneRateService/getShippingZones`).pipe(map((response) => response.getData()));
  }
  public addShippingZone(params): Observable<any> {
    return this.restService
      .post(`ShippingZoneRateService/addNewShippingZone`, params)
      .pipe(map((response) => response.getFirstData()));
  }
  public deleteShippingZone(zoneId: number): Observable<any> {
    return this.restService
      .post(`ShippingZoneRateService/deleteShippingZone`, { id: zoneId })
      .pipe(map((response) => response.getData()));
  }
  public saveWorldwideShipping(params): Observable<any> {
    return this.restService
      .post(`SupplierDetailsService/saveWorldwideShipping`, params)
      .pipe(map((response) => response.getData()));
  }
  public editShippingZone(params): Observable<any> {
    return this.restService
      .post(`ShippingZoneRateService/saveShippingZone`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public sanitizeURL(url): any {
    if (url && !url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public saveFeaturedSuppliers(params): Observable<any> {
    return this.restService.post('SuppliesFeaturedService/saveAll', params).pipe(map((res) => res.getData()));
  }

  public searchSuppliers(search): Observable<any> {
    return this.restService
      .get(`SupplierDetailsService/searchSuppliers?search=${search}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public getFeaturedSuppliers(): Observable<SupplierBlock[]> {
    return this.restService.get('SuppliesFeaturedService/getFeaturedSuppliers').pipe(map((res) => res.getData()));
  }

  public deleteFeaturedSupplier(body): Observable<any> {
    return this.restService.delete('SuppliesFeaturedService/delete', body).pipe(map((res) => res.getData()));
  }

  public getReviewsForUser(userId): Observable<any> {
    return this.restService
      .get(`SupplierReviewService/getReviewsForUser?user=${userId}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public getSupplierChatDetails(supplier): Observable<any> {
    return this.restService
      .get(`SupplierDetailsService/getSupplierChatDetails?id=${supplier}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public getSupplierChatDetailsByUser(user): Observable<any> {
    // OLD_TODO remove userId from queryParams when backend accepts uId
    return this.restService
      .get(`SupplierDetailsService/getSupplierChatDetails?userId=${user}&uId=${user}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public getCatalogVisibilityBySeoUrl(seoUrl: string): Observable<CatalogVisibilityVO> {
    return this.restService
      .get(`SupplierDetailsService/getCatalogVisibilityBySeoUrl?seoUrl=${seoUrl}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public sendMailToShippingCompany(params: InternationalShippingVo): Observable<any> {
    return this.restService
      .post('SupplierDetailsService/sendMailToShippingCompany', params)
      .pipe(map((res) => res.getData()));
  }

  public getNotifiedCompanies(): Observable<InternationShippingCompany[]> {
    return this.restService.get('SupplierDetailsService/getNotifiedCompanies').pipe(map((res) => res.getData()));
  }

  public getAllCatalogWithApprovedStatus(supplierUserId: number): Observable<AllCatalogWithApprovedStatus[]> {
    return this.restService
      .get(`SupplierCatalogService/getAllCatalogWithApprovedStatus?supplierUserId=${supplierUserId}`)
      .pipe(map((res) => res.getData()));
  }

  public searchPremiumSuppliers(search?): Observable<any> {
    return this.restService
      .get(`SupplierDetailsService/searchPremiumSuppliers?search=${search}`)
      .pipe(map((res) => res.getFirstData()));
  }

  public getSupplierDataForFilter(
    from: number,
    size: number,
    searchTerm?: string,
    vip = false
  ): Observable<RestResponseWithPagination<SupplierDataForFilter>> {
    const restData = { from, size, vip };
    if (!Utils.isNullOrUndefined(searchTerm)) {
      restData['supplierName'] = searchTerm;
    }
    return this.restService.getWithPagination('SupplierService/supplierDataForFilter', restData);
  }

  public getSupplierData(supplierUserId: number): Observable<SupplierData> {
    return this.restService.get<SupplierData>('SupplierService', { supplierUserId }).pipe(
      map((res) => {
        return res.getFirstData();
      })
    );
  }

  public saveSupplierImage(image: File, type: SupplierImageType): Observable<void> {
    const formData = Utils.objectToFormData({ [type]: image, type }, new FormData());
    return this.restService.post('SupplierDetailsService/addImage', formData).pipe(map(() => {}));
  }

  deleteSupplierImage(supplierUserId: number, imageType: SupplierImageTypeEnum): Observable<void> {
    return this._springService
      .delete(MicroserviceNames.USER, `/Supplier/${supplierUserId}/Image/${imageType}`, undefined, true)
      .pipe(map(() => {}));
  }

  public patchSupplier(supplierData: Partial<SupplierData>): Observable<void> {
    return this._springService.patch(MicroserviceNames.USER, '/Supplier', supplierData, {}, true).pipe(map(() => {}));
  }
}

export interface AllCatalogWithApprovedStatus {
  id: string;
  userId: number;
  name: string;
  seoUrl: string;
  approved: -1 | 0 | 1;
}

export interface SupplierDataForFilter {
  userId: number;
  companyName: string;
  vip: boolean;
  isNew: boolean;
  premium: boolean;
  mainWarehouseLocation: string;
}

export interface SupplierData {
  id: number;
  userId: number;
  contactEmail: string;
  website: string;
  description: string;
  categories?: string;
  date: string;
  mainWarehouseLocation: string;
  worldwideShipping: boolean;
  europeanCountries?: string;
  europeanShippingTime: string;
  asianCountries?: string;
  asianShippingTime: string;
  americanCountries?: string;
  americanShippingTime: string;
  africanCountries?: string;
  africanShippingTime: string;
  australianCountries?: string;
  australianShippingTime: string;
  companyName: string;
  ownInventory: boolean;
  phoneCode: string;
  phoneNumber: string;
  publicPhoneCode?: string;
  publicPhoneNumber?: string;
  discount: number;
  yearOfFoundation: number;
  paymentCurrency?: string;
}

export interface SupplierCatalogListItem {
  catId: string;
  name: string;
  seoUrl: string;
  isHidden: boolean;
  visibility: boolean;
}

export type SupplierImageType = 'coverImage' | 'supplierLogo' | 'brandLogo';

export enum SupplierImageTypeEnum {
  COVER_IMAGE = 'COVER_IMAGE',
  SUPPLIER_LOGO = 'SUPPLIER_LOGO',
  BRAND_LOGO = 'BRAND_LOGO',
}
