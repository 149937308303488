<div
  [ngStyle]="{ 'background-color': ads[randomNumber].backgroundColor }"
  [routerLink]="ads[randomNumber].redirectLink"
  fxLayout="column"
  fxLayoutAlign="space-between center"
  fxLayoutGap="16px"
  class="explore-products-advertisement p-20"
  [style.height]="'100%'"
>
  <div>
    <h4 class="m-0 text-center pb-8">{{ ads[randomNumber].titleKey | translate }}</h4>
    <div class="typography-h3-subtitle text-center">{{ ads[randomNumber].descriptionKey | translate }}</div>
  </div>
  <img fxFlex="0 1 auto" src="{{ ads[randomNumber].imageUrl | getAssetFromStorage }}" alt="advertisement" />
  <button mat-flat-button color="primary" class="w-100p">{{ ads[randomNumber].buttonKey | translate }}</button>
</div>
