import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { ExploreProductsService } from 'app/service/marketplace/explore-products/explore-products.service';
import { IsProductUsedResponse } from 'app/service/marketplace/explore-products/is-product-used';
import { NavigationEventService } from 'app/service/navigation-events/navigation-event.service';
import { ProductSearchService } from 'app/service/product-search/product-search.service';
import { SearchWidgetService } from 'app/service/search-widget/search-widget.service';
import { getSelectedEcomByRole } from 'app/store/ecom/ecom.selector';
import { Utils } from 'app/utils/utils';
import { RolesEnum } from 'app/vo/roles/roles';
import { MarketplaceFilter, SearchProductVO } from 'app/vo/search-product-vo';
import { WidgetFilterData } from 'app/vo/widget';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { selectedCurrencySelector } from '../../../store/currency/currency.selector';
import { ProductsListSliderBoxComponent } from '../products-list-slider-box/products-list-slider-box.component';
import { SkeletonModule } from '../skeleton/skeleton.module';

@Component({
  selector: 'app-products-row-widget',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    RouterModule,
    SkeletonModule,
    ProductsListSliderBoxComponent,
  ],
  templateUrl: './products-row-widget.component.html',
  styleUrls: ['./products-row-widget.component.scss'],
  animations: [fuseAnimations],
})
export class ProductsRowWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() widgetFilter: WidgetFilterData[];
  @Input() wrapperClass: string;

  swiped = false;
  ecomCurrency: Observable<string>;
  productList: SearchProductVO[] = [];
  isLoading: boolean;
  isProductUsed: IsProductUsedResponse;

  private _selectedEcom: EcomVO;
  private _unsubscribeAll: Subject<void>;

  constructor(
    private store: Store<AppState>,
    private productSearchService: ProductSearchService,
    private exploreProductsService: ExploreProductsService,
    private searchWidgetService: SearchWidgetService,
    private navigationEventService: NavigationEventService
  ) {
    this._unsubscribeAll = new Subject<void>();
    this.ecomCurrency = this.store.select(selectedCurrencySelector);
  }

  ngOnInit(): void {
    this.getSelectedEcom();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetFilter) {
      this.initProducts();
    }
  }

  private getSelectedEcom(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this._selectedEcom = res;
      });
  }

  private initProducts(): void {
    if (!Utils.isNullOrUndefined(this.widgetFilter)) {
      this.getProducts(this.widgetFilter);
    }
  }

  private getProducts(widgetFilter: WidgetFilterData[]): void {
    this.isLoading = true;

    this.productSearchService
      .searchRandomProducts(
        this._selectedEcom,
        {
          category: !widgetFilter['category'] && 1,
          ...this.mapToMarketplaceFilter(widgetFilter),
        },
        { from: 0, size: 18 }
      )
      .pipe(
        catchError(() => of({ result: [] })),
        tap((productList) => {
          this.getUsedProducts(productList.result.map((prod) => prod.ID));
        }),
        map((data) => data.result),
        takeUntil(this.navigationEventService.onNavigationStart),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (productList) => {
          this.productList = productList;
        },
      });
  }

  mapToMarketplaceFilter(filterData: WidgetFilterData[]): MarketplaceFilter {
    return this.searchWidgetService.mapWidgetFilterDataToMarketplaceFilter(filterData);
  }

  private getUsedProducts(productIds: string[]): void {
    if (!Utils.isNullOrUndefined(this._selectedEcom) && !!productIds?.length) {
      this.exploreProductsService
        .getIsProductUsed(this._selectedEcom.id, productIds)
        .pipe(takeUntil(this.navigationEventService.onNavigationStart))
        .subscribe({
          next: (v) => {
            this.isProductUsed = v;
          },
        });
    }
  }
}
