import { GettingStartedConfig } from './model/getting-started-config';
import { GeneralInformationSupplierComponent } from './step/supplier/genereal-information-supplier/general-information-supplier.component';
import { CompanyPresentationComponent } from './step/supplier/company-presentation/company-presentation.component';
import { GettingPaidComponent } from './step/supplier/getting-paid/getting-paid.component';
import { SummaryComponent } from './step/supplier/summary/summary.component';
import { FinishingUpComponent } from './step/retailer/finishing-up/finishing-up.component';
import { GeneralInformationComponent } from './step/retailer/general-information/general-information.component';
import { PersonalizationComponent } from './step/retailer/personalization/personalization.component';
import { YourBusinessComponent } from './step/retailer/your-business/your-business.component';

export const RETAILER_GETTING_STARTED_CONFIG: GettingStartedConfig[] = [
  {
    step: 'YOUR_BUSINESS',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.YOUR_BUSINESS.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.YOUR_BUSINESS.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.YOUR_BUSINESS.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.YOUR_BUSINESS.DESCRIPTION',
    },
    url: 'your-business',
    component: YourBusinessComponent,
  },
  {
    step: 'FINISHING_UP',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.FINISHING_UP.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.FINISHING_UP.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.FINISHING_UP.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.FINISHING_UP.DESCRIPTION',
    },
    url: 'finishing-up',
    component: FinishingUpComponent,
  },
  {
    step: 'GENERAL_INFORMATION_RETAILER',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION.DESCRIPTION',
    },
    url: 'general-information',
    component: GeneralInformationComponent,
  },
  {
    step: 'PERSONALIZATION',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.PERSONALIZATION.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.PERSONALIZATION.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.PERSONALIZATION.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.PERSONALIZATION.DESCRIPTION',
    },
    url: 'personalization',
    component: PersonalizationComponent,
  },
];

export const SUPPLIER_GETTING_STARTED_CONFIG: GettingStartedConfig[] = [
  {
    step: 'GENERAL_INFORMATION_SUPPLIER',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION_SUPPLIER.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION_SUPPLIER.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION_SUPPLIER.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.GENERAL_INFORMATION_SUPPLIER.DESCRIPTION',
    },
    url: 'contact-details',
    component: GeneralInformationSupplierComponent,
  },
  {
    step: 'COMPANY_PRESENTATION',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.COMPANY_PRESENTATION.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.COMPANY_PRESENTATION.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.COMPANY_PRESENTATION.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.COMPANY_PRESENTATION.DESCRIPTION',
    },
    url: 'company-presentation',
    component: CompanyPresentationComponent,
  },
  {
    step: 'GETTING_PAID',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.GETTING_PAID.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.GETTING_PAID.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.GETTING_PAID.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.GETTING_PAID.DESCRIPTION',
    },
    url: 'getting-paid',
    component: GettingPaidComponent,
  },
  {
    step: 'SUMMARY',
    page: {
      step: {
        title: 'GETTING_STARTED.STEPPER_SIDEBAR.SUMMARY.STEP_TITLE',
        subtitle: 'GETTING_STARTED.STEPPER_SIDEBAR.SUMMARY.STEP_SUBTITLE',
      },
      title: 'GETTING_STARTED.STEPPER_SIDEBAR.SUMMARY.TITLE',
      description: 'GETTING_STARTED.STEPPER_SIDEBAR.SUMMARY.DESCRIPTION',
    },
    url: 'summary',
    component: SummaryComponent,
  },
];
