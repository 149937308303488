import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HorizontalScrollDirective } from 'app/shared/directives/horizontal-scroll.directive';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, HorizontalScrollDirective],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {}
