import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { ConvertToUserWeightUnitPipe } from 'app/shared/pipes/convert-to-user-weight-unit.pipe';
import { CurrencyTransformPipe } from 'app/shared/pipes/currency-transform-pipe.pipe';
import { IsNullOrUndefinedOrEmptyPipe } from 'app/shared/pipes/is-null-or-undefined.pipe';
import { CountryLocationDto } from 'app/vo/countries-location/country-location-dto';
import { ShippingCarrierType } from 'app/vo/shipping-zones/shipping-carrier-type';
import { ShippingRateType } from 'app/vo/shipping-zones/shipping-rate-condition-dto';
import { ShippingRateDto } from 'app/vo/shipping-zones/shipping-rate.dto';
import { Subject, takeUntil } from 'rxjs';
import { CustomDividerComponent } from '../custom-divider/custom-divider.component';
import { MatCardModule } from '@angular/material/card';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'app-shipping-details-preferred-table',
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    TranslateModule,
    CurrencyTransformPipe,
    ConvertToUserWeightUnitPipe,
    MatTableModule,
    CustomDividerComponent,
    MatIconModule,
    MatTooltipModule,
    IsNullOrUndefinedOrEmptyPipe,
    MatCardModule,
    TruncatePipe,
  ],
  templateUrl: './shipping-details-preferred-table.component.html',
  styleUrls: ['./shipping-details-preferred-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingDetailsPreferredTableComponent implements OnInit, OnDestroy {
  @Input() rates: Record<string, ShippingRateDto[]>;
  @Input() preferredLocations: CountryLocationDto[];
  @Input() supplierCurrency: string;

  dataSource: (string | ShippingRateDto)[];
  displayedColumns = ['location', 'condition', 'incrementalFee', 'shippingTime', 'price'];
  isMobile: boolean;

  readonly rateTypes = ShippingRateType;
  readonly carrierType = ShippingCarrierType;

  private _unsubscribeAll: Subject<void>;

  constructor(private screenManagerService: ScreenManagerService, private cdr: ChangeDetectorRef) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
    this.initTable();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe({
        next: (matches) => {
          this.isMobile = matches;
        },
      });
  }

  private initTable(): void {
    this.dataSource = this.preferredLocations
      .filter((location) => !!this.rates[location.countryCode])
      .map((location) => {
        const tableData: (string | ShippingRateDto)[] = [];
        tableData.push(location.countryCode);
        for (const rate of this.rates[location.countryCode]) {
          tableData.push(rate);
        }
        return tableData;
      })
      .flat(1);

    this.cdr.markForCheck();
  }
}
