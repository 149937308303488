<div fxLayout="column" fxLayoutGap="24px">
  <div fxLayout="column" fxLayoutGap="4px" *ngIf="!!title || !!subTitle">
    <div class="typography-body-large-2">{{ title | translate }}</div>
    <div>{{ subTitle | translate }}</div>
  </div>
  <app-chip-list-selector
    [iconSize]="18"
    [items]="chipItems"
    [selectedItems]="selectedCategoryChips"
    (selectedItemsChange)="handleItemChange($event)"
    [selectionLimit]="3"
  ></app-chip-list-selector>
</div>
