import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';
import { UserFeedbackService } from '../../../../../service/user-feedback/user-feedback.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  CustomOptionDto,
  UserFeedbackComponent,
} from '../../../../../shared/components/user-feedback/user-feedback.component';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { UpdateUserStart } from '../../../../../store/user/user.actions';
import { UserService } from '../../../../../service/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';

@Component({
  selector: 'app-your-business',
  templateUrl: './your-business.component.html',
  styleUrls: ['./your-business.component.scss'],
})
export class YourBusinessComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  storeName = new FormControl<string>('', Validators.required);
  @ViewChild('userFeedbackComponent') feedbackComponent: UserFeedbackComponent;
  bestDescribesCustomSource$: Observable<Record<string, CustomOptionDto[]>>;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;

  private unsubscribeAll = new Subject<void>();
  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private userFeedbackService: UserFeedbackService,
    private store: Store<AppState>,
    private userService: UserService
  ) {
    super(gettingStartedStepBaseService);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.userService
      .getCurrentUserFromStore()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.storeName.setValue(value.userName);
      });
    this.bestDescribesCustomSource$ = this.userFeedbackService.bestDescribesCustomSource$;
  }

  private updateUserName(): void {
    this.store.dispatch(new UpdateUserStart({ user: { userName: this.storeName.value } }));
  }

  handleNextClick(): void {
    this.feedbackComponent.feedbackFormGroup.markAllAsTouched();
    this.storeName.markAllAsTouched();
    if (!this.feedbackComponent.feedbackFormGroup.valid || !this.storeName.valid) {
      return;
    }
    this.handleFormAndStepForward();
  }

  private handleFormAndStepForward(): void {
    const observables: Observable<void>[] = [];
    if (this.storeName.dirty) {
      this.updateUserName();
      observables.push(this.userService.subscribeToUserUpdateSuccess());
    }
    if (this.feedbackComponent.feedbackFormGroup.dirty) {
      observables.push(this.feedbackComponent.saveFeedbacks());
    }

    if (isEmpty(observables)) {
      this.goToNextStep();
    } else {
      combineLatest(observables)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(() => {
          this.goToNextStep();
        });
    }
  }
}
