import { KeyValue } from '@angular/common';

export const SUPPLIER_PAYMENT_CURRENCIES: { label: string; value: string }[] = [
  {
    label: 'United States Dollar',
    value: 'USD',
  },
  {
    label: 'Euro',
    value: 'EUR',
  },
  {
    label: 'Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'Hungarian Forint',
    value: 'HUF',
  },
];
