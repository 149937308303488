<ng-container *ngIf="supplier && productsToRender; else skeleton">
  <div class="more-from-supplier">
    <h3>{{ 'PRODUCT_PAGE.MORE_FROM_SUPPLIER' | translate }}</h3>
    <div class="content-wrapper">
      <div class="info-and-report-wrapper">
        <div class="supplier-info-wrapper">
          <div class="supplier-info-header">
            <div class="logo-name-badges">
              <app-supplier-icon
                [supplierUserId]="supplier.userId"
                size="90px"
                [supplierCompanyName]="supplier.companyName"
              ></app-supplier-icon>
              <div class="name-and-badges">
                <h4>{{ supplier.companyName }}</h4>
                <app-review
                  *ngIf="reviewAverage"
                  [condensed]="false"
                  [avgRating]="average"
                  [reviewCount]="count"
                ></app-review>
              </div>
            </div>
            <app-follow-supplier-button
              [supplierUserId]="supplier.userId"
              [hasBorder]="true"
            ></app-follow-supplier-button>
          </div>
          <div class="supplier-description" [innerHTML]="supplier.description.substring(0, 451) | sanitizeHTML"></div>
          <div class="action-buttons">
            <button [routerLink]="supplierSeoUrl" mat-button class="syncee-black-button">
              {{ 'PRODUCT_PAGE.SEE_ALL_PRODUCTS' | translate }}
            </button>
            <app-contact-supplier-button
              [supplierUserId]="supplier.userId"
              [buttonType]="'syncee-black-flat-button'"
            ></app-contact-supplier-button>
          </div>
        </div>
        <app-storefront-report-supplier
          [reportedUserId]="supplier.userId"
          class="report-supplier"
        ></app-storefront-report-supplier>
      </div>
      <div class="product-cards">
        <app-product-card
          *ngFor="let product of productsToRender"
          class="product-card"
          [product]="product"
          [hiddenActionBar]="true"
          [ecomCurrency]="ecomCurrency"
        ></app-product-card>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div fxLayout="column" fxLayoutGap="24px" style="width: 100%">
    <app-skeleton-display-text maxWidth="min(200px, 30%)"></app-skeleton-display-text>

    <div class="divider"></div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" style="width: 100%; padding-top: 44px">
      <div fxLayout="column" fxLayoutGap="12px" style="width: 50%" [ngStyle.lt-md]="{ width: '100%' }">
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="12px">
          <div fxLayout="row" fxLayoutGap="12px" style="flex: 1">
            <app-skeleton-image width="92px" height="92px" borderRadius="50%"></app-skeleton-image>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" style="width: 100%">
              <app-skeleton-text [lines]="1" style="width: min(220px, 40%)"></app-skeleton-text>
            </div>
          </div>

          <app-skeleton-display-text></app-skeleton-display-text>
        </div>

        <app-skeleton-text></app-skeleton-text>

        <div fxLayout="row" fxLayoutGap="12px">
          <app-skeleton-display-text></app-skeleton-display-text>
          <app-skeleton-display-text></app-skeleton-display-text>
        </div>

        <app-skeleton-text style="width: min(120px, 10%)" [lines]="1"></app-skeleton-text>
      </div>

      <div
        class="skeleton-product-cards"
        [ngStyle]="{
          gridTemplateColumns: (isLg$ | async) ? '1fr 1fr' : '1fr 1fr 1fr'
        }"
      >
        <ng-container *ngFor="let _ of [].constructor((isLg$ | async) ? 2 : 3)">
          <app-skeleton-product-card></app-skeleton-product-card>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
