import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class ViewItemGtmAction implements GtmAction {
  event = GtmEvent.view_item;

  constructor(public payload?: ViewItemGtmActionPayload) {
    this.payload = { ...payload };
  }
}

export interface Item {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_variant?: string;
  price: number;
}

export interface ViewItemGtmActionPayload {
  currency: string;
  value: number;
  items: Item[];
}
