import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';

export class SearchGtmAction implements GtmAction {
  event = GtmEvent.search;
  constructor(public payload: SearchGtmActionPayload) {}
}

export interface SearchGtmActionPayload {
  search_term: string;
}
