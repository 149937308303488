<div class="w-100p" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
  <app-step-title-description
    [title]="'GETTING_STARTED.STEP_PAGE.YOUR_STORE.TITLE' | translate"
    [description]="'GETTING_STARTED.STEP_PAGE.YOUR_STORE.SUBTITLE' | translate"
  ></app-step-title-description>
  <div class="w-100p" [style.max-width]="contentMaxWidth" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="12px">
      <h5 class="mt-0">{{ 'USER_FEEDBACK.STORE_NAME.TITLE' | translate }}</h5>
      <mat-form-field appearance="fill" class="input-without-padding-except-error select-without-label">
        <input
          matInput
          [formControl]="storeName"
          type="text"
          [placeholder]="'USER_FEEDBACK.STORE_NAME.PLACEHOLDER' | translate"
        />
        <mat-error *ngIf="!!storeName.errors">
          {{ 'FORM_VALIDATORS.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <app-user-feedback
      #userFeedbackComponent
      group="BUSINESS_DETAILS_RETAILER"
      [customOptions]="bestDescribesCustomSource$ | async"
    ></app-user-feedback>
  </div>
  <app-step-navigation-buttons
    (prevClick)="goToPreviousStep()"
    (nextClick)="handleNextClick()"
  ></app-step-navigation-buttons>
</div>
