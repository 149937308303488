import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OnboardItem, OnboardService } from '../../layout/components/onboard/onboard.service';
import { Observable, of } from 'rxjs';
import {
  AddCompletedSnippetsToUserAction,
  AddCompletedSnippetToUserAction,
  GetSnippetCompletedStepsStartAction,
  GetSnippetCompletedStepsSuccessAction,
  GetSnippetTreeStartAction,
  GetSnippetTreeSuccessAction,
  GetSnippetUserStatusStart,
  GetSnippetUserStatusSuccess,
  SnippetActionTypes,
} from './snippet.actions';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SnippetToUser } from '../../main/setup-guide/model/snippet-to-user';
import { SetupGuideGatewayService } from '../../main/setup-guide/service/setup-guide-gateway.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserIdSelector, hasRolesSelector } from '../user/user.selector';
import { snippetCompletedStepsSelector } from './snippet.selector';
import { RolesEnum } from '../../vo/roles/roles';

@Injectable()
export class SnippetEffects {
  constructor(
    private actions$: Actions,
    private onboardService: OnboardService,
    private setupGuideGatewayService: SetupGuideGatewayService,
    private store: Store<AppState>
  ) {}

  startChain: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.SNIPPET_CHAIN_START),
      switchMap(() => [
        new GetSnippetTreeStartAction(),
        new GetSnippetCompletedStepsStartAction(),
        new GetSnippetUserStatusStart(),
      ])
    )
  );

  getSnippetCompletedStepsStart: Observable<GetSnippetCompletedStepsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.GET_SNIPPET_COMPLETED_STEPS_START),
      switchMap(() => this.store.select(getCurrentUserIdSelector).pipe(take(1))),
      switchMap((userId) => this.setupGuideGatewayService.getSnippetToUser(userId).pipe(catchError(() => of(null)))),
      map((items: SnippetToUser[]) => new GetSnippetCompletedStepsSuccessAction(items))
    )
  );

  getSnippetTreeStart: Observable<GetSnippetTreeSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.GET_SNIPPET_TREE_START),
      switchMap(() => this.setupGuideGatewayService.getSnippetTree().pipe(catchError(() => of(null)))),
      withLatestFrom(this.store.select(hasRolesSelector, RolesEnum.SUPPLIER)),
      map(([items, hasSupplierRole]) =>
        items.filter((item) => item.type.includes(hasSupplierRole ? 'supplier' : 'retailer'))
      ),
      map((items: OnboardItem[]) => new GetSnippetTreeSuccessAction(items))
    )
  );

  addCompletedSnippetToUser: Observable<GetSnippetCompletedStepsStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.ADD_COMPLETED_SNIPPET_TO_USER),
      switchMap((action: AddCompletedSnippetToUserAction) => this.addSnippet(action)),
      map(() => new GetSnippetCompletedStepsStartAction())
    )
  );

  addCompletedSnippetsToUser: Observable<GetSnippetCompletedStepsStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.ADD_COMPLETED_SNIPPETS_TO_USER),
      switchMap((action: AddCompletedSnippetsToUserAction) =>
        this.setupGuideGatewayService.addSnippetsToUser(action.payload)
      ),
      map(() => new GetSnippetCompletedStepsStartAction())
    )
  );

  getSnippetUserStatus: Observable<GetSnippetUserStatusSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(SnippetActionTypes.GET_SNIPPET_USER_STATUS_START),
      switchMap(() => this.onboardService.getOnboardingStatus()),
      map((show) => new GetSnippetUserStatusSuccess(show))
    )
  );

  private addSnippet(action: AddCompletedSnippetToUserAction): Observable<void> {
    return this.store.select(snippetCompletedStepsSelector).pipe(
      take(1),
      switchMap((snippets) => {
        if (snippets && !snippets.some((step) => step.snippetsId === action.payload.snippetsId)) {
          return this.setupGuideGatewayService.addSnippetToUser({
            snippetsId: action.payload.snippetsId,
            skipped: action.payload.skipped,
          });
        }
        return of(null);
      })
    );
  }
}
