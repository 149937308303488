import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexLayoutModule } from '@angular/flex-layout';
import { SkeletonModule } from '../skeleton/skeleton.module';

import { CategoriesByPreferenceComponent } from './categories-by-preference.component';
import { CategoryCardItemComponent } from './category-card-list/category-card-item/category-card-item.component';
import { CategoryCardListComponent } from './category-card-list/category-card-list.component';
import { SkeletonCategoryCardItemComponent } from './category-card-list/skeleton-category-card-item/skeleton-category-card-item.component';
import { CategoryNameByIdPipe } from 'app/shared/pipes/category-name-by-id.pipe';

@NgModule({
  declarations: [
    CategoriesByPreferenceComponent,
    CategoryCardItemComponent,
    CategoryCardListComponent,
    SkeletonCategoryCardItemComponent,
  ],
  imports: [CommonModule, FlexLayoutModule, ExtendedModule, SkeletonModule, CategoryNameByIdPipe],
  exports: [CategoriesByPreferenceComponent],
})
export class CategoriesByPreferenceModule {}
