import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { CatalogTypeGtm } from '../model/catalog-type-gtm';

export class CatalogCreateAction implements GtmAction {
  event = GtmEvent.catalog_create;

  constructor(public payload: CatalogCreateActionPayload) {}
}

export interface CatalogCreateActionPayload {
  catalog_type: CatalogTypeGtm;
}
