<div (click)="openOrderAndCatalogInformation()" class="clickable-content" *ngIf="supplierOrderLimit">
  {{
    'PRODUCT_PAGE.WHOLESALE_MINIMUM_ORDER.MIN_ORDER'
      | translate
        : {
            amount:
              amount
              | currencyExchangeToSelected : supplier.paymentCurrency
              | async
              | currencyTransformToSelected
              | async
          }
  }}
</div>
