import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { navigation } from 'app/navigation/navigation';
import { getUserRoleSelector } from 'app/store/user/user.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { AiChatLayout } from '../../../main/ai-chat/model/layout/ai-chat-layout';
import { AiChatService } from '../../../main/ai-chat/services/ai-chat.service';
import {
  ChatMinimizationService,
  MinimizedChatData,
} from '../../../service/chat-minimization/chat-minimization.service';
import { LayoutPortalPosition } from '../../../service/layout-portal/layout-portal-position';
import { LayoutPortalService } from '../../../service/layout-portal/layout-portal.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { snippetOnboardingStatusSelector } from '../../../store/snippet/snippet.selector';
import { switchMapWith } from '../../../utils/operator/switch-map-with';
import { ROLE_TYPE } from '../../../vo/roles/roles';
import { FilterSidebarMetadata } from '../../components/filter/filter.service';
import { MOBILE_MENU_SIDEBAR_ID } from '../../components/mobile-main-menu/mobile-main-menu-sidebar-ids';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('outerContainer') outerContainer: ElementRef<HTMLElement>;

  fuseConfig: any;
  toolbarConfig$: Observable<any>;
  navigation: any;
  filterSidebarMetadata: FilterSidebarMetadata;
  mobileSidebarId = MOBILE_MENU_SIDEBAR_ID;
  onboardStatus: Observable<boolean>;
  minimizedChats: MinimizedChatData[];
  userRole: ROLE_TYPE;
  aiChatLayoutEnum = AiChatLayout;
  isMobile: boolean;
  topValueOfFloatingTopPortals: number;

  private _unsubscribeAll: Subject<void>;
  private _toolbarConfig$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  protected readonly LayoutPortalPosition = LayoutPortalPosition;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private chatMinimizationService: ChatMinimizationService,
    private screenManager: ScreenManagerService,
    private store: Store<AppState>,
    public aiChatService: AiChatService,
    public layoutService: LayoutService,
    public layoutPortalService: LayoutPortalService,
    private zone: NgZone
  ) {
    this.toolbarConfig$ = this._toolbarConfig$.asObservable().pipe(omitNullOrUndefined(), distinctUntilChanged());
    this.navigation = navigation;
    this._unsubscribeAll = new Subject();
    this.onboardStatus = this.store.select(snippetOnboardingStatusSelector);
    this.initBreakpointObserver().subscribe((value) => (this.isMobile = value));
  }

  ngOnInit(): void {
    this._fuseConfigService.config
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((fuseConfig) => this.setToolbarConfig(fuseConfig))
      )
      .subscribe((config) => {
        this.fuseConfig = config;
      });
    this.subscribeToMinimizedChats();
    this.store
      .select(getUserRoleSelector)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((role) => (this.userRole = role));
    this.scrollbarHoverListener();
    this.getTopValueOfFloatingTopPortals()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((px) => (this.topValueOfFloatingTopPortals = px));
  }

  ngAfterViewInit(): void {
    this.layoutService.initContent(this.outerContainer);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private setToolbarConfig(fuseConfig: any): void {
    this._toolbarConfig$.next({
      hidden: fuseConfig.layout.toolbar.hidden,
      needRedirect: fuseConfig.layout.categorySelector.redirect,
      needCategorySelector: !fuseConfig.layout.categorySelector.hidden,
      customClass:
        fuseConfig.layout.toolbar.customBackgroundColor === true
          ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
          : fuseConfig.layout.toolbar.position,
      hasAiChat: !fuseConfig.layout.aiChat.hidden,
      position: fuseConfig.layout.toolbar.position,
    });
  }

  private subscribeToMinimizedChats(): void {
    this.chatMinimizationService.minimizedChatSubject.subscribe((value) => {
      this.minimizedChats = value;
    });
  }

  private initBreakpointObserver(): Observable<boolean> {
    return this.screenManager.observeBreakpoint(BreakPoint.md).pipe(this.screenManager.stateMatchesOperator());
  }

  private scrollbarHoverListener(): void {
    this.zone.runOutsideAngular(() => {
      document.addEventListener('mousemove', function (e): void {
        const element = document.getElementById('main');
        const distance = element.offsetLeft + element.offsetWidth - e.pageX;
        distance < 15 && distance > -15
          ? element.classList.add('scroll-hovered')
          : element.classList.remove('scroll-hovered');
      });
    });
  }

  private getTopValueOfFloatingTopPortals(): Observable<number> {
    return this.layoutService.stickyHeaderVisible.pipe(
      switchMap((visible) => (visible ? this.layoutService.toolbarHeight : of(0))),
      switchMapWith(() => this.screenManager.observeIfBreakpointMatches(BreakPoint.sm)),
      map(([px, matches]) => px + (matches ? 0 : 40))
    );
  }
}
