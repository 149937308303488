import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthTokens } from 'app/service/authentication/authentication.service';
import { AuthenticationState } from './authentication.reducer';
import { SCOPES } from '../../service/permission/scopes';

export const getAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');

export const isAuthenticatedSelector = createSelector(
  getAuthenticationState,
  (state: AuthenticationState) => state.isAuthenticated
);

export const isUserAuthenticatedByAdminSelector = createSelector(
  getAuthenticationState,
  (state: AuthenticationState) => !!state && state.isAuthenticated && state.loggedInBy === 'ADMIN'
);

export const isUserAuthenticatedByTeamMemberSelector = createSelector(
  getAuthenticationState,
  (state: AuthenticationState) => !!state && state.isAuthenticated && state.loggedInBy === 'TEAM_MEMBER'
);

export const loggedInBySelector = createSelector(getAuthenticationState, (state: AuthenticationState) =>
  !!state && state.isAuthenticated && !!state.loggedInBy ? state.loggedInBy : null
);

export const isAuthenticationFailedSelector = createSelector(
  getAuthenticationState,
  (state: AuthenticationState) => state && state.isFailed
);

export const getScopesSelector = (mergeScopes: boolean) =>
  createSelector(getAuthenticationState, (state: AuthenticationState) =>
    mergeScopes && !!state.loggedUserScopes?.length
      ? state && state.scopes && state.loggedUserScopes && [...state.scopes, ...state.loggedUserScopes]
      : !!state && !!state.loggedUserScopes
      ? state.loggedUserScopes
      : state.scopes
  );

export const getIsAdminScopeSelector = () =>
  createSelector(getAuthenticationState, (state: AuthenticationState) => state && state.scopes.includes(SCOPES.ADMIN));

export const getCurrentAuthTokensSelector = createSelector(getAuthenticationState, (state: AuthenticationState) => {
  const isLoggedUser = !!state.loggedUserAccessToken;
  return {
    accessToken: isLoggedUser ? state.loggedUserAccessToken : state.token,
    refreshToken: isLoggedUser ? state.loggedUserRefreshToken : state.refreshToken,
    expiresIn: isLoggedUser ? state.loggedUserExpiresIn : state.expiresIn,
  } as AuthTokens;
});
