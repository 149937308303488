import { Preference } from '../../vo/Preferences/preference';
import { PreferenceTypeRecord } from './preference.store.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PreferenceHelperService {
  constructor() {}
  public getCategoryPreferences(preferences: Preference[], types: PreferenceTypeRecord): Preference[] {
    return preferences?.filter((pref) => pref.preferenceTypeId === types['SYNCEE_CATEGORY']) ?? [];
  }

  public getWarehouseLocationPreferences(preferences: Preference[], types: PreferenceTypeRecord): Preference[] {
    return preferences?.filter((pref) => pref.preferenceTypeId === types['SETTINGS.warehouseLocation.keyword']) ?? [];
  }

  public getShipsToLocationPreferences(preferences: Preference[], types: PreferenceTypeRecord): Preference[] {
    return preferences?.filter((pref) => pref.preferenceTypeId === types['SETTINGS.shipsTo.keyword']) ?? [];
  }

  public getUserLocationPreferences(preferences: Preference[], types: PreferenceTypeRecord): Preference[] {
    return preferences?.filter((pref) => pref.preferenceTypeId === types['SETTINGS.myLocation.keyword']) ?? [];
  }
}
