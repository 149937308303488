<app-custom-dialog-wrapper>
  <app-subscription-upsell
    [selectedEcom]="selectedEcom"
    [descriptionTranslationKey]="descTransKey"
    (laterEvent)="closeDialog()"
    (redirectedEvent)="closeDialog()"
    [variant]="data.variant"
    [hasActionBar]="false"
  ></app-subscription-upsell>
</app-custom-dialog-wrapper>
