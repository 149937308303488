export enum SnippetEnum {
  REVIEW = 109,
  RETAILER_EXPLORE_PRODUCTS = 99,
  RETAILER_ADD_IMPORT_LIST = 101,
  RETAILER_SYNC_CATALOG = 102,
  SUPPLIER_SETUP_STOREFRONT = 104,
  SUPPLIER_IMPORT_PRODUCTS = 105,
  SUPPLIER_SHIPPING_CONDITIONS = 106,
  SUPPLIER_LAUNCH_STOREFRONT = 107,
  SUPPLIER_ADD_CARRIER = 123,
  SUPPLIER_REFERRAL_WIDGET = 122,
}
