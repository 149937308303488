import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-list-price-rounding-example',
  templateUrl: './import-list-price-rounding-example.component.html',
  styleUrls: ['./import-list-price-rounding-example.component.scss'],
})
export class ImportListPriceRoundingExampleComponent implements OnInit, OnDestroy {
  examplePrice = 104.88;
  @Input() triggerCalc: Subject<string>;
  @Input() currency = 'USD';
  calculatedPrice = 104.88;
  private _unsubscribe: Subject<void>;

  constructor() {
    this._unsubscribe = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToTrigger();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  subscribeToTrigger(): void {
    this.triggerCalc.pipe(takeUntil(this._unsubscribe)).subscribe((res) => {
      this.calculate(res);
    });
  }

  calculate(roundingRule: string): void {
    switch (roundingRule) {
      case '.00':
        this.calculatedPrice = 104.0;
        break;
      case '.95':
        this.calculatedPrice = 104.95;
        break;
      case '.99':
        this.calculatedPrice = 104.99;
        break;
      case 'xx9.0':
        this.calculatedPrice = 109.0;
        break;
      case '990':
        this.calculatedPrice = 190.0;
        break;
      case '5.00':
        this.calculatedPrice = 105.0;
        break;
      case '0.00':
        this.calculatedPrice = 100.0;
        break;
      default:
        this.calculatedPrice = 104.88;
        break;
    }
  }
}
