<div class="storefront-upload-images" *ngIf="userId | async as userIdAsync">
  <app-upload-supplier-image
    #supplierLogo
    [variant]="variant"
    [resourcePath]="'logos/' + userIdAsync + '.png' | getResourceFromStorage : true"
    type="supplierLogo"
    [title]="'STOREFRONT_SETTINGS.TAB.BRANDING.LOGO.TITLE' | translate"
    [tooltip]="'STOREFRONT_SETTINGS.TAB.BRANDING.LOGO.TOOLTIP' | translate"
    helperImageSrc="/assets/images/storefront-illustrations/logo.png"
    [helperText]="'STOREFRONT_SETTINGS.TAB.BRANDING.LOGO.TEXT_UNDER_IMAGE' | translate"
    (changed)="changedTypes.add('supplierLogo')"
    [maxWidth]="2000"
    [maxHeight]="2000"
    [imageType]="imageType.SUPPLIER_LOGO"
  ></app-upload-supplier-image>
  <app-custom-divider *ngIf="hasDivider"></app-custom-divider>
  <app-upload-supplier-image
    #coverImage
    [variant]="variant"
    [resourcePath]="'cover-images/' + userIdAsync + '.png' | getResourceFromStorage : true"
    previewVariant="list"
    type="coverImage"
    [title]="'STOREFRONT_SETTINGS.TAB.BRANDING.COVER_IMAGE.TITLE' | translate"
    [tooltip]="'STOREFRONT_SETTINGS.TAB.BRANDING.COVER_IMAGE.TOOLTIP' | translate"
    helperImageSrc="/assets/images/storefront-illustrations/cover-image.png"
    [helperText]="'STOREFRONT_SETTINGS.TAB.BRANDING.COVER_IMAGE.TEXT_UNDER_IMAGE' | translate"
    (changed)="changedTypes.add('coverImage')"
    [templateType]="templateType.COVER"
    [imageType]="imageType.COVER_IMAGE"
  ></app-upload-supplier-image>
  <app-custom-divider *ngIf="hasDivider"></app-custom-divider>
  <app-upload-supplier-image
    #brandImage
    [variant]="variant"
    [resourcePath]="'brand-logos/' + userIdAsync + '.png' | getResourceFromStorage : true"
    type="brandLogo"
    [title]="'STOREFRONT_SETTINGS.TAB.BRANDING.BRAND_IMAGE.TITLE' | translate"
    [tooltip]="'STOREFRONT_SETTINGS.TAB.BRANDING.BRAND_IMAGE.TOOLTIP' | translate"
    helperImageSrc="/assets/images/storefront-illustrations/brand-image.png"
    [helperText]="'STOREFRONT_SETTINGS.TAB.BRANDING.BRAND_IMAGE.TEXT_UNDER_IMAGE' | translate"
    (changed)="changedTypes.add('brandLogo')"
    [maxWidth]="2000"
    [maxHeight]="2000"
    [required]="false"
    [imageType]="imageType.BRAND_LOGO"
  ></app-upload-supplier-image>
</div>
