import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingInfoVO } from '../../../vo/billing-vo';
import { CountryNode } from '../../../utils/Countries';
import { EU_COUNTRIES_CODES } from '../../../utils/Constants';
import { Utils } from 'app/utils/utils';

@Injectable()
export class BillingInformationService implements OnDestroy {
  billingInfo: BillingInfoVO;
  hideEUVat = true;
  hideTaxNumber = true;
  countryList: CountryNode[];
  private euCountryCodeList = EU_COUNTRIES_CODES;
  billingInfoForm: FormGroup;
  private _unsubscribeAll: Subject<void>;

  constructor(private formBuilder: FormBuilder, private countriesManagerService: CountriesManagerService) {
    this._unsubscribeAll = new Subject();
    this.initForm();
    this.initFormValues();
    this.initSubscribes();
    this.countryList = this.countriesManagerService.getOnlyCountries();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initSubscribes(): void {
    this.billingInfoForm.controls['country'].valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((countryCode) => {
        if (countryCode === 'HU') {
          this.handleHungarySelection();
        } else {
          this.handleNonHungarySelection();
        }

        this.handleEuVat(countryCode);
      });

    // this.createCompanyNameSubscription();
  }

  private createCompanyNameSubscription(): void {
    this.billingInfoForm
      .get('companyName')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((companyName) => {
        if (companyName.length > 0 && this.billingInfoForm.controls['country'].value === 'HU') {
          this.hideTaxNumber = false;
          this.billingInfoForm.controls['taxNumber'].setValidators([Validators.required]);
          this.billingInfoForm.controls['taxNumber'].updateValueAndValidity();
          this.billingInfoForm.updateValueAndValidity();
          this.billingInfoForm.controls['taxNumber'].markAsTouched();
        } else {
          this.billingInfoForm.controls['taxNumber'].clearValidators();
          this.billingInfoForm.controls['taxNumber'].updateValueAndValidity();
          this.billingInfoForm.updateValueAndValidity();
        }
      });
  }

  private handleEuVat(countryCode): void {
    if (this.euCountryCodeList.includes(countryCode)) {
      this.hideEUVat = false;
      this.billingInfoForm.controls['euVat'].addValidators([Validators.required]);
    } else {
      this.hideEUVat = true;
      this.billingInfoForm.controls['euVat'].removeValidators([Validators.required]);
      this.billingInfoForm.controls['euVat'].reset();
    }
    this.billingInfoForm.controls['euVat'].updateValueAndValidity();
  }

  private handleNonHungarySelection(): void {
    this.hideTaxNumber = true;
    this.billingInfoForm.controls['taxNumber'].removeValidators(Validators.required);
    this.billingInfoForm.controls['taxNumber'].updateValueAndValidity();
  }

  private handleHungarySelection(): void {
    this.hideTaxNumber = false;
    this.billingInfoForm.controls['taxNumber'].addValidators(Validators.required);
    this.billingInfoForm.controls['taxNumber'].updateValueAndValidity();
  }

  private initForm(): void {
    this.billingInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      companyName: ['', Validators.required],
      euVat: [''],
      taxNumber: [''],
    });
  }

  private initFormValues(): void {
    if (this.billingInfo) {
      console.log(this.billingInfo);
      if (!Utils.isNullOrUndefined(this.billingInfo)) {
        if (typeof this.billingInfo === 'string') {
          const json = JSON.parse(this.billingInfo);
          for (const [key, value] of Object.entries(json)) {
            this.billingInfoForm.controls[key].setValue(value);
          }
        } else {
          for (const [key, value] of Object.entries(this.billingInfo)) {
            this.billingInfoForm.controls[key].setValue(value);
          }
        }
      }
    }
  }

  set euCountryCodes(isBrazilIncluded: boolean) {
    if (isBrazilIncluded) {
      this.euCountryCodeList = EU_COUNTRIES_CODES.concat('BR');
    } else {
      this.euCountryCodeList = EU_COUNTRIES_CODES;
    }
  }
}
